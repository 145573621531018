import ContentContainer from "@/components/contentContainer";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { Paper } from "@mui/material";
import {
  GridColDef,
  GridRowSelectionModel,
  GridSortDirection,
} from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelsResponse } from "@/api/setup/types";
import { useColumns } from "@/hooks/useColumnsData";
import useData from "@/hooks/auth/useData";
import LabelButton from "@/components/LabelButton";

const Categories = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const { selectedManufacturers, setupCategoriesApi } = useData();

  const manufacturerIds = useMemo(
    () => selectedManufacturers.map((m) => m.ID),
    [selectedManufacturers]
  );

  const rows = useMemo(() => {
    return (setupCategoriesApi.data || []).map((row) => ({
      ...row,
    }));
  }, [setupCategoriesApi.data]);

  useEffect(() => {
    if (!manufacturerIds.length) return;

    setupCategoriesApi.execute({
      m: manufacturerIds,
    });
  }, [manufacturerIds]);

  const allColumns = useColumns();

  const columns: GridColDef<LabelsResponse>[] = [
    allColumns.posColumn,
    allColumns.categoryLinkColumn,
    allColumns.idColumn,
    allColumns.setupLabelColumn,
  ];

  const sortModel = useMemo(
    () => [
      {
        field: "labels",
        sort: "asc" as GridSortDirection,
      },
    ],
    []
  );

  const MAX_SELECTION = 20;

  const handleRowSelection = (newSelectionModel: GridRowSelectionModel) => {
    if (newSelectionModel.length >= rows.length) {
      const visibleRows = rows.map((row) => row.id);
      const limitedSelection = visibleRows.slice(0, MAX_SELECTION);
      setSelectedRows(limitedSelection);
      console.log('Limited selection to MAX_SELECTION');
    } else if (newSelectionModel.length > MAX_SELECTION) {
      console.log('Exceeded selection limit');
      return;
    } else {
      setSelectedRows(newSelectionModel);
    }
  };

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          id="categories"
          rows={rows}
          columns={columns}
          sortModel={sortModel}
          onRowSelectionModelChange={handleRowSelection}
          rowSelectionModel={selectedRows}
          selectedRows={selectedRows}
          loading={setupCategoriesApi.showSkeletton}
          filterBarType={2}
          hasCheckboxes={true}
          hasExport={false}
          opacityTableInfo={t("pages.categories.title") as string}
          isError={setupCategoriesApi.isError}
          error={setupCategoriesApi.error}
          customButton={<LabelButton selectedRows={selectedRows} isKeyword={false} />}
          isRowSelectable={() => {
            return selectedRows.length <= MAX_SELECTION;
          }}
        />
      </Paper>
    </ContentContainer>
  );
};

export default Categories;
