import { MeApiType, useMe } from "@/api/useMe";
import { SignInApiType, useSignIn } from "@/api/useSignIn";
import { SignOutApiType, useSignOut } from "@/api/useSignOut";
import { useUserTraffic, UserTrafficApiType } from "@/api/useUserTraffic";
import { PreloaderBox } from "@/components/preloader-box/PreloaderBox";
import { Routes } from "@/consts/routes";
import { useDidUpdateEffect } from "@/hooks/useDidUpdateEffect";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import globalEventEmitter from "@/utils/eventEmitter";
import { GLOBAL_LOGOUT_EVENT } from "@/utils/globalLogout";
import { getParentModule, getTitleByPathname } from "@/consts/navItems";
import { getRequiredModules } from "@/consts/routes";

export type AuthState = {
  isInitialized: boolean;
  isLoading: boolean;
  isAuthenticated: boolean | undefined;
  signInApi: SignInApiType | undefined;
  signOutApi: SignOutApiType | undefined;
  meApi: MeApiType | undefined;
  signOut: () => void;
  userTrafficApi: UserTrafficApiType | undefined;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

/**
 * Initial state.
 */
const initialState: AuthState = {
  isLoading: true,
  isInitialized: false,
  isAuthenticated: undefined,
  signInApi: undefined,
  signOutApi: undefined,
  meApi: undefined,
  userTrafficApi: undefined,
  signOut: () => {},
};

const AuthContext = React.createContext<AuthState>(initialState);

/**
 * AuthProvider component to manage authentication state and provide context to children components.
 *
 * @param {AuthProviderProps} props - The properties for the AuthProvider component.
 * @returns {JSX.Element} The rendered AuthProvider component.
 */
function AuthProvider({ children }: AuthProviderProps): JSX.Element | null {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined
  );
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const signInApi = useSignIn();
  const meApiPending = useRef(false);
  const meApi = useMe();
  const signOutApi = useSignOut();
  const userTrafficApi = useUserTraffic();
  const title = getTitleByPathname(location.pathname);

  const signOut = async () => {
    // Send traffic data for sign out if authenticated.
    if (isAuthenticated) {
        try {
          await userTrafficApi?.execute({
            page_title: "SignOut",
            url: window.location.href,
            screen_size: `${window.screen.width}x${window.screen.height}`,
          });
        } catch(error) {
          console.error(error);
        } 
    } 

    // Sign out.
    await signOutApi?.execute();
    setIsAuthenticated(false);
    navigate(Routes.signIn);
  };

  /**
   * Check session debounce.
   */
  const checkSession = async () => {
    if (meApiPending.current) return;

    meApiPending.current = true;

    try {
      await meApi.execute();
    } finally {
      meApiPending.current = false;
    }
  };

  /**
   * Check session initially
   */
  useEffect(() => {
    if (isAuthenticated === false) return;

    checkSession();
  }, []);

  /**
   * Set isAuthenticated if signInApi or meApi has valid result / session.
   */
  useDidUpdateEffect(() => {
    if (meApiPending.current) return;

    setIsAuthenticated(!!meApi.data || !!signInApi.data);
  }, [meApi.data, signInApi.data, meApiPending.current]);

  /**
   * Set isAuthenticated to false if signInApi or meApi has invalid result or error and sign out.
   */
  useDidUpdateEffect(() => {
    if (!meApi.isError && !signInApi.isError) return;

    if (isAuthenticated) {
      signOut();
      return;
    }

    navigate(Routes.signIn);
  }, [meApi.isError, signInApi.isError]);

  /**
   * Initialize app.
   */
  useDidUpdateEffect(() => {
    if (isAuthenticated === undefined || isInitialized) return;

    setIsInitialized(true);
  }, [isAuthenticated]);

  /**
   * Redirect to private routes when authenticated.
   */
  useDidUpdateEffect(() => {
    if (!isAuthenticated) return;

    if (pathname === Routes.signIn) {
      navigate(Routes.startPage);
    }
  }, [isAuthenticated, pathname]);

  useEffect(() => {
    // Don't track traffic if user is not authenticated
    if (isAuthenticated === undefined || !isAuthenticated) return;

    const url = window.location.href; // Get the URL path including origin
    const screenSize = `${window.screen.width}x${window.screen.height}`;

    const requiredModules = getRequiredModules(window.location.pathname);

    // Send page data
    userTrafficApi?.execute({
      page_title: title,
      url: url,
      module_id: requiredModules.length > 0 ? requiredModules[0] : undefined,
      screen_size: screenSize,
    });
  }, [isAuthenticated, pathname, title]); // Run effect on first load and on route change

  /**
   * Handles logouts to due unsuccessful refresh request
   */
  useEffect(() => {
    const handleLogout = () => {
      signOut();
    };

    globalEventEmitter.on(GLOBAL_LOGOUT_EVENT, handleLogout);

    return () => {
      globalEventEmitter.off(GLOBAL_LOGOUT_EVENT, handleLogout);
    };
  }, [signOut]);

  if (!isInitialized) {
    return <PreloaderBox />;
  }

  return (
    <AuthContext.Provider
      value={{
        //isLoading: signInApi.isLoading || refreshApi.isLoading,
        isLoading: signInApi.isLoading || meApi.isLoading,
        isAuthenticated,
        isInitialized,
        signInApi,
        signOutApi,
        //refreshApi,
        meApi,
        signOut,
        userTrafficApi,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
