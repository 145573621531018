import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { getType } from "@/pages/buynow/components/utils";
import useData from "@/hooks/auth/useData";
import { formatShortDate } from "@/utils/formatUserNumber";
import { useTabsContext } from "@/contexts/TabsContext";
import { validationRules } from "../consts";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";

const SetupTab = () => {
  const { t } = useTranslation();
  const { allPropertiesApi, user } = useData();
  const isAdmin = user?.role === "admin";

  const availableProperties = useMemo(() => {
    return allPropertiesApi.data || [];
  }, [allPropertiesApi.data]);
 
  const {
    setTabValue,
    setupProps,
    setSetupProps,
    configs,
    setConfigs,
    defaultConfigIndex,
    setDefaultConfigIndex,
    setValidationErrors,
    setWarnings,
    warnings,
    setCurrentVariants,
    setIsItemsTabInitialized
  } = useTabsContext();

  const [configState, setConfigState] = useState({
    imprint: "",
    dataProtection: "",
    hideInMobile: 1,
    pagination: false,
    m360Branding: false,
  });

  const { na } = useCustomTableUtils();
  
  useEffect(() => {
    if (configs[defaultConfigIndex]) {
      setConfigState({
        imprint:
          configs[defaultConfigIndex].config?.config?.footer?.imprint || "",
        dataProtection:
          configs[defaultConfigIndex].config?.config?.footer?.dataProtection ||
          "",
        hideInMobile:
          configs[defaultConfigIndex].config?.config?.hideInMobile || 1,
        pagination:
          configs[defaultConfigIndex].config?.config?.pagination || false,
        m360Branding:
          configs[defaultConfigIndex].config?.config?.m360Branding || false,
      });
    }
  }, [configs, defaultConfigIndex]);

  const handleConfigChange = (field: string, value: any) => {
    setConfigState((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    const updatedWarnings = { ...warnings };
    const fieldKey = field;

    if (field === "imprint" || field === "dataProtection") {
      if (value.trim() === "") {
        delete updatedWarnings[fieldKey];
      } else if (!validationRules.url.pattern.test(value)) {
        updatedWarnings[fieldKey] = t(validationRules.url.message);
      } else {
        delete updatedWarnings[fieldKey];
      }
    }

    setWarnings(updatedWarnings);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      setup: { ...updatedWarnings },
    }));

    setConfigs((prevConfigs) =>
      prevConfigs.map((config, idx) =>
        idx === defaultConfigIndex ? updateConfig(config, field, value) : config
      )
    );
  };

  const updateConfig = (config: any, field: string, value: any) => {
    if (field === "imprint" || field === "dataProtection") {
      return {
        ...config,
        config: {
          ...config.config,
          config: {
            ...config.config?.config,
            footer: {
              ...config.config?.config?.footer,
              [field]: value,
            },
          },
        },
      };
    } else {
      return {
        ...config,
        config: {
          ...config.config,
          config: {
            ...config.config?.config,
            [field]: value,
          },
        },
      };
    }
  };

  const handlePropertyChange = (event: SelectChangeEvent) => {
    const selectedProperty = availableProperties.find(
      (prop) => prop.url === event.target.value
    );
    if (selectedProperty) {
      setSetupProps((prev) => ({
        ...prev,
        install: selectedProperty.id,
        property: selectedProperty.url || "n.a.",
        type: selectedProperty.type,
        restriction: selectedProperty.domain || "",
      }));
    }
  };

  const handleDefaultConfiguration = (event: SelectChangeEvent) => {
    const newDefaultConfigIdx = configs.findIndex(
      (config) => config.config_id === +event.target.value
    );
    if (newDefaultConfigIdx !== -1) {
      setDefaultConfigIndex(newDefaultConfigIdx);
      const updatedConfigs = configs.map((config, index) => ({
        ...config,
        type: index === newDefaultConfigIdx ? 0 : 1,
      }));
      setConfigs(updatedConfigs);
      setCurrentVariants(updatedConfigs[newDefaultConfigIdx].variants);
      setIsItemsTabInitialized(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label={t("pages.buyNowComponent.editModal.setup.componentId")}
            fullWidth
            margin="dense"
            value={setupProps.id || ""}
            InputProps={{ readOnly: true }}
            disabled={true}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            label={t("pages.buyNowComponent.editModal.setup.type")}
            fullWidth
            margin="dense"
            value={getType(setupProps.type) || ""}
            InputProps={{ readOnly: true }}
            disabled={true}
          />
        </Grid>
      </Grid>

      <FormControl fullWidth margin="dense">
        <InputLabel>
          {t("pages.buyNowComponent.editModal.setup.property")}
        </InputLabel>
        <Select
          label={t("pages.buyNowComponent.editModal.setup.property")}
          value={setupProps.property}
          onChange={handlePropertyChange}
          disabled={!isAdmin}
        >
          {availableProperties.map((prop) => (
            <MenuItem key={prop.id} value={prop.url || "n.a."}>
              {prop.url || "n.a."}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label={t("pages.buyNowComponent.editModal.setup.restriction")}
        fullWidth
        margin="dense"
        value={setupProps.restriction}
        InputProps={{ readOnly: true }}
        disabled={true}
      />
      <FormControl fullWidth margin="dense">
        <InputLabel>
          {t("pages.buyNowComponent.editModal.setup.defaultConfig")}
        </InputLabel>
        <Select
          value={configs[defaultConfigIndex]?.config_id.toString() || ""}
          label={t("pages.buyNowComponent.editModal.setup.defaultConfig")}
          onChange={handleDefaultConfiguration}
          disabled={!isAdmin}
        >
          {configs.map((config) => (
            <MenuItem
              key={config.config_id}
              value={config.config_id.toString()}
            >
              {config.config_id
                ? `${config.config_id} - ${t("general.created")} ${
                    formatShortDate(config.created, true, user?.settings?.numberFormat, true) || na
                  }`
                : na}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <Typography variant="h6" gutterBottom>
          {t("pages.buyNowComponent.editModal.setup.imprintAndDataProtection")}
        </Typography>

        <TextField
          label={t("pages.buyNowComponent.editModal.setup.imprintUrl")}
          fullWidth
          margin="dense"
          value={configState.imprint}
          onChange={(e) => handleConfigChange("imprint", e.target.value)}
          onFocus={(e) => e.target.select()}
          error={!!warnings["imprint"]}
          helperText={warnings["imprint"] || ""}
          disabled={!isAdmin}
        />

        <TextField
          label={t("pages.buyNowComponent.editModal.setup.dataProtectionUrl")}
          fullWidth
          margin="dense"
          value={configState.dataProtection}
          onChange={(e) => handleConfigChange("dataProtection", e.target.value)}
          onFocus={(e) => e.target.select()}
          error={!!warnings["dataProtection"]}
          helperText={warnings["dataProtection"] || ""}
          disabled={!isAdmin}
        />
      </Box>

      <FormControl fullWidth margin="dense">
        <InputLabel>
          {t("pages.buyNowComponent.editModal.setup.excludeOnMobile")}
        </InputLabel>
        <Select
          value={configState.hideInMobile}
          label={t("pages.buyNowComponent.editModal.setup.excludeOnMobile")}
          onChange={(e) => handleConfigChange("hideInMobile", e.target.value)}
          disabled={!isAdmin}
        >
          <MenuItem value={1}>{t("pages.buyNowComponent.editModal.setup.button")}</MenuItem>
          <MenuItem value={2}>{t("pages.buyNowComponent.editModal.setup.ratings")}</MenuItem>
          <MenuItem value={3}>{t("pages.buyNowComponent.editModal.setup.price")}</MenuItem>
        </Select>
      </FormControl>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={configState.pagination}
                onChange={(e) =>
                  handleConfigChange("pagination", e.target.checked)
                }
              />
            }
            label={t("pages.buyNowComponent.editModal.setup.pagination")}
            disabled={!isAdmin}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={configState.m360Branding}
                onChange={(e) =>
                  handleConfigChange("m360Branding", e.target.checked)
                }
              />
            }
            label={t("pages.buyNowComponent.editModal.setup.branding")}
            disabled={!isAdmin}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SetupTab;
