import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { gray as lighterGray } from "@/hooks/useColumnsData/consts";

export const containerStyles: SxProps<Theme> = (theme) => ({
  padding: "10px",
  backgroundColor: theme.palette.background.paper,
});

export const innerContainerStyles: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
};

export const selectorsBoxStyles: SxProps<Theme> = {
  display: "flex",
  flexWrap: "wrap",
  gap: 5,
};

export const moreButtonContainerStyles: SxProps<Theme> = {
  display: "flex",
  height: "40px",
  gap: "10px",
  alignSelf: "flex-start",
};

export const moreButtonStyles: SxProps<Theme> = (theme) => ({
  alignSelf: "center",
  color: theme.palette.text.secondary,
  border: `1px solid ${theme.palette.divider}`,
  minWidth: "103px",
  height: "40px",
});

export const moreButtonStylesContent: SxProps<Theme> = (theme) => ({
  display: "flex",
  fontWeight: "500",
  width: "84%",
  alignItems: "center",
  justifyContent: "space-between",
  color: (theme.palette.mode === "dark") ? lighterGray : theme.palette.grey[700],
});

export const filterButtonStyles: SxProps<Theme> = (theme) => ({
  width: "87px",
  backgroundColor: theme.palette.action.hover,
  color: theme.palette.text.primary,
});

export const formControlStyles: SxProps<Theme> = {
  minWidth: "120px",
  alignSelf: "flex-start",
};
