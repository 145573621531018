import React, { useEffect, useMemo, useState } from 'react';
import ContentContainer from '@/components/contentContainer';
import { Paper } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderEditCellParams,
  GridRowClassNameParams,
  GridRowParams,
  GridSortDirection,
} from '@mui/x-data-grid-pro';
import MuiGridTable from '@/components/tables/DataGridTable';
import { useTranslation } from 'react-i18next';
import CustomGridTreeDataGroupingCell from '@/components/CustomGridTreeDataGroupingCell';
import useCustomTableUtils from '@/hooks/useColumnsData/utils';
import { useColumns } from '@/hooks/useColumnsData';
import useData from '@/hooks/auth/useData';
import ActionsDialog from './ActionsDialog';
import { UserResponse } from '@/types/user';

const UsersManager = () => {
  const { t } = useTranslation();
  const { na } = useCustomTableUtils();
  const { allUsersApi, user } = useData();
  const [openActionsDialog, setOpenActionsDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<UserResponse | null>(null); // Track selected row
  const [dialogMode, setDialogMode] = useState<"create" | "edit" | "overview">("create"); // Manage dialog mode

  const calculateDaysAgo = (lastLoginDate: string | null) => {
    if (!lastLoginDate) return na;
    const lastLogin = new Date(lastLoginDate);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - lastLogin.getTime());

    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    if (diffHours < 24)
      return `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'} ago`;

    const diffDays = Math.floor(diffHours / 24);
    if (diffDays < 30)
      return `${diffDays} ${diffDays === 1 ? 'day' : 'days'} ago`;

    const diffMonths = Math.floor(diffDays / 30);
    if (diffMonths < 12)
      return `${diffMonths} ${diffMonths === 1 ? 'month' : 'months'} ago`;

    const diffYears = Math.floor(diffMonths / 12);
    return `${diffYears} ${diffYears === 1 ? 'year' : 'years'} ago`;
  };

  useEffect(() => {
    allUsersApi.execute();
  }, []);
  
  const allColumns = useColumns();

  const rowsWithDaysAgo = useMemo(() => {
    return (allUsersApi.data || []).map((row: UserResponse) => ({
      ...row,
      daysAgo: calculateDaysAgo(row?.last_login),
    }));
  }, [allUsersApi.data]);

  const handleRowClick = (params: { row: UserResponse }, mode: "edit" | "overview") => {
    const row = params.row;
    if (row.hierarchy.length === 1) {
      setSelectedRowData(row);
      setDialogMode(mode);
      setOpenActionsDialog(true);
    }
  };

  const columns: GridColDef[] = [
    allColumns.actionsTreeColumnNative(
      (params: GridRowParams) => [
        <GridActionsCellItem
          key="edit"
          onClick={(_event) => {
            handleRowClick({ row: params.row }, "edit");
          }}
          label={t("general.edit")}
          showInMenu
        />,
      ],
      (params: GridRowParams) => (params.row?.hierarchy?.length || 0) === 1 && user?.role === "admin"
    ),
    allColumns.companyColumn,
    allColumns.mailColumn,
    allColumns.usersMakerColumn,
    allColumns.privilegeColumns,
    allColumns.roleColumns,
    allColumns.lastLoginColumn,
    allColumns.lastActiveColumn,
    {
      ...allColumns.leftAlignedColumn,
      field: "comment",
      headerName: t("tableColumns.comment") || "comment",
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
      valueGetter: allColumns.createValueGetter("comment", false, 0, false)
    },
    allColumns.updatedOnlyDateColumn,
    {...allColumns.timeAddedColumn,
      headerName: t('tableColumns.created') || 'Created',
    },
  ];

  const groupingUserColDef: GridColDef = {
    headerName: t('tableColumns.name') || 'Name',
    headerClassName: 'grouping-header',
    field: 'name',
    width: 220,
    renderCell: (params: GridRenderEditCellParams) => (
      <CustomGridTreeDataGroupingCell
        {...params}
        content={
          params.row.hierarchy?.length === 1 ? params.row.name || na : ''
        }
      />
    ),
    valueGetter: (_: any, row: Record<string, any>) =>
      row.hierarchy?.length === 1 ? row.name || na : '',
  };

  const getRowClassName = (params: GridRowClassNameParams<any>) => {
    if (params.row.hierarchy?.length > 1) {
      return `nested-row`;
    }

    return '';
  };

  const handleCreateClick = () => {
    setDialogMode("create");
    setOpenActionsDialog(true);
  };

  const sortModel = useMemo(
    () => [
      {
        field: "updated",
        sort: "desc" as GridSortDirection,
      },
    ],
    []
  );

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          id="usersManager"
          rows={rowsWithDaysAgo || []}
          columns={columns}
          loading={allUsersApi.showSkeletton}
          filterBarType={2}
          searchPlaceholder={
            t('tableColumns.searchManageUsersPlaceholder') ||
            'Search, Manage, Users'
          }
          sortModel={sortModel}
          hasCheckboxes={false}
          hiddenFields={['mail', 'last_login', 'comment']}
          hasExport={false}
          isError={allUsersApi.isError}
          error={allUsersApi.error}
          treeData
          onRowClick={(params) => handleRowClick(params, "overview")}
          getTreeDataPath={(row) => row.hierarchy}
          groupingColDef={groupingUserColDef}
          getRowClassName={getRowClassName}
          initialPageSize={100}
          hasControls={user?.role === "admin"}
          onCreateClick={handleCreateClick}
        />
      </Paper>
      <ActionsDialog
        open={openActionsDialog}
        selectedRowData={selectedRowData as UserResponse}
        onClose={() => setOpenActionsDialog(false)}
        onSuccess={() => { } }
        dialogMode={dialogMode}/>
    </ContentContainer>
  );
};

export default UsersManager;
