import { TFunction } from "i18next";

export const convertMonth = (target: number, t: TFunction<"translation", undefined, "translation">): string => {
    const years = Math.floor(target / 12);
    const months = target % 12;

    const yearPart = years > 0
        ? (years === 1 ? `${years} ${t('general.year')}` : `${years} ${t('general.years')}`)
        : "";
    const monthPart = months > 0
        ? (months === 1 ? `${months} ${t('general.month')}` : `${months} ${t('general.months')}`)
        : "";

    return [yearPart, monthPart].filter(Boolean).join(" ");
};

export const convertDays = (target: number, t: TFunction<"translation", undefined, "translation">): string => {
    const years = Math.floor(target / 365);
    const remainingDaysAfterYears = target % 365;
    const months = Math.floor(remainingDaysAfterYears / 30);
    const days = remainingDaysAfterYears % 30;

    const yearPart = years > 0
        ? (years === 1 ? `${years} ${t('general.year')}` : `${years} ${t('general.years')}`)
        : "";
    const monthPart = months > 0
        ? (months === 1 ? `${months} ${t('general.month')}` : `${months} ${t('general.months')}`)
        : "";
    const dayPart = days > 0
        ? (days === 1 ? `${days} ${t('general.day')}` : `${days} ${t('general.days')}`)
        : "";

    return [yearPart, monthPart, dayPart].filter(Boolean).join(" ");
};