import { Box, Typography, Popover } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { BrandCellProps } from './types';
import useCustomTableUtils from '@/hooks/useColumnsData/utils';
import { debounce } from '@/hooks/useDebaunce';
import ColorPicker from '../ColorPickerModal';
import { useTranslation } from 'react-i18next';
import useData from '@/hooks/auth/useData';

const BrandCell: FC<BrandCellProps> = ({
  brand,
  height = '100%',
  manufacturer,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [accessAnchorEl, setAccessAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const brandColor = brand?.COLOR ?? '#E0E0E0';
  const { t } = useTranslation();

  const { na } = useCustomTableUtils();
  const { setupBrandsApi, manufacturersApi, user } = useData();

  const debouncedUpdateColor = useCallback(
    debounce((newColor: string) => {
      setupBrandsApi.execute({
        brand_id: brand.ID,
        color: newColor,
      });
    }, 500),
    [brand]
  );

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    const m = manufacturersApi?.manufacturers.data?.find(
      (m) => m.ID === manufacturer
    );

    if (!(m?.access === 'write' || user?.role === 'admin')) {
      setAccessAnchorEl(event.currentTarget);
      return;
    }

    event.stopPropagation();
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (newColor: string) => {
    try {
      debouncedUpdateColor(newColor);
      if (brand) brand.COLOR = newColor;
    } catch (error) {
      console.error(error);
    }
  };

  const handleAccessClose = () => {
    setAccessAnchorEl(null);
  };

  return brand && brand.BRAND ? (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          width: '100%',
          height: height,
          cursor: 'pointer',
        }}
        onClick={handleOpen}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mr: '8px',
          }}
        >
          <Box
            sx={{
              width: 14,
              height: 6,
              backgroundColor: brand.COLOR ?? '#E0E0E0',
              mr: '4px',
            }}
          />
          <Typography fontWeight={400}>{brand.BRAND}</Typography>
        </Box>
      </Box>
      <ColorPicker
        anchorEl={anchorEl}
        handleClose={handleClose}
        initialValue={brandColor}
        handleColorChange={handleColorChange}
        title={t('pages.myProduct.colorPickerTitle')}
      />
      <Popover
        open={Boolean(accessAnchorEl)}
        anchorEl={accessAnchorEl}
        onClose={handleAccessClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <Typography variant="body2" color="error">
            {t('pages.myProduct.accessDeniedMessage')}
          </Typography>
        </Box>
      </Popover>
    </>
  ) : (
    <Box>{na}</Box>
  );
};

export default BrandCell;
