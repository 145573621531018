import { useEffect, useRef } from 'react';
import Quill from 'quill';
import Delta from 'quill-delta';
import 'quill/dist/quill.snow.css';
import { useTheme } from "@mui/material";
import "./TextEditor.css";
import { TextEditorType } from './types';
import { t } from "i18next";
import { sanitize } from '@/utils/htmlText';

const TextEditor: React.FC<TextEditorType> = ({
    onChange,
    value,
    disabled = false
}) => {
    const editorRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const quillInstance = useRef<Quill | null>(null);
    const theme = useTheme();
    const emptyLine = "<p><br></p>";

    const handleTextChange = () => {
        if (quillInstance.current) {
            const htmlContent = quillInstance.current.root.innerHTML;
            const newValue = htmlContent !== emptyLine ? htmlContent : "";
            onChange(newValue);
        }
    };

    useEffect(() => {
        if (!editorRef.current || !containerRef.current) return;
        editorRef.current.innerHTML = "";
        

        if (!quillInstance.current) {
            quillInstance.current = new Quill(editorRef.current, {
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: [
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            ["bold", "italic", "underline", "strike", "blockquote"],
                            [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                            ],
                        ],
                    },
                },
                placeholder: t("pages.buyNowComponent.editModal.items.textPlaceholder") || "",
            });
            quillInstance.current.on("text-change", handleTextChange);

            // Customize clipboard to strip unwanted inline styles => colors are not used from clipbooard
            const clipboard = quillInstance.current.getModule('clipboard') as  Quill['clipboard'];
            clipboard.addMatcher(Node.ELEMENT_NODE, (node: Node, delta: Delta) => {
                const ops = delta.ops.map((op) => {
                    if (op.attributes) {
                        // Remove inline color and background styles
                        const { color, background, ...otherAttributes } = op.attributes;
                        op.attributes = otherAttributes;
                    }
                    return op;
                });
                delta.ops = ops;
                return delta;
            });

            if (value) {
                const content: { html?: string; text?: string } = { html: sanitize(value) };
                const initialDelta = quillInstance.current.clipboard.convert(content);
                quillInstance.current.setContents(initialDelta);
            }

            /**
             * In strict mode (local development) it happens that the toolbar is rendered twice
             * => remove duplicated toolbars for local dev
             */
            const toolbars = containerRef.current.querySelectorAll('.custom-quill .ql-toolbar');
            toolbars?.forEach((toolbar, index) => {
                if (index !== toolbars.length - 1) {
                    (toolbar as HTMLElement).style.display = 'none';
                }
            });
        }

        if (quillInstance.current) {
            quillInstance.current.enable(!disabled);
        }

        return () => {
            if (quillInstance.current) {
                quillInstance.current.off("text-change", handleTextChange);
                quillInstance.current = null;
            }
        };
    }, []);
    
    return (
        <div 
            ref={containerRef}
            className={`custom-quill${theme.palette.mode === "dark" ? " custom-dark-quill" : ""}`}
            >
            <div 
                ref={editorRef}
                style={{ height: '300px' }} 
            />
        </div>
    )
};

export default TextEditor;