import React from "react";
import { LinearProgress, Box, Typography } from "@mui/material";

interface ProgressBarProps {
  value: number;
  max: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, max }) => {
  const progress = (value / max) * 100;

  return (
    <Box display="flex" alignItems="center" role="progressbar" aria-valuenow={value} aria-valuemin={0} aria-valuemax={max} aria-label={`Progress: ${value} out of ${max}`}>
      <Box width="100%" mr={"8px"}>
        <LinearProgress
          variant="determinate"
          value={progress}
          role="progressbar"
          aria-label={`Progress: ${value} out of ${max}`}
          sx={{ width: 116, height: 4 }}
        />
      </Box>

      <Box minWidth={35}>
        <Typography
          id="progress-bar-label"
          variant="body1"
          color="textPrimary"
        >
          {`${value}/${max}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
