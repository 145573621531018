import {useApiMutation} from "@/api/useApiMutation";
import {ContentType, createMutationFunction} from "@/api/mutations/createMutationFunction";
import {CreateUserApiType, UserCreateRequest} from "@/api/management/types";
import {UserResponse} from "@/types/user";

export const useCreateUser = (): CreateUserApiType => {
  return useApiMutation<UserResponse, UserCreateRequest>({
    mutationFn: (variables, signal) => {
      return createMutationFunction<UserResponse, UserCreateRequest>(
        "app/management/users",
        ContentType.JSON,
        "POST"
      )(variables, signal);
    },
  });
};