import React, { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import { Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import {dependentAPIs, hiddenTableColumns, selectorsConfig} from "./consts";
import FiltersPanel from "@/components/FiltersPanel";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { useTranslation } from "react-i18next";
import {PricingTableResponseLine} from "@/api/pricing/types";

const Paper = styled(MuiPaper)(spacing);

const PricingOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { pricingTableApi } = useData();

  const rows: PricingTableResponseLine[] = useMemo(() => {
    return pricingTableApi?.data || [];
  }, [pricingTableApi?.data]);
  const allColumns = useColumns();

  const columns: GridColDef<PricingTableResponseLine>[] = [
    allColumns.posColumn,
    allColumns.brandNameColumn,
    {
      ...allColumns.productLinkColumn,
      flex: 1
    },
    allColumns.sizeColumn,
    allColumns.skuColumn,
    {
      ...allColumns.boldPriceColumn,
      field: "lastDRP",
      headerName: t("tableColumns.lastDRP") || "Last DRP",
      valueGetter: allColumns.createRawValueGetter("last_price")
    },
    {
      ...allColumns.priceColumn,
      field: "avp",
      headerName: t("tableColumns.rawAvp") || "AVP",
      valueGetter: allColumns.createRawValueGetter("avp")
    },
    {
      ...allColumns.percentageColumn,
      field: "disc",
      headerName: t("tableColumns.discount") || "Discount",
      valueGetter: allColumns.createValueGetter("DISC")
    },
    {
      ...allColumns.dateColumn,
      field: "price_changed",
      width: 100,
      headerName: t("tableColumns.lastChanged") || "Last Changed",
      valueGetter: allColumns.createValueGetter("price_changed")
    },
    {
      ...allColumns.priceColumn,
      field: "oldPrice",
      headerName: t("tableColumns.priceOld") || "Old Price",
      valueGetter: allColumns.createRawValueGetter("price_at_last_change")
    },
    {
      ...allColumns.coloredPercentageColumn,
      field: "delta",
      headerName: t("tableColumns.delta") || "Delta",
      valueGetter: allColumns.createValueGetter("price_difference")
    },
  ];

  return (
    <ContentContainer>
      <FiltersPanel
        selectorsConfig={selectorsConfig}
        dependentAPIs={dependentAPIs}
        hasDaysFilter={false}
        hasMoreButton={false}
      />
      <Paper>
        <MuiGridTable
          id="pricingOverview"
          rows={rows}
          columns={columns}
          hiddenFields={hiddenTableColumns}
          loading={pricingTableApi.showSkeletton}
          filterBarType={2}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
          isError={pricingTableApi?.isError}
          error={pricingTableApi?.error}
        />
      </Paper>
    </ContentContainer>
  );
};

export default PricingOverview;
