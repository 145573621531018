import { createMutationFunction } from "./mutations/createMutationFunction";
import { useApiMutation, ApiMutation } from "./useApiMutation";

export type BuyNowTransactionsApiType = ApiMutation<BuyNowTransactionResponse, BuyNowTransactionsParams>;

export interface BuyNowTransactionsParams {
    manufacturers: number[];
    from_date: number;
    to_date: number;
}

export interface BuyNowTransactionElement {
    currency: string | null
    hierarchy: string[]
    id: string
    items: number | null
    name: string | null
    property: string | null
    timelead?: string // only on POS
    timestamp?: string // only on POS
    pzn?: string // only on product
    type: "POS" | "product"
    url?: string
    value: number | null
}

export type BuyNowTransactionResponse = BuyNowTransactionElement[];

const key = "buynow-transactions";

export const useBuyNowTransactions = () => {
    return useApiMutation<BuyNowTransactionResponse, BuyNowTransactionsParams>({
        mutationFn: createMutationFunction<BuyNowTransactionResponse, BuyNowTransactionsParams>("dashboard/buynow/transactions/"),
    });
    
};
