import {
  ContentType,
  createMutationFunction,
} from '../mutations/createMutationFunction';
import { useApiMutation } from '../useApiMutation';
import { Brand, BrandParams } from './types';

const key = 'categories';

export const useBrands = () => {
  const brands = useApiMutation<Brand, BrandParams>({
    mutationFn: (variables, signal) => {
      return createMutationFunction<Brand, BrandParams>(
        `brands/${variables?.brand_id}`,
        ContentType.JSON,
        'POST'
      )(variables, signal);
    },
  });

  return brands;
};
