import React, { useMemo, useState, useCallback, useRef } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress,
  useTheme,
  Typography,
  Tooltip,
} from "@mui/material";
import type { Manufacturer } from "@/api/useManufacturers";
import useData from "@/hooks/auth/useData";
import { t } from "i18next";

const ManufacturerFilter = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingIds, setLoadingIds] = useState<Set<number>>(new Set());
  const theme = useTheme();
  const { setManufacturer, selectedManufacturers, manufacturersApi, user } = useData();
  const firstMenuItemRef = useRef<HTMLLIElement | null>(null);

  const MAX_SELECTION = 5;

  const manufacturersData = useMemo(
    () => manufacturersApi?.manufacturers?.data || [],
    [manufacturersApi?.manufacturers?.data]
  );

  const initialFilteredManufacturers = useMemo(() => {
    if (showAll || user?.role === "user") return manufacturersData;
    const selectedIds = new Set(selectedManufacturers.map((m) => m.ID));
    return manufacturersData.filter(
      (manufacturer) =>
        manufacturer.GROUP === "Active" || selectedIds.has(manufacturer.ID)
    );
  }, [manufacturersData, showAll, selectedManufacturers]);

  const filteredManufacturers = useMemo(() => {
    return initialFilteredManufacturers.filter(
      (manufacturer) =>
        manufacturer.NAME.toLowerCase().includes(searchQuery) ||
        manufacturer.GROUP?.toLowerCase().includes(searchQuery)
    );
  }, [searchQuery, initialFilteredManufacturers]);

  const groupedManufacturers = useMemo(() => {
    const grouped = filteredManufacturers.reduce(
      (acc: Record<string, Manufacturer[]>, manufacturer) => {
        const group = user?.role === "admin" ? 
        (manufacturer.GROUP === "Active" ? "Active" : "Others") : "All";
        if (!acc[group]) acc[group] = [];
        acc[group].push(manufacturer);
        return acc;
      },
      {}
    );
    Object.keys(grouped).forEach((group) =>
      grouped[group].sort((a, b) => a.NAME.localeCompare(b.NAME))
    );
    const sortedGroupedKeys = Object.keys(grouped).sort((a, b) => {
      if (a === "Active") return -1;
      if (b === "Active") return 1;
      return a.localeCompare(b);
    });
    const sortedGrouped = sortedGroupedKeys.reduce((acc, key) => {
      acc[key] = grouped[key];
      return acc;
    }, {} as Record<string, Manufacturer[]>);
    return sortedGrouped;
  }, [filteredManufacturers]);

  const handleToggleManufacturer = (manufacturer: Manufacturer) => {
    const { ID } = manufacturer;
    setLoadingIds((prev) => new Set(prev).add(ID)); // Add loading state
  
    setTimeout(() => {
      const isSelected = selectedManufacturers.some((m) => m.ID === manufacturer.ID);
  
      if (isSelected) {
        // Prevent unchecking the last selected manufacturer
        if (selectedManufacturers.length === 1) {
          setLoadingIds((prev) => {
            const newSet = new Set(prev);
            newSet.delete(ID);
            return newSet;
          });
          return;
        }
  
        setManufacturer(
          selectedManufacturers.filter((m) => m.ID !== manufacturer.ID)
        );
      } else {
        setManufacturer([...selectedManufacturers, manufacturer]);
      }
  
      setLoadingIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(ID);
        return newSet;
      });
    }, 300);
  };
  
  const handleSpanClick = (manufacturer: Manufacturer) => {
    const { ID } = manufacturer;
    setLoadingIds((prev) => new Set(prev).add(ID));
  
    setTimeout(() => {
      setManufacturer([manufacturer]);
  
      setLoadingIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(ID);
        return newSet;
      });
    }, 300);
  };

  const handleShowAll = () => {
    setLoading(true);
    setTimeout(() => {
      setShowAll(true);
      setLoading(false);
    }, 500);
  };

  const renderedManufacturers = useMemo(() => {
    const isMaxSelectionReached =
      selectedManufacturers.length >= MAX_SELECTION;

    return Object.keys(groupedManufacturers).map((group, groupIndex) => (
      <>
        {groupedManufacturers[group].map((manufacturer: Manufacturer, index) => (
          <MenuItem
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
                  handleSpanClick(manufacturer);
                }
            }}
            sx={{
              padding: "0px 0px",
              height: "32px",
              ...(index == 0 && groupIndex === 0 && { marginTop: "7px" }),
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            ref={index == 0 && groupIndex === 0 ? firstMenuItemRef : null}
            key={manufacturer.ID}
            disabled={
              (loadingIds.size > 0 && !loadingIds.has(manufacturer.ID))
            }
            selected={selectedManufacturers.some(
              (m) => m.ID === manufacturer.ID
            )}
          >
            <Box
              sx={{
              display: "flex",
              alignItems: "center",
              width: "35px",
              justifyContent: "center",
            }}>
              {loadingIds.has(manufacturer.ID) ? (
                <CircularProgress
                  size={16}
                />
              ) : (
                <Tooltip
                  title={
                    selectedManufacturers.length === 1 &&
                    selectedManufacturers.some((m) => m.ID === manufacturer.ID)
                      ? t("profile.navBar.atLeastOneManufacturer") // New message
                      : !selectedManufacturers.some((m) => m.ID === manufacturer.ID) &&
                        isMaxSelectionReached
                      ? t("profile.navBar.maximumReached")
                      : t("profile.navBar.multiSelect")
                  }
                  arrow
                  placement="left"
                  componentsProps={
                    selectedManufacturers.length === 1 &&
                    selectedManufacturers.some((m) => m.ID === manufacturer.ID)
                      ? {
                          tooltip: {
                            sx: {
                              backgroundColor: theme.palette.warning.main,
                              color: theme.palette.common.white,
                            },
                          },
                          arrow: {
                            sx: {
                              color: theme.palette.warning.main,
                            },
                          },
                        }
                      : !selectedManufacturers.some((m) => m.ID === manufacturer.ID) &&
                        isMaxSelectionReached
                      ? {
                          tooltip: {
                            sx: {
                              backgroundColor: theme.palette.warning.main,
                              color: theme.palette.common.white,
                            },
                          },
                          arrow: {
                            sx: {
                              color: theme.palette.warning.main,
                            },
                          },
                        }
                      : {}
                  }
                >
                  <span>
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleManufacturer(manufacturer);
                      }}
                      disabled={
                        !selectedManufacturers.some((m) => m.ID === manufacturer.ID) &&
                        isMaxSelectionReached
                      }
                      sx={{
                        width: 2,
                        height: 2,
                        padding: "15px",
                        "&:hover": {
                          background: theme.palette.action.selected,
                        },
                      }}
                      checked={selectedManufacturers.some(
                        (m) => m.ID === manufacturer.ID
                      )}
                    />
                  </span>
                  </Tooltip>
              )}
            </Box>
            <Typography
              sx={{
                width: "100%",
                paddingLeft: "3px",
                height: "inherit",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexGrow: 5,
                "&:hover": {
                  background: theme.palette.action.hover,
                },
              }}
              onClick={() => handleSpanClick(manufacturer)}
            >
            {manufacturer.NAME}
            </Typography>
          </MenuItem>
        ))}
      </>
    ));
  }, [
    groupedManufacturers,
    handleToggleManufacturer,
    selectedManufacturers,
    loadingIds,
    theme.palette.text.primary,
    theme.palette.action.hover,
  ]);

  const handleInputKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === "ArrowDown" && firstMenuItemRef.current) {
      e.preventDefault();
      firstMenuItemRef.current.focus();
    }
  };

  return (
    <>
      <TextField
        size="small"
        sx={{
          width: 225,
          "& .MuiOutlinedInput-root": {
            borderRadius: 0,
            paddingLeft: "5px",
            "& fieldset": {
             border: "none",
            },
            "& .MuiInputBase-input": {
              padding: "0px 0 7px 2px",
              textAlign: "left",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
          "& .MuiInputBase-root": {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
        }}
        placeholder={t("profile.navBar.searchManufacturers") || "Search manufacturers..."}
        value={searchQuery}
        onKeyDown={handleInputKeyDown} // Handle key down to focus the first menu item
        onChange={(e) => { e.target.value.trim() && setShowAll(true); setSearchQuery(e.target.value.toLowerCase())}}
      />
      {renderedManufacturers}
      {!showAll && user?.role === "admin" && (
        <Box sx={{ textAlign: "center", marginTop: theme.spacing(2)}}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleShowAll}
            disabled={loading}
          >
            {loading ? <CircularProgress size={16} /> : t("Show All")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default ManufacturerFilter;
