import React, { useEffect, useState, useMemo } from "react";
import { BuyNowTransactionsParams } from "@/api/useBuyNowTransactions";
import useData from "@/hooks/auth/useData";
import ContentContainer from "@/components/contentContainer";
import MuiGridTable from "@/components/tables/DataGridTable";
import { Paper } from "@mui/material";
import { useColumns } from "@/hooks/useColumnsData";
import { GridColDef, GridSortItem } from "@mui/x-data-grid-pro";
import { t } from "i18next";

export const BuyNowTransactions = () => {
  const {
    buyNowTransactionsApi,
    selectedManufacturers,
    dateRange,
    daysChanged,
  } = useData();
  const columns = useColumns();


  const executeApiCall = () => {
    const id = selectedManufacturers.map((m) => m?.ID);
    if (buyNowTransactionsApi && id) {
      const params: BuyNowTransactionsParams = {
        manufacturers: id,
        from_date: dateRange.from_date,
        to_date: dateRange.to_date,
      };
      buyNowTransactionsApi.execute(params);
    }
  };

  useEffect(() => {
    executeApiCall();
  }, [selectedManufacturers, dateRange, daysChanged]);
  

  const myDataGridColumns: Array<GridColDef> = [
    { ...columns.posTransColumn, flex: 0.5 },
    columns.pznTransColumn,
    columns.productTransColumn,
    {
      ...columns.itemsColumn,
      width: 50,
      align: "right",
    },
    columns.priceValueColumn,
    columns.timeLeadTransColumn,
    { ...columns.propertyTransColumn, flex: 0.5 },
  ];

  const rows = useMemo(() => {
    if (!buyNowTransactionsApi?.data) return [];
    const data = buyNowTransactionsApi?.data || [];
    const excludedRows = new Set<string>();
    return data.filter(row => {
      if (excludedRows.has(row.id)) {
        return false; // Skip this row
      }
      // Process only top-level rows
      if (row.hierarchy.length === 1) {
        // Find related rows (maximum depth of 2)
        const relatedRows = data.filter(r =>
          r.hierarchy.slice(0, row.hierarchy.length).join() === row.hierarchy.join() &&
          r.hierarchy.length == 2
        );
        const pzns = relatedRows.map(r => r.pzn).filter(Boolean);
        const productNames = relatedRows.map(r => r.name).filter(Boolean);
        
        if (relatedRows.length === 1) {
          excludedRows.add(relatedRows[0].id)
        }

        Object.assign(row, {
          pzn: pzns.sort().join(', '),
          combinedNames: productNames.sort().join(', '),
        });
      }

      // Return other rows unchanged
      
      return true;
    });
  }, [buyNowTransactionsApi?.data]);

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          id="buynowTransactions"
          filterBarType={2}
          rows={rows}
          columns={myDataGridColumns}
          loading={buyNowTransactionsApi?.showSkeletton || false} // for data grid pro it does not work
          getTreeDataPath={(row) => row.hierarchy || []}
          treeData
          sortModel={[
            {
              field: '__tree_data_group__',
              sort: 'desc',
            },
          ]}
          groupingColDef={columns.groupingTransColDef}
          getRowClassName={(params) => {
            if (params.row.type === "product" || params.row.keyword) {
              return "gray-50-row";
            }
            return "";
          }}
          tableInfo={t("tableInfo.buyNowTransactions") || ""}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
          isError={buyNowTransactionsApi?.isError || false}
          error={buyNowTransactionsApi?.error || ""}
        />
      </Paper>
    </ContentContainer>
  );
};
