import React, { useState } from "react";
import { Box, Popover, Typography } from "@mui/material";
import { ChromePicker, ColorResult } from "react-color";
import { useTranslation } from "react-i18next";
import { ColorPickerProps } from "./types";


export default function ColorPicker({
  anchorEl,
  handleClose,
  handleColorChange,
  initialValue = "rgba(255, 255, 255, 1)",
  title, 
}: ColorPickerProps) {
  const { t } = useTranslation();
  const [rgba, setRgba] = useState(initialValue);

  const rgbaToHex = (r: number, g: number, b: number, a: number) => {
    const toHex = (value: number) => value.toString(16).padStart(2, "0");
    const alpha = Math.round(a * 255);
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(alpha)}`;
  };

  const handleChangeColor = (newColor: ColorResult) => {
    const { r, g, b, a } = newColor.rgb;
    const hexWithAlpha = rgbaToHex(r, g, b, a ?? 1);
    setRgba(`rgba(${r}, ${g}, ${b}, ${a})`);
    handleColorChange(hexWithAlpha);
  };

  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box sx={{ padding: "10px" }}>
        <Typography
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "center",
            boxShadow:
              "rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px",
          }}
          variant="h6"
        >
          {title}
        </Typography>
        <ChromePicker
          color={rgba}
          onChange={handleChangeColor}
          styles={{
            default: {
              saturation: {
                height: "100px",
              },
            },
          }}
        />
      </Box>
    </Popover>
  );
}
