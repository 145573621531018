import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Alert,
} from '@mui/material';
import { AxiosError } from 'axios';
import { t } from 'i18next';

interface DynamicDialogProps {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  successText?: string;
  errorText?: string;
  isLoading?: boolean;
  closeOnBackdropClick?: boolean;
  content: React.ReactNode;
  title: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  isPrimaryBtnDisabled?: boolean;
  showSuccessOutside?: boolean;
  onSuccess?: (data: any) => void;
  footer?: React.ReactNode;
  primaryButtonColor?: "primary" | "inherit" | "error" | "success" | "info" | "warning" | "secondary";
}

interface AxiosErrorDetail {
  detail: string;
}

const DynamicDialog: React.FC<DynamicDialogProps> = ({
  open,
  onClose,
  onClick,
  successText = t("general.operationSuccessful") || "Operation successful",
  errorText = t("general.baseError") || "Something went wrong",
  isLoading = false,
  closeOnBackdropClick = true,
  content,
  title,
  isPrimaryBtnDisabled,
  primaryButtonText,
  secondaryButtonText,
  showSuccessOutside = false,
  onSuccess,
  footer,
  primaryButtonColor = "primary",
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleAxiosError = (error: unknown, defaultErrorMessage: string): string => {
    if (error && (error as AxiosError).isAxiosError) {
      const axiosError = error as AxiosError<{ detail?: Array<any> }>;
      
      if (Array.isArray(axiosError.response?.data?.detail)) {
        const detailMessages = axiosError.response?.data?.detail
          .map((item) => item.msg || "An error occurred")
          .join(", ");
        return detailMessages || defaultErrorMessage;
      }
  
      return (
        axiosError.response?.data?.detail ||
        axiosError.response?.statusText ||
        defaultErrorMessage
      );
    }
  
    return (error as Error)?.message || defaultErrorMessage;
  };

  const handleClick = async () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    try {
      const result = await onClick();
      if (showSuccessOutside) {
        onClose();
      } else {
        setSuccessMessage(successText);
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error: unknown) {
      const errorMessage = handleAxiosError(error as AxiosError<AxiosErrorDetail>, errorText);
      setErrorMessage(errorMessage);
    }
  };

  useEffect(() => {
    if (open) {
      setErrorMessage(null);
      setSuccessMessage(null);
    }
  }, [open]);

  const onDialogClose = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeOnBackdropClick && !isLoading ? onDialogClose : undefined}
      aria-labelledby="dynamic-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle fontSize={"24px"}>{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions sx={{ padding: "0 24px 0px 24px" , paddingBottom: footer ? 0 : "24px", justifyContent: "flex-start",}}>
        {secondaryButtonText && (
          <Button onClick={onDialogClose} color="secondary" disabled={isLoading}>
            {secondaryButtonText}
          </Button>
        )}
        {primaryButtonText && (
          <Button onClick={handleClick} variant="contained" disabled={isPrimaryBtnDisabled || isLoading} color={primaryButtonColor}>
            {isLoading ? <CircularProgress size={24} /> : primaryButtonText}
          </Button>
        )}
        {errorMessage && (
          <Alert variant="filled" severity="error" sx={{ padding: '0px 16px'}}>
            {errorMessage}
          </Alert>
        )}
        {!showSuccessOutside && successMessage && (
          <Alert variant="filled" severity="success" sx={{ padding: '0px 16px'}}>
            {successMessage}
          </Alert>
        )}
      </DialogActions>
      { footer && <DialogContent>{footer}</DialogContent>}
    </Dialog>
  );
};

export default DynamicDialog;
