import {useState, useEffect, useRef} from "react";
import {
    Box,
    Grid,
    IconButton,
    Paper,
    Typography,
    Button,
    Snackbar,
    Tooltip,
    Skeleton,
    useTheme
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ContentCopy, Refresh, Star, SwapHoriz} from "@mui/icons-material";
import styled from "@emotion/styled";
import {diffWords, Change} from "diff";
import {renderText} from "./utils";
import {textStyles, titleStyles} from "./styles";
import useData from "@/hooks/auth/useData";
import { AnalyticsRequestBody } from "@/components/FiltersPanel/types";
import ContentContainer from "@/components/contentContainer";

import FiltersPanel from "@/components/FiltersPanel";
import { selectorsConfig, dependentAPIs } from "./consts";

export const AiOptimizer = (): JSX.Element => {

    const { t } = useTranslation();
    const { aiOptimizerApi, merchantProductContentApi } = useData()
    const theme = useTheme();

    const [loading, setLoading] = useState({
        global: false,
        teaser: false,
        description: false,
    });

    const [generatedContent, setGeneratedContent] = useState({
        teaser: t('pages.aiOptimizer.generationHelp'),
        description: t('pages.aiOptimizer.generationHelp'),
    });

    const [descriptionGenerated, setDescriptionGenerated] = useState(false);
    const [teaserGenerated, setTeaserGenerated] = useState(false);

    const [originalContent, setOriginalContent] = useState({
        teaser: t('pages.aiOptimizer.originalTextHelp'),
        description: t('pages.aiOptimizer.originalTextHelp')
    });

    const [showDiff, setShowDiff] = useState({
        teaser: false,
        description: false,
    });

    const [copySuccess, setCopySuccess] = useState(false);
    const [copyMessage, setCopyMessage] = useState('');

    const [currentSelectors, setCurrentSelectors] = useState<AnalyticsRequestBody | undefined>(undefined);

    const triggerRef = useRef(false);

    useEffect(() => {
        aiOptimizerApi?.abort();
        setGeneratedContent({
            teaser: t('pages.aiOptimizer.generationHelp'),
            description: t('pages.aiOptimizer.generationHelp'),
        });
        setTeaserGenerated(false);
        setDescriptionGenerated(false);
        setLoading({
            global: false,
            teaser: false,
            description: false,
        });
        setShowDiff({
            teaser: false,
            description: false,
        })
        triggerRef.current = false;
    }, [currentSelectors])

    useEffect(() => {
        if(merchantProductContentApi?.data) {
            setOriginalContent({
                teaser: merchantProductContentApi.data.TEASER || t('pages.aiOptimizer.noTeaser'),
                description: merchantProductContentApi.data.DESCRIPTION || t('pages.aiOptimizer.noDescription'),
            });
        } else {
            setOriginalContent({
                teaser: t('pages.aiOptimizer.originalTextHelp'),
                description: t('pages.aiOptimizer.originalTextHelp')
            });
        }
    }, [merchantProductContentApi?.data]);

    const handleGenerateClick = async () => {
        if(!currentSelectors || currentSelectors?.products?.length === 0 || currentSelectors.merchants?.length === 0) return;

        setLoading({...loading, global: true});
        triggerRef.current = true;

        try {
            // Start both requests in parallel
            const descriptionPromise = aiOptimizerApi?.execute({
                products: currentSelectors.products || [],
                merchants: currentSelectors.merchants || [],
                labels: currentSelectors.labels || [],
                type: "description"
            }, true);

            const teaserPromise = aiOptimizerApi?.execute({
                products: currentSelectors.products || [],
                merchants: currentSelectors.merchants || [],
                labels: currentSelectors.labels || [],
                type: "teaser"
            }, true);

            // Handle each promise as it resolves
            const [descriptionResult, teaserResult] = await Promise.allSettled([descriptionPromise, teaserPromise]);

            // happens if filter changes during loading
            if (!triggerRef.current) return;

            // Handle description result
            if (descriptionResult.status === "fulfilled") {
                const descriptionContent = descriptionResult.value?.optimizedContent || generatedContent.description;
                setGeneratedContent(prevContent => ({
                    ...prevContent,
                    description: descriptionContent
                }));
                setDescriptionGenerated(true);
            } else {
                setGeneratedContent(prevContent => ({
                    ...prevContent,
                    description: t("pages.aiOptimizer.generationError")
                }));
                setDescriptionGenerated(false);
            }

            // Handle teaser result
            if (teaserResult.status === "fulfilled") {
                const teaserContent = teaserResult.value?.optimizedContent || generatedContent.teaser;
                setGeneratedContent(prevContent => ({
                    ...prevContent,
                    teaser: teaserContent
                }));
                setTeaserGenerated(true);
            } else {
                setGeneratedContent(prevContent => ({
                    ...prevContent,
                    teaser: t("pages.aiOptimizer.generationError")
                }));
                setTeaserGenerated(false);
            }
        } catch (error) {
            console.error("Failed to generate content:", error);
            setGeneratedContent(prevContent => ({
                ...prevContent,
                description: t("pages.aiOptimizer.generationError"),
                teaser: t("pages.aiOptimizer.generationError")
            }));
            setTeaserGenerated(false);
            setDescriptionGenerated(false);
        } finally {
            setLoading({...loading, global: false});
            triggerRef.current = false;
        }
    };

    const handleRefreshClick = async (type: "teaser" | "description") => {
        if(!currentSelectors || currentSelectors?.products?.length === 0 || currentSelectors.merchants?.length === 0) return;

        setLoading({...loading, [type]: true});
        triggerRef.current = true;

        const setter = type === "teaser" ? setTeaserGenerated : setDescriptionGenerated;
        try {
            const response = await aiOptimizerApi?.execute({
                products: currentSelectors.products || [],
                merchants: currentSelectors.merchants || [],
                labels: currentSelectors.labels || [],
                type: type
            }, true);

             // happens if filter changes during loading
             if (!triggerRef.current) return;

            if (response) {
                const newContent = {
                    ...generatedContent,
                    [type]: response.optimizedContent || generatedContent[type],
                };

                setGeneratedContent(newContent);
                setter(true);
            }
        } catch (error) {
            console.error(`Failed to refresh ${type}:`, error);
            const newContent = {
                ...generatedContent,
                [type]: t("pages.aiOptimizer.generationError"),
            };

            setGeneratedContent(newContent);
            setter(false)
        } finally {
            setLoading({...loading, [type]: false});
            triggerRef.current = false;
        }
    };

    const handleToggleDiff = (type: "teaser" | "description") => {
        setShowDiff((prev) => ({...prev, [type]: !prev[type]}));
    };

    const getDiffText = (original: string, generated: string) => {
        const diffResult: Change[] = diffWords(original, generated);
        return diffResult.map((part: Change, index: number) => {
            const color = part.added ? "green" : part.removed ? "red" : "black";
            return (
                <span key={index} style={{color}}>
                    {part.value}
                </span>
            );
        });
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopyMessage(t("general.copiedToClipBoard") as string); // Enforce string type here
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);  // Hide message after 2 seconds
        }).catch(err => {
            setCopyMessage(t("general.failedToCopy") as string); // Enforce string type here
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);  // Hide message after 2 seconds
            console.error("Could not copy text: ", err);
        });
    };

    const GenerateButton =
        (
            <Button
                variant="contained"
                color="primary"
                startIcon={<Star />}
                onClick={handleGenerateClick}
                sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: '8px 16px',
                    borderRadius: '8px',
                    ':hover': {
                    backgroundColor: theme.palette.primary.dark,
                    },
                }}
                disabled={loading.global || !currentSelectors || currentSelectors?.products?.length === 0 || currentSelectors.merchants?.length === 0}
                >
                {t('pages.aiOptimizer.generate') as string}
            </Button>
        );


    return (
        <ContentContainer>
            <FiltersPanel
                selectorsConfig={selectorsConfig}
                dependentAPIs={dependentAPIs}
                hasDaysFilter={false}
                hasMoreButton={false}
                actionButton={GenerateButton}
                setValuesForParent={setCurrentSelectors}
            />
            {/* Teaser Row */}
            <Grid container spacing={theme.gap}>
                <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom sx={titleStyles}>
                        {t("pages.aiOptimizer.originalTeaser") as string}
                    </Typography>
                    <Paper elevation={3} sx={{padding: 2}}>
                        <Typography component="div" variant="body1" sx={textStyles}>
                            {renderText(originalContent.teaser)}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" gutterBottom
                                    sx={{...titleStyles, color: theme.palette.success.main}}>
                            {t("pages.aiOptimizer.optimizedTeaser") as string}
                        </Typography>
                        <ContentActions
                            onRefresh={() => handleRefreshClick("teaser")}
                            onCopy={() => handleCopyClick(generatedContent.teaser)}
                            onToggleDiff={() => handleToggleDiff("teaser")}
                            disabled={loading.global || loading.teaser || !teaserGenerated}
                        />
                    </Box>
                    <Paper elevation={3} sx={{padding: 2}}>
                        {loading.teaser || loading.global ? <SkeletonText/> : (
                            <Typography component="div" variant="body1" sx={textStyles}>
                                {showDiff.teaser
                                    ? getDiffText(originalContent.teaser, generatedContent.teaser)
                                    : renderText(generatedContent.teaser)
                                }
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            {/* Product Description Row */}
            <Grid container spacing={theme.gap}>
                <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom sx={titleStyles}>
                        {t("pages.aiOptimizer.originalProductDescription") as string}
                    </Typography>
                    <Paper elevation={3} sx={{padding: 2}}>
                        <Typography component="div" variant="body2" sx={textStyles}>
                            {renderText(originalContent.description)}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" gutterBottom
                                    sx={{...titleStyles, color: theme.palette.success.main}}>
                            {t("pages.aiOptimizer.optimizedProductDescription") as string}
                        </Typography>
                        <ContentActions
                            onRefresh={() => handleRefreshClick("description")}
                            onCopy={() => handleCopyClick(generatedContent.description)}
                            onToggleDiff={() => handleToggleDiff("description")}
                            disabled={loading.global || loading.description || !descriptionGenerated}
                        />
                    </Box>
                    <Paper elevation={3} sx={{padding: 2}}>
                        {(loading.description || loading.global) ? <SkeletonText/> : (
                            <Typography component="div" variant="body2" sx={textStyles}>
                                {showDiff.description
                                    ? getDiffText(originalContent.description, generatedContent.description)
                                    : renderText(generatedContent.description)}
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            {/* Snackbar for copy success message */}
            <Snackbar
                open={copySuccess}
                message={copyMessage}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            />
        </ContentContainer>
    );
};

const ContentActions = ({onRefresh, onCopy, onToggleDiff, disabled}: {
    onRefresh: () => void;
    onCopy: () => void;
    onToggleDiff: () => void;
    disabled: boolean;
}) => {
    const {t} = useTranslation();

    return (
        <Box>
            <Tooltip title={disabled ? "" : t("pages.aiOptimizer.showDiff")}>
                <IconButton
                    size="small"
                    onClick={onToggleDiff}
                    disabled={disabled}
                    aria-label={t("pages.aiOptimizer.showDiff") as string} // Accessible name
                >
                    <SwapHoriz fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title={disabled ? "" : t("pages.aiOptimizer.refresh")}>
                <IconButton
                    size="small"
                    onClick={onRefresh}
                    disabled={disabled}
                    aria-label={t("pages.aiOptimizer.refresh") as string} // Accessible name
                >
                    <Refresh fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title={disabled ? "" : t("general.copy")}>
                <IconButton
                    size="small"
                    onClick={onCopy}
                    disabled={disabled}
                    aria-label={t("general.copy") as string} // Accessible name
                >
                    <ContentCopy fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
    
};


const MagicLoader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '24px',

  '& > div': {
    width: '8px',
    height: '8px',
    margin: '0 4px',
    backgroundColor: theme.palette.success.main,
    borderRadius: '50%',
    animation: 'magic-pulse 1.4s infinite both',
  },

  '& > div:nth-of-type(1)': {
    animationDelay: '-0.32s',
  },
  '& > div:nth-of-type(2)': {
    animationDelay: '-0.16s',
  },
  '& > div:nth-of-type(3)': {
    animationDelay: '0s',
  },

  '@keyframes magic-pulse': {
    '0%, 80%, 100%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1)',
    },
  },
}));


const MagicLoaderComponent = () => (
    <MagicLoader>
        <div></div>
        <div></div>
        <div></div>
    </MagicLoader>
);

const SkeletonText = () => (
    <Box sx={{width: '100%'}}>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
        <Skeleton variant="text"/>
    </Box>
);


export default AiOptimizer;
