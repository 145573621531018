import { MutationFunction, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState, useRef, useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Interface for error callback parameters
 */
export interface ErrorCallback<TResponse> {
  error: AxiosError<unknown>;
  setError: Dispatch<SetStateAction<string | null>>;
  setData: Dispatch<SetStateAction<TResponse | undefined>>;
}

/**
 * Interface for mutation parameters
 * @template TResponse - The type of the response from the mutation
 * @template TParams - The type of the parameters for the mutation (optional)
 */
export interface ApiMutationParams<TResponse, TParams = undefined> {
  mutationFn: (variables?: TParams, signal?: AbortSignal) => Promise<TResponse>;
  onErrorCallback?: ({
    error,
    setError,
    setData,
  }: ErrorCallback<TResponse>) => void;
}

/**
 * Interface for the mutation return type
 * @template TResponse - The type of the response from the mutation
 * @template TParams - The type of the parameters for the mutation (optional)
 */
export interface ApiMutation<TResponse, TParams = undefined> {
  execute: (variables?: TParams, ignoreAbort?: boolean) => Promise<TResponse>;
  abort: () => void;
  isExecuted: boolean;
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  data: TResponse | undefined;
  showSkeletton: boolean;
}

/**
 * Custom hook to handle API mutations using react-query with abort functionality
 * @template TResponse - The type of the response from the mutation
 * @template TParams - The type of the parameters for the mutation (optional)
 * @param {ApiMutationParams<TResponse, TParams>} params - The mutation parameters
 * @returns {ApiMutation<TResponse, TParams>} - The mutation state and execute function
 */
export const useApiMutation = <TResponse, TParams = undefined>({
  mutationFn,
  onErrorCallback,
}: ApiMutationParams<TResponse, TParams>): ApiMutation<TResponse, TParams> => {
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<TResponse | undefined>(undefined);
  const [isExecuted, setIsExecuted] = useState<boolean>(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const location = useLocation();
  const currentPathRef = useRef(location.pathname);
  const loadingRef = useRef(false);

  // for initial load
  const hasLoadedRef = useRef(false);

  /**
   * Handles errors during the mutation process
   * @param {AxiosError} error - The error object
   */
  const handleError = (error: AxiosError) => {
    if (onErrorCallback) {
      onErrorCallback({ error, setError, setData });
      return;
    }

    setError(`${t("errors.globalError")}: ${error.message}`);
    setData(undefined);
  };

  const mutation = useMutation<
    TResponse,
    AxiosError<unknown>,
    { params?: TParams; signal?: AbortSignal }
  >({
    mutationFn: ({ params, signal }) => mutationFn(params, signal),
    onSuccess: (data) => {
      setData(data);
      setError(null);
      mutation.reset();
    },
    onError: handleError,
  });

  /**
   * Helper function to remove empty values from an object (such as empty arrays)
   * @param obj - The object to clean
   * @returns {TParams} - The cleaned object
   */
  const cleanParams = <TParams extends Record<string, any>>(obj: TParams): TParams => {
    const cleanedObj = { ...obj };

    Object.entries(cleanedObj).forEach(([key, value]) => {
      // Remove keys with empty arrays or null/undefined values
      if ((Array.isArray(value) && value.length === 0) || value == null || value == undefined) {
        delete cleanedObj[key];
      }
    });

    return cleanedObj;
  };

  /**
   * Executes the mutation with an AbortSignal.
   * @param {TParams} params - The parameters for the mutation.
   * @param {boolean} ignoreAbort - Parameter to control aborting.
   * @returns {Promise<TResponse>}
   */
  const execute = async (params?: TParams, ignoreAbort: boolean = false) => {
    if (abortControllerRef.current && !ignoreAbort) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const cleanedParams = params ? cleanParams(params) : params;
    
    setIsExecuted(true);
    return mutation.mutateAsync({ params: cleanedParams, signal });
  };

  /**
   * Aborts the ongoing request if there is one.
   */
  const abort = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  useLayoutEffect(() => {
    if (!hasLoadedRef.current) {
      hasLoadedRef.current = true;
      return; // Skip logic on first render
    }

    // reset is executed on path changes
    if (currentPathRef.current !== location.pathname) {
      currentPathRef.current = location.pathname;
      if(!loadingRef.current) {
        setIsExecuted(false);
      }
    }

    return () => {
      abort();
    };
  }, [location.pathname]);

  useEffect(() => {
    loadingRef.current = mutation.isPending;
  }, [mutation.isPending])

  return {
    execute,
    abort,
    isExecuted,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error,
    data,
    showSkeletton: mutation.isPending || !isExecuted,
  };
};
