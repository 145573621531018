import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { gray as lighterGray } from "@/hooks/useColumnsData/consts";

export const containerStyles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
};

export const moreButtonContainerStyles: SxProps<Theme> = {
  display: "flex",
  height: "40px",
  gap: "10px",
  alignSelf: "flex-start",
};

export const moreButtonStylesContent: SxProps<Theme> = (theme) => ({
  display: "flex",
  fontWeight: "500",
  width: "84%",
  alignItems: "center",
  justifyContent: "space-between",
  color: (theme.palette.mode === "dark") ? lighterGray : theme.palette.grey[700],
});
