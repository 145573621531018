import { UserResponse } from '@/types/user';
import { createQueryFunction } from '../queries/createQueryFunction';
import { useApiQuery } from '../useApiQuery';
import {  UsersApiType } from './types';
import {useApiMutation, ErrorCallback} from "@/api/useApiMutation";
import {ContentType, createMutationFunction} from "@/api/mutations/createMutationFunction";
import {CreateUserApiType, UserCreateRequest} from "@/api/management/types";
import { t } from 'i18next';

const handleCustomError = ({
  error,
  setError,
  setData,
}: ErrorCallback<UserResponse>) => {
  const errorMessage =
  error.response && error.response.status === 400
  ? t("user.updateError")
  : `${t("errors.globalError")}: ${error.message}`;

  setError(errorMessage);
  setData(undefined);
};

export const useCreateUser = (): CreateUserApiType => {
  return useApiMutation<UserResponse, UserCreateRequest>({
    mutationFn: (variables, signal) => {
      return createMutationFunction<UserResponse, UserCreateRequest>(
        "app/management/users",
        ContentType.JSON,
        "POST"
      )(variables, signal);
    },
  });
};

const key = 'managementUsers';

export const useUsers = (): UsersApiType => {
  const allUsersApi = useApiQuery<UserResponse[]>({
    key,
    queryFn: createQueryFunction('app/management/users'),
  });
  return { allUsersApi };
};

export const useEditUser = () => {
  return useApiMutation<UserResponse, UserCreateRequest>({
      mutationFn: (variables) =>
          createMutationFunction<UserResponse, UserCreateRequest>(
              `app/management/users${variables?.user_id ? `/${variables.user_id}` : ""}`,
              ContentType.JSON,
              'PUT'
          )(variables),
      onErrorCallback: handleCustomError,
  });
};
