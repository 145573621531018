import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useData from "@/hooks/auth/useData";
import DynamicDialog from "@/components/DynamicDialog";
import { Delete, Add } from "@mui/icons-material";
import { fields, initialFormData, validationRules } from "./consts";
import { UserResponse } from "@/types/user";
import { AccountsFormData, ManufacturerRow } from "./types";
import { cleanPayload } from "./utils";
import { AccountsTreeResponse } from "@/api/management/types";
import countries from "world-countries";
import i18n from "i18next"; // Assuming you have i18n set up
import * as isoCountries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import esLocale from "i18n-iso-countries/langs/es.json";
import deLocale from "i18n-iso-countries/langs/de.json";

isoCountries.registerLocale(enLocale);
isoCountries.registerLocale(esLocale);
isoCountries.registerLocale(deLocale);

const getCountryNames = (language: string) => {
  return countries.map((country) => {
    const translatedName = isoCountries.getName(country.cca2, language) || country.name.common;
    return {
      code: country.cca2,
      name: translatedName,
      label: `${country.cca2} - ${translatedName}`,
    };
  });
};

export default function ActionsDialog({
  dialogOpen,
  setDialogOpen,
  dialogMode,
  selectedRowData,
}: {
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogMode: "create" | "edit" | "overview";
  selectedRowData?: AccountsTreeResponse;
}) {
  const { t } = useTranslation();
  const { selectedManufacturers, manufacturersApi, accountsUserApi, createAccountApi, accountsTreeApi, editAccountApi } = useData();
  const theme = useTheme();
  const [formData, setFormData] = useState<AccountsFormData>(initialFormData);
  const [rows, setRows] = useState([{ manufacturerId: "" }]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string | undefined }>({});
  const isReadOnly = useMemo(() => dialogMode === "overview", [dialogMode]);
  
  const [language, setLanguage] = useState(i18n.language); // Current language from i18n
  const [countryOptions, setCountryOptions] = useState(getCountryNames(language));
  
  useEffect(() => {
    const handleLanguageChange = () => {
      setCountryOptions(getCountryNames(i18n.language));
    };

    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    if ((dialogMode === "edit" || dialogMode === "overview") && selectedRowData) {
      setFormData({
        companyName: selectedRowData.name || "",
        companyShortName: selectedRowData.shortName || "",
        emailDomain: selectedRowData.emailDomain || "",
        csm: (primaryContacts.find((contact) => contact.user_id === selectedRowData.csm) || null) as unknown as UserResponse,
        csmURL: selectedRowData.csmUrl || "",
        accountContact: selectedRowData.accountContact || "",
        country: selectedRowData.countryCode || "",
        vatNumber: selectedRowData.vatNumber || "",
        companyRegistrationNumber: selectedRowData.companyRegistrationNumber || "",
        billingEmail: selectedRowData.billingEmail || "",
        websiteAddress: selectedRowData.websiteAddress || "",
        streetAddress: selectedRowData.streetAddress || "",
        city: selectedRowData.city || "",
        zipCode: selectedRowData.zipCode || "",
        comment: selectedRowData.comment || "",
      });

      setRows(
        selectedRowData.manufacturers?.map((manufacturer: {manufacturerId: number}) => ({
          manufacturerId: manufacturer.manufacturerId.toString(),
        })) || [{ manufacturerId: "" }]
      );
    } else if (dialogMode === "create") {
      handleResetState();
    }
  }, [dialogMode, selectedRowData, dialogOpen]);

  const manufacturersData = useMemo(
    () => manufacturersApi?.manufacturers.data || [],
    [manufacturersApi?.manufacturers]
  );

  const validateMandatoryFields = (): boolean => {
    const errors: { [key: string]: string } = {};
  
    if (!formData.companyName.trim()) {
      errors.companyName = t("pages.accountsManager.companyNameRequired");
    }
    if (!formData.companyShortName.trim()) {
      errors.companyShortName = t("pages.accountsManager.companyShortNameRequired");
    }
    if (!formData.country.trim()) {
      errors.country = t("pages.accountsManager.countryRequired");
    }
    if (!formData.csm) {
      errors.csm = t("pages.accountsManager.csmRequired");
    }
  
    if (!rows.some((row) => row.manufacturerId.trim())) {
      errors.manufacturerId = t("pages.accountsManager.manufacturerIdRequired");
    }
  
    setValidationErrors((prev) => ({ ...prev, ...errors }));
  
    return Object.keys(errors).length === 0;
  };

  const primaryContacts = useMemo(() => accountsUserApi.data || [],[accountsUserApi.data])

  useEffect(() => {
    accountsUserApi.execute({ account_ids: [Number(5)] });
  },[selectedManufacturers]);

  useEffect(() => {
    const hasErrors = Object.keys(validationErrors).some((key) => validationErrors[key]);

    const areFieldsValid = formData.companyName.trim() &&
      formData.companyShortName.trim() &&
      formData.country.trim() &&
      formData.csm &&
      rows.some((row) => row.manufacturerId.trim());
  
    setIsBtnDisabled(!areFieldsValid || hasErrors);
  }, [formData, rows]);
  
  const validateField = (name: string, value: string): { valid: boolean; message?: string } => {
    const rule = validationRules[name];
    if (rule) {
      if (value.trim() === "") {
        return { valid: true };
      }
      const isValid = rule.pattern.test(value);
      return { valid: isValid, message: isValid ? undefined : rule.message };
    }
    return { valid: true };
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) return;
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    const { valid, message } = validateField(name, value);
  
    setValidationErrors((prev) => {
      const updatedErrors = { ...prev };
      if (valid) {
        delete updatedErrors[name];
      } else {
        updatedErrors[name] = message;
      }
      return updatedErrors;
    });
  };

  const handleCountryChange = (value: string) => {
    if (isReadOnly) return;
    setFormData((prev) => ({
      ...prev,
      country: value || "",
    }));
  };

  const handleRowsChange = (index: number, field: keyof ManufacturerRow, value: string) => {
    if (isReadOnly) return;
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const addRow = () => {
    if (isReadOnly) return;
    setRows((prev) => [...prev, { manufacturerId: "" }]);
  };

  const deleteRow = (index: number) => {
    if (isReadOnly) return;
    setRows((prev) => prev.filter((_, i) => i !== index));
  };

  const handleResetState = () => {
    setFormData(initialFormData);
    setRows([{
        manufacturerId: "",
      }]);
    setValidationErrors({});
  };

  const onClose = () => {
    handleResetState();
    setDialogOpen(false);
  };

  const handleCsmChange = (_: any, value: UserResponse) => {
    setFormData((prev) => ({
      ...prev,
      csm: value,
    }));
  };
  
  const handleSubmit = async () => {
    if (isReadOnly) return;
    try {
      setIsLoading(true);
      if (!validateMandatoryFields()) {
        throw new Error(t("pages.accountsManager.formValidationFailed") || "");
      }
  
      const payload: any = cleanPayload({
        account_id : selectedRowData?.accountId || "",
        name: formData.companyName,
        abbreviation: formData.companyShortName,
        email_domain: formData.emailDomain,
        manufacturers: rows.length
          ? rows.map((row) => ({
              manufacturer_id: parseInt(row.manufacturerId, 10),
            }))
          : undefined,
        cs_manager: formData.csm?.user_id,
        csm_url: formData.csmURL,
        account_contact: formData.accountContact,
        country_code: formData.country,
        vat_number: formData.vatNumber,
        company_registration_number: formData.companyRegistrationNumber,
        billing_email: formData.billingEmail,
        website: formData.websiteAddress,
        address: formData.streetAddress,
        city: formData.city,
        zip_code: formData.zipCode,
        comment: formData.comment,
      });

      if (dialogMode === "create") {
        await createAccountApi.execute(payload);
      } else if (dialogMode === "edit") {
        await editAccountApi.execute(payload);
      }
      await accountsTreeApi.execute(undefined, false);
      handleResetState();
      setDialogOpen(false);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  
const formContent = (
  <Box>
    <Grid container spacing={3}>
      {fields.map((field) => {
        if (field.name === "country") return (
          <Grid item xs={field.xs} md={field.md}>
            <Autocomplete
              size="medium"
              options={countryOptions}
              disabled={isReadOnly}
              value={formData.country as unknown as { code: string; name: string; label: string }}
              onChange={(_, newValue) => handleCountryChange(newValue ? newValue.code : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={isReadOnly}
                  required={field.required}
                  label={t(`pages.accountsManager.${field.name}`)}
                  variant="standard" size="medium"
                  placeholder={t(`pages.accountsManager.${field.name}Placeholder`) || ""}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        );
        if (field.name === "csm") return (
          <Grid item xs={field.xs} md={field.md}>
            <Autocomplete
              size="medium"
              disabled={isReadOnly}
              options={primaryContacts}
              getOptionLabel={(option) =>
                option.first_name && option.last_name
                  ? `${option.first_name} ${option.last_name}`
                  : `${option.username ||  ""}`
              }
              value={(formData as any)[field.name]}
              onChange={handleCsmChange}
              renderInput={(params) => (
                <TextField
                  value={formData.csm}
                  {...params}
                  disabled={isReadOnly}
                  name={field.name}
                  label={t(`pages.accountsManager.${field.name}`)}
                  required={field.required}
                  variant="standard"
                  placeholder={t(`pages.accountsManager.${field.name}Placeholder`) || ""}
                  InputLabelProps={{ shrink: true }}
                  error={!!validationErrors[field.name]}
                  helperText={t(validationErrors[field.name] as string) || ""}
                />
              )}
            />
        </Grid>
        )
        return (
          <Grid item xs={field.xs} md={field.md} key={field.name}>
            <TextField
              size="medium"
              label={t(`pages.accountsManager.${field.name}`)}
              fullWidth
              required={field.required}
              InputLabelProps={{shrink: true}}
              variant="standard"
              name={field.name}
              disabled={isReadOnly || field.name === "accountContact"}
              placeholder={t(`pages.accountsManager.${field.name}Placeholder`) || ""}
              value={(formData as any)[field.name]}
              onChange={handleFormChange}
              error={!!validationErrors[field.name]}
              helperText={t(validationErrors[field.name] as string) || ""}
            />
          </Grid>
        );
      })}
    </Grid>

    {rows.map((row, index) => (
      <Grid container spacing={3} key={index} marginTop={1}>
        <Grid item xs={5}>
        <Autocomplete
          disabled={isReadOnly}
          options={manufacturersData.map((m) => ({
            value: m.ID.toString(),
            label: m.NAME,
          }))}
          value={
            manufacturersData.find((m) => String(m.ID) == row.manufacturerId)
              ? {
                  value: row.manufacturerId,
                  label:
                    manufacturersData.find((m) => String(m.ID) == row.manufacturerId)
                      ?.NAME || "",
                }
              : null
          }
          onChange={(_, newValue) =>
            handleRowsChange(index, "manufacturerId", newValue?.value || "")
          }
          renderInput={(params) => (
            <TextField
              
              error={!!validationErrors["manufacturerId"]}
              helperText={validationErrors["manufacturerId"] || ""}
              {...params}
              disabled={isReadOnly}
              required
              label={t("pages.accountsManager.manufacturerIdLabel")}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              placeholder={t("pages.accountsManager.manufacturerIdPlaceholder") || ""}
            />
          )}
        />
        </Grid>
        {/* <Grid item xs={5}>
        <Autocomplete
          disabled={isReadOnly}
          options={privileges}
          value={privileges.find((p) => p.value === Number(row.privilege)) || null}
          onChange={(_, newValue) => handleRowsChange(index, "privilege", newValue?.value || "")}
          renderInput={(params) => (
            <TextField
              error={!!validationErrors["privilege"]}
              helperText={validationErrors["privilege"] || ""}
              disabled={isReadOnly}
              {...params}
              label="Privilege"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              placeholder="Assign Privilege"
            />
          )}
        />
        </Grid> */}
        { !isReadOnly && <Grid
          item xs={12}
          md={0.9}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}>
            <IconButton onClick={() => deleteRow(index)} disabled={rows.length === 1} color="error" 
              sx={{
                height: "60%",
                width: "100%",
                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                borderRadius: 2,
              }}
            >
              <Delete sx={{ fontSize: "18px" }} />
            </IconButton>
        </Grid>}
      </Grid>
    ))}
    { !isReadOnly && <Button variant="outlined" startIcon={<Add />} onClick={addRow} style={{ marginTop: "16px" }}>
      {t("pages.accountsManager.addRowButton")}
    </Button>}
    <Grid item xs={12}>
      <TextField
        size="medium"
        disabled={isReadOnly}
        label={t("pages.accountsManager.commentLabel")}
        fullWidth
        InputLabelProps={{shrink: true}}
        name={"comment"}
        multiline
        minRows={3}
        margin="normal"
        variant="standard"
        placeholder={t("pages.accountsManager.commentPlaceholder") || ""}
        value={formData.comment}
        onChange={handleFormChange}
      />
    </Grid>
  </Box>
);

  return (
    <DynamicDialog
      open={dialogOpen}
      onClose={onClose}
      onClick={handleSubmit}
      closeOnBackdropClick={isReadOnly}
      isLoading={isLoading}
      title={t(`pages.accountsManager.${dialogMode}DialogTitle`)}
      content={formContent}
      {...(!isReadOnly
        ? {
            primaryButtonText: t("pages.accountsManager.saveButton") || "",
            secondaryButtonText: t("pages.accountsManager.cancelButton") || ""
          }
        : {})}
      isPrimaryBtnDisabled={isBtnDisabled}
    />
  );
}
