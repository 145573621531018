import { Box, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useContext } from "react";
import { DataContext } from "@/contexts/DataContext";

type Props = {
  text: string | null,
}

const CopyCell = ({text}: Props) => {
  const { t } = useTranslation();
  const {
    setCopyState,
    setOpenSnackbar,
    setSnackbarMessage
  } = useContext(DataContext)

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {            
        setCopyState("success")
        setSnackbarMessage(t("tableColumns.copiedToClipboard") || "Copied to clipboard") 
      }).catch(err => {
        console.error("Failed to copy:", err);
        setCopyState("error")
        setSnackbarMessage(t("errors.copiedToClipboard") || "Copy failed")
      });
      setOpenSnackbar(true)
  };

  
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    > 
      
      <Tooltip title={text}>
        <div style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          flexGrow: 1, 
        }}>
          {text || t("tableColumns.notAvailable") || "n.a."}
        </div>
      </Tooltip>
      {text && 
        <Tooltip title={t("tableColumns.copyToClipboard")} sx={{ cursor: "pointer" }} placement="top">
          <ContentCopyIcon onClick={() => handleCopy(text)} style={{ height: 14, cursor: "pointer" }} />
        </Tooltip>
      }
    </Box>
  )
}

export default CopyCell;