import React, { useState, useRef, useCallback, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { ComponentsResponse, Config } from "@/api/buynow/types";
import useData from "@/hooks/auth/useData";
import SetupTab from "./tabs/SetupTab";
import ItemsTab from "./tabs/ItemsTab";
import LookTab from "./tabs/LookTab";
import ListingTab from "./tabs/ListingTab";
import PosTab from "./tabs/PosTab";
import { EditDrawerProps } from "./types";
import { useTabsContext } from "@/contexts/TabsContext";
import { tabsOptions, tabsWithReset } from "./consts";

const EditDrawer: React.FC<EditDrawerProps> = ({
  open,
  onClose,
  updateRow,
}) => {
  const { t } = useTranslation();
  const {
    updateComponentsApi,
    assignConfigComponentApi,
    updateAssignmentConfigComponentApi,
    createConfigApi,
    user
  } = useData();
  const [saveStatus, setSaveStatus] = useState<"success" | "error" | null>(
    null
  );
  const [renderedTabs, setRenderedTabs] = useState(new Set([0]));
  const [reset, setReset] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    currentVariants,
    revertToDefaultValue,
    tabValue,
    setTabValue,
    setupProps,
    configs,
    defaultConfigIndex,
    initialSetupProps,
    initialConfigs,
    componentData,
    validationErrors,
    setIsLoading,
    isLoading,
    setSetupProps,
  } = useTabsContext();

  const isAdmin = user?.role === "admin";

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
      containerRef.current.scrollLeft = 0;
    }
  }, [tabValue]);

  const hasSetupPropsChanged = useCallback(
    () => JSON.stringify(setupProps) !== JSON.stringify(initialSetupProps),
    [setupProps, initialSetupProps]
  );

  const hasConfigDataChanged = useCallback(
    (currentConfig: Config, initialConfig: Config) =>
      JSON.stringify(currentConfig.config?.config || {}) !==
      JSON.stringify(initialConfig.config?.config || {}),
    []
  );

  const hasAssignmentChanged = useCallback(
    (currentConfig: Config, initialConfig: Config) =>
      currentConfig.type !== initialConfig.type ||
      currentConfig.variants !== initialConfig.variants ||
      currentConfig.start !== initialConfig.start ||
      currentConfig.end !== initialConfig.end,
    []
  );

  const onDrawerClose = () => {
    revertToDefaultValue();
    setRenderedTabs(new Set([0]));
    onClose();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setRenderedTabs((prevRenderedTabs) => new Set(prevRenderedTabs).add(newValue));
  };

  const handleUpdateAssignment = async (currentConfig: Config) => {
    try {
      console.log(
        `Assignment changed for config ${currentConfig.config_id}. Updating assignment...`
      );
      console.log("This is the new current: ", currentConfig);
      console.log("This is the index ", defaultConfigIndex);

      return await updateAssignmentConfigComponentApi?.execute({
        id: setupProps.id,
        instance_config_id: currentConfig.id,
        config_id: currentConfig.config_id,
        variants: currentConfig.variants,
        type: currentConfig.type,
        start: currentConfig.start,
        end: currentConfig.end,
      });
    } catch (error) {
      console.error("Error updating config assignment:", error);
    }
  };

  const handleCreateAndAssignConfig = async (currentConfig: Config) => {
    try {
      console.log(
        `Creating a new config for config_id ${currentConfig.config_id}...`
      );

      const updatedImages = currentConfig?.config?.config.images
        ? [...currentConfig?.config?.config.images]
        : [];
      const payloadConfig = {
        ...currentConfig.config.config,
        ...(updatedImages?.length ? { images: updatedImages } : {}),
      };
      console.log("payloadConfig", payloadConfig);
      const createdConfig = await createConfigApi?.execute({
        manufacturer_id: componentData?.install.manufacturer as number,
        config: payloadConfig,
        comment: currentConfig.config.comment,
        status: currentConfig.config.status,
        type: setupProps.type,
      });

      if (!createdConfig) {
        console.error("Failed to create new config.");
        return null;
      }

      console.log(
        `New config created: ${createdConfig.CID}. Proceeding with assignment...`
      );
      console.log("skuArray", currentVariants);

      const assignResult = await assignConfigComponentApi?.execute({
        instance_id: setupProps.id,
        config_id: createdConfig.CID,
        variants: currentVariants,
        type: 0,
        start: createdConfig.CREATED,
        end: currentConfig.end,
      });

      if (!assignResult) {
        console.error("Failed to assign the new config.");
        return null;
      }

      console.log(
        `Config assigned successfully. Updating assignment for instance_config_id ${currentConfig.id}...`
      );

      const updateAssignmentResult =
        await updateAssignmentConfigComponentApi?.execute({
          id: setupProps.id,
          instance_config_id: currentConfig.id,
          config_id: currentConfig.config_id,
          variants: currentConfig.variants,
          type: 1,
          start: currentConfig.start,
          end: createdConfig.CREATED,
        });

      if (!updateAssignmentResult) {
        console.error("Failed to update assignment for the config.");
        return null;
      }

      console.log(
        "Configuration creation, assignment, and update completed successfully."
      );
      return updateAssignmentResult;
    } catch (error) {
      console.error("Error during config creation and assignment:", error);
      return null;
    }
  };

  const areArraysEqualUnordered = (
    array1: unknown,
    array2: unknown
  ): boolean => {
    if (!Array.isArray(array1) || !Array.isArray(array2)) return false;
    if (
      !array1.every((item) => typeof item === "string") ||
      !array2.every((item) => typeof item === "string")
    )
      return false;

    if (array1.length !== array2.length) return false;

    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();
    return sortedArray1.every(
      (element, index) => element === sortedArray2[index]
    );
  };

  const handleConfigUpdate = async () => {
    try {
      const currentConfig = { ...configs[defaultConfigIndex], type: 0 };
      const initialConfig = initialConfigs[defaultConfigIndex];

      const configDataChanged = hasConfigDataChanged(
        currentConfig,
        initialConfig
      );
      const assignmentChanged = hasAssignmentChanged(
        currentConfig,
        initialConfig
      );
      console.log("Selected currentConfig:", currentConfig);
      console.log("initialConfig:", initialConfig);

      const arraysAreEqual = areArraysEqualUnordered(
        currentVariants || [],
        currentConfig?.variants || []
      );

      if (configDataChanged || !arraysAreEqual) {
        return await handleCreateAndAssignConfig(currentConfig);
      } else if (assignmentChanged) {
        return await handleUpdateAssignment(currentConfig);
      } else {
        console.log(
          `No changes detected for config ${currentConfig.config_id}.`
        );
      }
    } catch (error) {
      console.error("Error updating config settings:", error);
    }
  };

  const hasValidationErrors = Object.values(validationErrors).some(
    (tabErrors) => Object.keys(tabErrors).length > 0
  );

  const handleComponentUpdate = async () => {
    try {
      return await updateComponentsApi?.execute({
        id: setupProps.id,
        install: setupProps.install,
        product: setupProps.product,
        demo: setupProps.demo as unknown as number,
      });
    } catch (error) {
      console.error("Error updating component settings:", error);
    }
  };

  const renderTabContent = () => (
    <>
      <Box display={tabValue === 0 ? "block" : "none"}>
        {renderedTabs.has(0) && <SetupTab />}
      </Box>
      <Box display={tabValue === 1 ? "block" : "none"}>
        {renderedTabs.has(1) && <ItemsTab />}
      </Box>
      <Box display={tabValue === 2 ? "block" : "none"}>
        {renderedTabs.has(2) && <LookTab reset={reset} setReset={setReset} />}
      </Box>
      <Box display={tabValue === 3 ? "block" : "none"}>
        {renderedTabs.has(3) && <ListingTab />}
      </Box>
      <Box display={tabValue === 4 ? "block" : "none"}>
        {renderedTabs.has(4) && <PosTab />}
      </Box>
    </>
  );

  const handleSave = async () => {
    console.log("Saving changes...");
    setIsLoading(true);
    let updatedRow = componentData;

    if (hasSetupPropsChanged()) {
      const componentUpdateResult = await handleComponentUpdate();
      if (componentUpdateResult) {
        updatedRow = {
          ...updatedRow,
          ...componentUpdateResult,
        };
      } else {
        console.log("Component update failed or returned no changes.");
      }
    } else {
      console.log("Component props did not change.");
    }

    const configUpdateResult = await handleConfigUpdate();
    if (configUpdateResult) {
      if (Array.isArray(configUpdateResult)) {
        const parentRow = configUpdateResult.find((row) => row.type === "instance");
        updatedRow = {
          ...updatedRow,
          ...parentRow,
        };
      } else {
        updatedRow = {
          ...updatedRow,
          ...configUpdateResult,
        };
      }
    } else {
      console.log("Config update failed or returned no changes.");
    }
    const hasChildren = Array.isArray(configUpdateResult) && configUpdateResult.length > 1;

    if (updatedRow) {
      updateRow(updatedRow as ComponentsResponse);
      if (hasChildren) {
        const children = configUpdateResult.filter((row) => row.type === "variant");
        children?.length && children?.forEach((child) => {
          updateRow(child as ComponentsResponse);
        })
      }
      setSaveStatus("success");
    } else {
      setSaveStatus("error");
    }

    setIsLoading(false);

    setTimeout(() => {
      setSaveStatus(null);
    }, 3000);
  };
  const handleDemoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSetupProps((prev) => ({
      ...prev,
      demo: (event.target.checked ? 1 : 0) as unknown as boolean,
    }));
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onDrawerClose}
      sx={{ "& .MuiDrawer-paper": { maxWidth: "390px", minWidth: "390px", display: "flex", flexDirection: "column", height: "100vh" }}}
    >
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
        TabIndicatorProps={{ sx: { bgcolor: "primary.main", height: "3px" } }}
        sx={{ marginBottom: "10px" }}
      >
        {tabsOptions.map((tab, index) => {
          const tabHasErrors =
            validationErrors[tab] && Object.keys(validationErrors[tab]).length > 0;

          return (
            <Tab
              key={tab}
              label={t(`pages.buyNowComponent.editModal.tabs.${tab}`)}
              sx={{
                fontSize: "13px",
                minWidth: "66px",
                color: tabHasErrors ? "red" : "inherit",
              }}
            />
          );
        })}
      </Tabs>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={'12px 12px'}
      >
        <Typography variant="h3">
          {t(`pages.buyNowComponent.editModal.${tabsOptions[tabValue]}.title`)}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", alignSelf: 'flex-start', marginTop: "3px", marginRight: 0 }}>
          {tabsWithReset.includes(tabValue) && (
            <Button variant="text" color="primary" onClick={() => setReset(true)}>
              {t("pages.buyNowComponent.editModal.look.reset")}
            </Button>
          )}
          { tabValue === 0 && <FormControlLabel
            sx={{ marginRight: "5px" }}
            control={
              <Switch
                checked={setupProps.demo as boolean}
                onChange={handleDemoChange}
              />
            }
            label={t("pages.buyNowComponent.editModal.setup.demo")}
          />}
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          padding: "12px",
        }}
        ref={containerRef}
      >  
        {renderTabContent()}
      </Box>
      <Box
        sx={{
          padding: "16px 12px",
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px",
          backgroundColor: "background.paper",
        }}
      >
        <Button
          variant="outlined"
          onClick={onDrawerClose}
          sx={{ minWidth: "80px", height: "36px", padding: "6px 8px" }}
        >
          {t("general.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={hasValidationErrors || isLoading || !isAdmin}
          sx={{
            minWidth: "80px",
            height: "36px",
            padding: "6px 8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("general.save")
          )}
        </Button>
        {saveStatus && (
          <Alert
            variant="filled"
            severity={saveStatus}
            sx={{
              minHeight: "36px",
              width: "100%",
              padding: "0px 2px",
              "& .MuiAlert-icon": {
                marginRight: "4px",
              },
            }}
          >
            {saveStatus === "success"
              ? t("pages.buyNowComponent.editModal.saveSuccess")
              : t("pages.buyNowComponent.editModal.saveError")}
          </Alert>
        )}
      </Box>
    
    </Drawer>
  );
};

export default React.memo(EditDrawer);
