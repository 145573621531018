import { DataContext } from "@/contexts/DataContext";
import { Alert, AlertColor, Snackbar } from "@mui/material"
import { useContext } from "react";

type Props = {
  autoHideDuration?: number,
}

const SnackbarAlert = ({
  autoHideDuration
} : Props) => {
  const {
    copyState,
    setCopyState,
    openSnackbar, 
    setOpenSnackbar,
    snackbarMessage,
  } = useContext(DataContext)

  const handleCloseSnackbar = () => {
    setCopyState("success")
    setOpenSnackbar(false)
  }

  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={autoHideDuration ?? 2000}
      onClose={handleCloseSnackbar}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={copyState}
        sx={{ width: "100%" }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  )
};

export default SnackbarAlert;
