import { UserResponse } from "@/types/user";
import { AccountsFormData } from "./types";

export const fields = [
  { label: "pages.accountsManager.companyName", name: "companyName", placeholder: "pages.accountsManager.placeholderCompanyName", xs: 12, md: 5, required: true },
  { label: "pages.accountsManager.companyShortName", name: "companyShortName", placeholder: "pages.accountsManager.placeholderCompanyShortName", xs: 12, md: 3, required: true },
  { label: "pages.accountsManager.accountContact", name: "accountContact", placeholder: "pages.accountsManager.placeholderAccountContact", xs: 12, md: 4 },
  { label: "pages.accountsManager.country", name: "country", placeholder: "pages.accountsManager.placeholderCountry", xs: 12, md: 5, required: true },
  { label: "pages.accountsManager.vatNumber", name: "vatNumber", placeholder: "pages.accountsManager.placeholderVatNumber", xs: 12, md: 3 },
  { label: "pages.accountsManager.companyRegistrationNumber", name: "companyRegistrationNumber", placeholder: "pages.accountsManager.placeholderCompanyRegistrationNumber", xs: 12, md: 4 },
  { label: "pages.accountsManager.billingEmail", name: "billingEmail", placeholder: "pages.accountsManager.placeholderBillingEmail", xs: 12, md: 5 },
  { label: "pages.accountsManager.emailDomain", name: "emailDomain", placeholder: "pages.accountsManager.placeholderEmailDomain", xs: 12, md: 3 },
  { label: "pages.accountsManager.websiteAddress", name: "websiteAddress", placeholder: "pages.accountsManager.placeholderWebsiteAddress", xs: 12, md: 4 },
  { label: "pages.accountsManager.streetAddress", name: "streetAddress", placeholder: "pages.accountsManager.placeholderStreetAddress", xs: 12, md: 5 },
  { label: "pages.accountsManager.city", name: "city", placeholder: "pages.accountsManager.placeholderCity", xs: 12, md: 3 },
  { label: "pages.accountsManager.zipCode", name: "zipCode", placeholder: "pages.accountsManager.placeholderZipCode", xs: 12, md: 4 },
  { label: "pages.accountsManager.csm", name: "csm", placeholder: "pages.accountsManager.placeholderCsm", xs: 12, md: 5, required: true },
  { label: "pages.accountsManager.csmURL", name: "csmURL", placeholder: "pages.accountsManager.placeholderCsmURL", xs: 12, md: 7 },
];

export const initialFormData: AccountsFormData = {
  companyName: "",
  companyShortName: "",
  accountContact: "",
  country: "",
  vatNumber: "",
  companyRegistrationNumber: "",
  billingEmail: "",
  emailDomain: "",
  websiteAddress: "",
  streetAddress: "",
  csmURL: "",
  csm: "" as unknown as UserResponse,
  city: "",
  zipCode: "",
  comment: "",
};

export const validationRules: {
  [key: string]: { pattern: RegExp; message: string };
} = {
  companyName: {
    pattern: /^(?!\s*$).+/,
    message: "pages.accountsManager.validationCompanyName",
  },
  companyShortName: {
    pattern: /^(?!\s*$).+/,
    message: "pages.accountsManager.validationCompanyShortName",
  },
  vatNumber: {
    pattern: /^[a-zA-Z0-9]+$/,
    message: "pages.accountsManager.validationVatNumber",
  },
  billingEmail: {
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: "pages.accountsManager.validationBillingEmail",
  },
  websiteAddress: {
    pattern:
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    message: "pages.accountsManager.validationWebsiteAddress",
  },
  emailDomain: {
    pattern: /^([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
    message: "pages.accountsManager.validationEmailDomain",
  },
  csmURL: {
    pattern: /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    message: "pages.accountsManager.validationCsmURL",
  },  
};

export const privileges = [
  { value: 3, label: "pages.accountsManager.privilegeSee" },
  { value: 1, label: "pages.accountsManager.privilegeRead" },
  { value: 2, label: "pages.accountsManager.privilegeWrite" },
];
