import React, {useState} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Alert,
  Snackbar,
  Button,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useTranslation} from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import {useTheme} from "@mui/system";

interface PasswordDialogProps {
  isPasswordDialogOpen: boolean;
  handlePasswordDialogClose: () => void;
  formData: {
    password: string;
  };
}

const PasswordDialog: React.FC<PasswordDialogProps> = ({
                                                         isPasswordDialogOpen,
                                                         handlePasswordDialogClose,
                                                         formData,
                                                       }) => {
  const themeTemplate = useTheme();
  const {t} = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [copyMessage, setCopyMessage] = useState('');

  const handleCopy = () => {
    navigator.clipboard.writeText(formData.password).then(() => {
      setCopyMessage(t("general.copiedToClipBoard") as string);
      setSnackbarOpen(true);
    }).catch(err => {
      setCopyMessage(t("general.failedToCopy") as string);
      setSnackbarOpen(true);
      console.error("Could not copy text: ", err);
    });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={isPasswordDialogOpen} onClose={handlePasswordDialogClose}>
        <DialogTitle>
          <span>{t('pages.passwordDialog.generatedPassword')}</span>
          <IconButton
            onClick={handlePasswordDialogClose}
            sx={{
              position: "absolute",
              top: themeTemplate.spacing(1.5),
              right: themeTemplate.spacing(1.5),
              zIndex: 1,
              color: themeTemplate.palette.grey[500],
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Alert severity="info">{t('pages.passwordDialog.info')}</Alert>
          <TextField
            value={formData.password}
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={showPassword ? t('pages.passwordDialog.hidePassword') : t('pages.passwordDialog.showPassword')}>
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('pages.passwordDialog.copyPassword')}>
                    <IconButton onClick={handleCopy}>
                      <ContentCopyIcon/>
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={copyMessage}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      />
    </>
  );
};

export default PasswordDialog;
