import { useEffect, useMemo, useState } from "react";
import { MuiGridTable as DataGridTable } from "@/components/tables/DataGridTable";
import { Alert, Paper, Snackbar } from "@mui/material";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { PropertiesResponse } from "@/api/buynow/types";
import { useTranslation } from "react-i18next";
import CreateProperty from "./CreateProperty";

const BuyNowProperties = (): JSX.Element => {
  const { selectedManufacturers, allPropertiesApi, user } = useData();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [highlightedRowId, setHighlightedRowId] = useState<number | null>(null);
  const [rows, setRows] = useState<any[]>([]); // CHANGE HERE
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const allColumns = useColumns();
  const { t } = useTranslation();

  useEffect(() => {
    if (allPropertiesApi.data) {
      setRows(allPropertiesApi.data);
    }
  }, [allPropertiesApi.data]);

  useEffect(() => {
    const selectedManufacturerIDs = selectedManufacturers.map((m) => m.ID);
    if (allPropertiesApi && selectedManufacturerIDs.length > 0) {
      setSelectedRows([]);
      allPropertiesApi.execute({ m: selectedManufacturerIDs });
    }
  }, [selectedManufacturers]);

  const columns: GridColDef<PropertiesResponse>[] = [
    allColumns.bnTypeColumn,
    {
      ...allColumns.ownerColumn,
      flex: 1,
    },
    {
      ...allColumns.propertyColumn,
      flex: 1,
    },
    allColumns.domainColumn,
    allColumns.createdOnlyDateColumn,
    allColumns.updatedOnlyDateColumn,

    allColumns.actionsColumn(),
  ];

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const adminFeatures = {
    hasControls: true,
    onCreateClick: () => setDialogOpen(true),
    getRowClassName: (params: { row: { id: string | null; }; }) => params.row.id === highlightedRowId
        ? 'row-highlight'
        : '',
  };

  return (
    <ContentContainer>
      <Paper>
        <DataGridTable
          id="buynowProperties"
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          selectedRows={selectedRows}
          loading={allPropertiesApi.showSkeletton}
          filterBarType={2}
          hasCheckboxes={true}
          sortModel={[
            {
              field: 'updated',
              sort: 'desc',
            },
          ]}
          {...user?.role === 'admin' && adminFeatures}
          searchPlaceholder={t("navigation.buyNowProperties") || "Properties"}
          tableInfo={t("tableInfo.buyNowProperties") || ""}
          isError={allPropertiesApi.isError}
          error={allPropertiesApi.error}
        />
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <CreateProperty
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        onSuccess={(newProperty) => {
          setRows((prevRows) => [...prevRows, newProperty]);
          setHighlightedRowId(newProperty.id);
          setSnackbarMessage("Property was created successfully");
          setOpenSnackbar(true);
      
          setTimeout(() => {
            setHighlightedRowId(null);
          }, 5000);
        }}
      />
    </ContentContainer>
  );
};

export default BuyNowProperties;
