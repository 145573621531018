import { createMutationFunction, ContentType } from "../mutations/createMutationFunction";
import { useApiMutation } from "../useApiMutation";
import { useApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import {ContractsApiType,  ContractsResponse, CreateContractApiType, CreateContractRequest} from "./types";


export const useContracts = (): ContractsApiType => {
    return useApiQuery<ContractsResponse[], {}>({
      key: 'contracts',
      queryFn: createQueryFunction(`app/management/contracts`),
    });
};

export const useCreateContract = (): CreateContractApiType => {
    return useApiMutation<ContractsResponse, CreateContractRequest>({
      mutationFn: createMutationFunction(`app/management/contracts/`, ContentType.JSON, "POST"),
    });
};

export const useEditContract = (): CreateContractApiType => {
    return useApiMutation<ContractsResponse, CreateContractRequest>({
      mutationFn: (variables) =>
        createMutationFunction<ContractsResponse, CreateContractRequest>(
          `app/management/contracts/${variables?.contract_id}`,
          ContentType.JSON,
          "PUT")(variables),
    });
};

