import { useApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import { AccountResponse, AccountsApiType, AccountsUserApiType, AccountsUserResponse, AccountsUserParams, AccountsTreeResponse, AccountsTreeApiType, CreateAccountRequest, CreateAccountResponse } from "./types";
import { ContentType, createMutationFunction } from "../mutations/createMutationFunction";
import { useApiMutation, ErrorCallback } from "../useApiMutation";
import { t } from "i18next";

const handleCustomError = ({
  error,
  setError,
  setData,
}: ErrorCallback<CreateAccountResponse>) => {
  const errorMessage =
  error.response && error.response.status === 400
  ? t("user.updateError")
  : `${t("errors.globalError")}: ${error.message}`;

  setError(errorMessage);
  setData(undefined);
};

export const useAccounts = (): AccountsApiType => {
    return useApiQuery<AccountResponse[], {}>({
      key: 'accounts',
      queryFn: createQueryFunction(`app/accounts`),
    });
};

export const useAccountsTree = (): AccountsTreeApiType => {
  return useApiQuery<AccountsTreeResponse[], {}>({
    key: 'accounts-tree',
    queryFn: createQueryFunction(`app/accounts/tree-data`),
  });
};

export const useAccountsUser = (): AccountsUserApiType => {
    return useApiQuery<AccountsUserResponse[], AccountsUserParams>({
      key: 'accountsUser',
      queryFn: createQueryFunction(`app/accounts/users`),
    });
};

export const useCreateAccount = () => {
  return useApiMutation<CreateAccountResponse, CreateAccountRequest>({
    mutationFn: (variables) =>
      createMutationFunction<CreateAccountResponse, CreateAccountRequest>(
        `app/accounts`
      )(variables),
    onErrorCallback: handleCustomError,
  });
};

export const useEditAccount = () => {
  return useApiMutation<CreateAccountResponse, CreateAccountRequest>({
      mutationFn: (variables) =>
          createMutationFunction<CreateAccountResponse, CreateAccountRequest>(
              `app/accounts${variables?.account_id ? `?account_id=${variables.account_id}` : ""}`,
              ContentType.JSON,
              'PUT'
          )(variables),
      onErrorCallback: handleCustomError,
  });
};
