import React, { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import { ContentTableResponseLine } from "@/api/content/types";
import { Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { dependentAPIs, hiddenTableColumns, selectorsConfig } from "./consts";
import FiltersPanel from "@/components/FiltersPanel";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { useTranslation } from "react-i18next";
import CopyCell from "@/components/CopyCell";

const Paper = styled(MuiPaper)(spacing);

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  const { contentTableApi, isLoading } = useData();

  const rows: ContentTableResponseLine[] = useMemo(() => {
    return contentTableApi?.data || [];
  }, [contentTableApi?.data]);
  const allColumns = useColumns();

  const columns: GridColDef<ContentTableResponseLine>[] = [
    allColumns.posColumn,
    allColumns.sellerColumn,
    allColumns.brandNameColumn,
    allColumns.productLinkColumn,
    allColumns.sizeColumn,
    allColumns.skuColumn,
    allColumns.normColumn,
    allColumns.statusColumn,
    allColumns.availabilityColumn,
    allColumns.prodPageColumn,
    allColumns.prodLinkColumn,
    allColumns.priceColumn,
    allColumns.discColumn,
    allColumns.ratingStarsColumn,
    allColumns.ratingNumberColumn,
    {
      ...allColumns.ratingsColumn,
      headerName: t("tableColumns.ratings") || "ratings",
    },
    allColumns.packshotsColumn,
    allColumns.videoColumn,
    allColumns.packshotsDescColumn,
    allColumns.videoDescColumn,
    {...allColumns.descriptionColumn,
      renderCell: (params) => {
        const description = params.row.DESCRIPTION
        return (
          <CopyCell text={description}/>
        )
      }},
    allColumns.descriptionLengthColumn,
    allColumns.teaserColumn,
    allColumns.indicationColumn,
    allColumns.leafletColumn,
    allColumns.canonicalColumn,
    allColumns.updatedDateColumn,
    allColumns.updatedPPDateColumn,
  ];

  return (
    <ContentContainer>
      <FiltersPanel
        selectorsConfig={selectorsConfig}
        dependentAPIs={dependentAPIs}
        hasDaysFilter={false}
        hasMoreButton={false}
      />
      <Paper>
        <MuiGridTable
          id="contentBoard"
          rows={rows}
          columns={columns}
          loading={contentTableApi.showSkeletton}
          filterBarType={2}
          hiddenFields={hiddenTableColumns}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
          isError={contentTableApi?.isError}
          error={contentTableApi?.error}
        />
      </Paper>
    </ContentContainer>
  );
};

export default Content;
