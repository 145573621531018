import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rgba } from "polished";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { spacing } from "@mui/system";
import InfoSvg from "@/assets/img/info.svg";
import BaseError from "@/components/errors/baseError";
import { ErrorSize } from "@/components/errors/baseError/types";

const illustrationCardStyle = (props: any) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;
  border-radius: 0;
  height: 118px;
  ${illustrationCardStyle};
`;

const CustomTooltip = styled(Tooltip)`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;

  @media (max-width: 1600px) {
    top: 4px;
    right: 16px;
  }
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
`;

const IllustrationImage = styled.img`
  height: 100px;
  position: absolute;

  display: none;

  @media (min-width: 1600px) {
    display: block;
  }
`;

type StatsProps = {
  title: string;
  amount: string | number;
  tooltip: string;
  illustration?: string;
  isLoading: boolean;
  isError: boolean;
  error: string | null;
};

const Stats: React.FC<StatsProps> = ({
  title,
  amount,
  tooltip,
  illustration,
  isLoading,
  isError,
  error,
}) => {

  return (
    <Card illustration={illustration}>
      <CardContent>
        {tooltip && (
          <CustomTooltip title={tooltip} placement="top">
            <img src={InfoSvg} alt="info" />
          </CustomTooltip>
        )}

        <MuiTypography
          variant="h6"
          component="h2"
          mb={isError ? 1 : 4}
          sx={{
            fontWeight: 500,
            fontSize: "17px",
            lineHeight: "23px",
          }}>
          {title}
        </MuiTypography>

        <Box
          sx={{ display: "flex", textAlign: "center", flexDirection: "column" }}
        >
          <Typography variant="h3" mb={3}>
            {isError ? (
              <BaseError text={error || ""} size={ErrorSize.small} />
            ) : (
              <Box
                sx={{ fontWeight: 700, fontSize: "20px", lineHeight: "27px" }}
              >
                {isLoading ? (
                  <Skeleton width={"100%"} height={"100%"} />
                ) : (
                  amount
                )}
              </Box>
            )}
          </Typography>
        </Box>
      </CardContent>

      {!!illustration && !isLoading && (
        <IllustrationImage src={illustration} alt="Illustration" />
      )}
    </Card>
  );
};

export default Stats;
