import { ManagementApiType } from "./types";
import {useContracts, useCreateContract, useEditContract} from "@/api/management/useContracts";
import {useAccounts, useAccountsTree, useAccountsUser, useCreateAccount, useEditAccount} from "@/api/management/useAccounts";
import {useEditModuleAccess, useModules, useModulesAccessTree} from "@/api/management/useModules";
import { useAdmin } from "./useAdmins";
import {useCreateUser} from "@/api/management/useCreateUser";
import { useEditUser, useUsers } from "./useUsers";

const useManagement = (): ManagementApiType => {
    const contractsApi = useContracts();
    const accountsApi = useAccounts();
    const accountsUserApi = useAccountsUser();
    const modulesApi = useModules();
    const modulesAccessTreeApi = useModulesAccessTree();
    const editModulesAccessApi = useEditModuleAccess();
    const createUserApi = useCreateUser();
    const createContractApi = useCreateContract();
    const createAccountApi = useCreateAccount();
    const editContractApi = useEditContract();
    const adminsApi = useAdmin();
    const accountsTreeApi = useAccountsTree();
    const { allUsersApi } = useUsers();
    const editAccountApi = useEditAccount();
    const editUserApi = useEditUser();

    return {
        contractsApi,
        accountsApi,
        accountsUserApi,
        modulesApi,
        modulesAccessTreeApi,
        editModulesAccessApi,
        createUserApi,
        createContractApi,
        editContractApi,
        adminsApi,
        allUsersApi,
        accountsTreeApi,
        createAccountApi,
        editAccountApi,
        editUserApi
    }
};

export default useManagement;