import { Manufacturer, useManufacturers } from '@/api/useManufacturers';
import { useMerchants } from '@/api/useMerchants';
import useAuth from '@/hooks/auth/useAuth';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from 'react';
import { isValidManufacturer } from './DataContext.utils';
import {
  useBuyNowTransactions,
  BuyNowTransactionsApiType,
} from '@/api/useBuyNowTransactions';
import {
  AnalyticsGraphApiType,
  BrandPagePerformanceApiType,
  BrandsApiType,
  KpiBrandPerformanceApiType,
  KpiMerchantsLeadsApiType,
  KpiTransactionsApiType,
  MerchantsLeadsApiType,
  MerchantsTransactionsApiType,
  ProductLeadsApiType,
  ProductsApiType,
  ProductsVisitsApiType,
  UtmParamsApiType,
  useAnalyticsQuery,
} from '@/api/useAnalytics';
import {
  BrandsFilterApiType,
  LabelsFilterApiType,
  SellerFilterApiType,
  BNFilterApiType,
} from '@/api/filters/types';
import useFilters from '@/api/filters';
import {
  AiReviewSummaryApiType,
  ContentTableApiType,
  PackshotsAndVideosApiType,
  RatingsAndReviewsTableApiType,
} from '@/api/content/types';
import useContent from '@/api/content';
import { DataState, DataProviderProps } from '@/types/dataContext';
import { useUpdateUser } from '@/api/useUserUpdate';
import { useUserTraffic, UserTrafficApiType } from '@/api/useUserTraffic';
import { useAiOptimizer } from '@/api/useAiOptimizer';
import { useMerchantProductContent } from '@/api/useMerchantProductContent';
import { UserResponse, Settings } from '@/types/user';
import i18next from 'i18next';
import { isSameArray } from '@/utils/isSameArray';
import dayjs from 'dayjs';
import { TimeRange } from '@/components/TimeRangePicker/types';
import useBuynow from '@/api/buynow';
import useVisibility from '@/api/visibility';
import { ShareOfSearchApiType } from '@/api/visibility/types';
import {
  SetupBrandsApi,
  SetupCategoriesApi,
  SetupKeywordsApi,
  SetupLabelsApi,
  SetupLabelsCreateApi,
  SetupLabelsDeleteApi,
  SetupLabelsUpdateApi,
  SetupMarketsApi,
  SetupMarketsCreateApi,
  SetupMarketsUpdateApi,
  SetupProductsApi,
} from '@/api/setup/types';
import {
  AllPropertiesApiType,
  AssignConfigComponentApi,
  ComponentsApiType,
  CreateComponentApi,
  CreatePropertyApi,
  CreateConfigApi,
  DefJsonApiType,
  UpdateAssignmentConfigComponentApi,
  UpdateComponentsApiType,
} from '@/api/buynow/types';

import {
  AvailabilityTableApiType,
  ProductListingsTableApiType,
} from '@/api/availability/types';
import { useAvailability } from '@/api/availability';
import useSetup from '@/api/setup';
import useAppTheme from '@/hooks/useTheme';
import {
  uiModes,
  currencies,
  numbersFormat,
  languageOptions,
} from '@/consts/settings';
import { PricingTableApiType } from '@/api/pricing/types';
import usePricing from '@/api/pricing';
import useManagement from '@/api/management';
import {
  AccountsApiType,
  AccountsUserApiType,
  ContractsApiType,
  CreateContractApiType,
  EditContractApiType,
  ModulesApiType,
  AdminsApiType,
  CreateUserApiType,
  ManagementApiType,
  AccountsTreeApiType,
  CreateAccountApiType,
  ModulesAccessTreeApiType,
  EditModuleAccessApiType
} from '@/api/management/types';
import { UsersTrafficApiType } from '@/api/accounts/types';
import useAccounts from '@/api/accounts';

import { UsersFilterApiType } from '@/api/management/types';

const initialState: DataState = {
  isLoading: false,
  user: undefined,
  merchantsApi: undefined,
  manufacturersApi: undefined,
  setManufacturer: (data: Manufacturer[]) => {},
  setSettings: (data: Settings) => {},
  brandsApi: {} as BrandsApiType,
  productsApi: {} as ProductsApiType,
  utmParamsApi: {} as UtmParamsApiType,
  analyticsGraphApi: {} as AnalyticsGraphApiType,
  productLeadsApi: {} as ProductLeadsApiType,
  productsVisitsApi: {} as ProductsVisitsApiType,
  merchantsLeadsApi: {} as MerchantsLeadsApiType,
  merchantsTransactionsApi: {} as MerchantsTransactionsApiType,
  brandPagePerformanceApi: {} as BrandPagePerformanceApiType,
  kpiBrandPerformanceApi: {} as KpiBrandPerformanceApiType,
  kpiTransactionsApi: {} as KpiTransactionsApiType,
  kpiMerchantsLeadsApi: {} as KpiMerchantsLeadsApiType,
  updateUserApi: undefined,
  userTrafficApi: {} as UserTrafficApiType,
  aiOptimizerApi: undefined,
  merchantProductContentApi: undefined,
  allBrandsApi: {} as BrandsFilterApiType,
  parentBrandsApi: {} as BrandsFilterApiType,
  subBrandsApi: {} as BrandsFilterApiType,
  sellerApi: {} as SellerFilterApiType,
  labelsApi: {} as LabelsFilterApiType,
  contentTableApi: {} as ContentTableApiType,
  pricingTableApi: {} as PricingTableApiType,
  buyNowTransactionsApi: {} as BuyNowTransactionsApiType,
  shareOfSearchApi: {} as ShareOfSearchApiType,
  ratingsAndReviewsApi: {} as RatingsAndReviewsTableApiType,
  reviewSummaryApi: {} as AiReviewSummaryApiType,
  componentsApi: {} as ComponentsApiType,
  defSocialJsonApi: {} as DefJsonApiType,
  defContentJsonApi: {} as DefJsonApiType,
  createComponentApi: {} as CreateComponentApi,
  createPropertyApi: {} as CreatePropertyApi,
  updateComponentsApi: {} as UpdateComponentsApiType,
  assignConfigComponentApi: {} as AssignConfigComponentApi,
  updateAssignmentConfigComponentApi: {} as UpdateAssignmentConfigComponentApi,
  createConfigApi: {} as CreateConfigApi,
  allManufacturerIsSelected: false,
  dateRange: {} as TimeRange,
  setDateRange: () => {},
  setAllManufacturerIsSelected: (isSelected: boolean) => {},
  selectedManufacturers: [],
  allPropertiesApi: {} as AllPropertiesApiType,
  setupKeywordsApi: {} as SetupKeywordsApi,
  setupCategoriesApi: {} as SetupCategoriesApi,
  setupLabelsApi: {} as SetupLabelsApi,
  setupProductsApi: {} as SetupProductsApi,
  setupMarketsApi: {} as SetupMarketsApi,
  daysChanged: { current: false },
  availabilityTableApi: {} as AvailabilityTableApiType,
  setupLabelsUpdateApi: {} as SetupLabelsUpdateApi,
  setupLabelsDeleteApi: {} as SetupLabelsDeleteApi,
  productListingsTableApi: {} as ProductListingsTableApiType,
  packshotsAndVideosTableApi: {} as PackshotsAndVideosApiType,
  setFiltersLoading: () => {},
  bnSourceApi: {} as BNFilterApiType,
  bnMediumApi: {} as BNFilterApiType,
  bnCampaignApi: {} as BNFilterApiType,
  bnContentApi: {} as BNFilterApiType,
  bnTermApi: {} as BNFilterApiType,
  bnDeviceApi: {} as BNFilterApiType,
  bnUrlApi: {} as BNFilterApiType,
  bnInstanceApi: {} as BNFilterApiType,
  bnBannerApi: {} as BNFilterApiType,
  allUsersTrafficApi: {} as UsersTrafficApiType,
  setupLabelsCreateApi: {} as SetupLabelsCreateApi,
  contractsApi: {} as ContractsApiType,
  accountsApi: {} as AccountsApiType,
  accountsTreeApi: {} as AccountsTreeApiType,
  accountsUserApi: {} as AccountsUserApiType,
  createAccountApi: {} as CreateAccountApiType,
  editAccountApi: {} as CreateAccountApiType,
  editUserApi: {} as CreateUserApiType,
  modulesApi: {} as ModulesApiType,
  modulesAccessTreeApi: {} as ModulesAccessTreeApiType,
  editModulesAccessApi: {} as EditModuleAccessApiType,
  createUserApi: {} as CreateUserApiType,
  createContractApi: {} as CreateContractApiType,
  editContractApi: {} as EditContractApiType,
  adminsApi: {} as AdminsApiType,
  setupMarketsCreateApi: {} as SetupMarketsCreateApi,
  setupMarketUpdateApi: {} as SetupMarketsUpdateApi,
  filtersLoading: false,
  allUsersApi: {} as UsersFilterApiType,
  confirmationDialogOpen: false,
  setConfirmationDialogOpen: {} as React.Dispatch<
    React.SetStateAction<boolean>
  >,
  copyState: 'success',
  setCopyState: () => {},
  openSnackbar: false,
  setOpenSnackbar: () => {},
  snackbarMessage: '',
  setSnackbarMessage: () => {},
  setupBrandsApi: {} as SetupBrandsApi,
  openFilters: false,
  setOpenFilters: () => {}
};

const DataContext = React.createContext(initialState);

/**
 * DataProvider component
 *
 * This component serves as a context provider for data related to the authenticated user, manufacturers, and merchants.
 * It fetches and manages data from various APIs and provides functions to handle manufacturer data in local storage.
 *
 * @param {DataProviderProps} props - The properties for the DataProvider component, including its children nodes.
 *
 * @returns {JSX.Element} A context provider component that wraps its children and provides various data and functions.
 */
const DataProvider = ({ children }: DataProviderProps) => {
  const { isAuthenticated, signInApi, meApi } = useAuth();
  const [user, setUser] = useState<UserResponse | undefined>(undefined);
  const { setTheme } = useAppTheme();
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [copyState, setCopyState] = useState<"success" | "error">("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const [selectedManufacturers, setSelectedManufacturers] = useState<
    Manufacturer[]
  >([]);
  const manufacturersApi = useManufacturers();
  const merchantsApi = useMerchants();
  const updateUserApi = useUpdateUser();
  const userTrafficApi = useUserTraffic();
  const aiOptimizerApi = useAiOptimizer();
  const merchantProductContentApi = useMerchantProductContent();
  const visibilityApi = useVisibility();
  const analyticsApis = useAnalyticsQuery();
  const { allUsersTrafficApi } = useAccounts();
  const bnFilterApi = useFilters();
  const managementApi = useManagement();
  const merchantContentApi = useContent();
  const pricingApi = usePricing();
  const setupApi = useSetup();
  const buyNowTransactionsApi = useBuyNowTransactions();
  const buynowApi = useBuynow();
  const availabilityApi = useAvailability();

  const [allManufacturerIsSelected, setAllManufacturerIsSelected] =
    useState(false);
  const [dateRange, setDateRange] = useState<TimeRange>({
    from_date: dayjs().subtract(30, 'day').unix(),
    to_date: dayjs().unix(),
  });
  const daysChanged = useRef(false);

  const { manufacturers } = manufacturersApi;
  const { merchants } = merchantsApi;

  const mergeManufacturerInUser = useCallback((data: Manufacturer[]) => {
    setUser((prev) => (prev ? { ...prev, manufacturer: data } : prev));
  }, []);

  const setSettings = useCallback((data: Settings) => {
    setUser((prev) => (prev ? { ...prev, settings: data } : prev));
  }, []);

  const setManufacturerInLocalStorage = useCallback(
    (data: Manufacturer[]) => {
      localStorage.setItem('manufacturer', JSON.stringify(data));
      mergeManufacturerInUser(data);
    },
    [mergeManufacturerInUser]
  );

  const loadManufacturerFromStorage = useCallback(() => {
    const storedManufacturer = localStorage.getItem('manufacturer');
  
    if (!manufacturers.data) return;

    const possibleManufacturers = manufacturers.data.filter(
      (m) => m.access !== 'see'
    );

    if (!storedManufacturer || JSON.parse(storedManufacturer).length === 0) {
      if (possibleManufacturers.length > 0) {
        const selectedManufacturer = [possibleManufacturers[0]];
        localStorage.setItem(
          'manufacturer',
          JSON.stringify(selectedManufacturer)
        );
        mergeManufacturerInUser(selectedManufacturer);
      }
      return;
    }
  
    let parsedManufacturer: Manufacturer[] | Manufacturer =
      JSON.parse(storedManufacturer);
    if (parsedManufacturer && !Array.isArray(parsedManufacturer)) {
      parsedManufacturer = [parsedManufacturer];
    }
  
    if (
      !isValidManufacturer({
        manufacturer: parsedManufacturer,
        possibleManufacturers: possibleManufacturers,
      })
    ) {
      parsedManufacturer = possibleManufacturers.length > 0
        ? [possibleManufacturers[0]]
        : [];
    }
  
    localStorage.setItem('manufacturer', JSON.stringify(parsedManufacturer));
    mergeManufacturerInUser(parsedManufacturer);
  }, [manufacturers.data]);
  
  useEffect(() => {
    const newArr =
      user && user?.manufacturer
        ? Array.isArray(user?.manufacturer)
          ? user?.manufacturer
          : [user?.manufacturer]
        : [];
    if (!isSameArray(selectedManufacturers, newArr)) {
      setSelectedManufacturers(newArr);
    }
  }, [user?.manufacturer, selectedManufacturers]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        // shouldn't be cached as issues with log in and  log out arise
        const focus = meApi?.data?.role === 'admin' ? { focus: 1 } : {};
        await manufacturers.execute(focus, false);
        await merchants.execute();
      }
    };

    fetchData();
  }, [isAuthenticated]);

  useLayoutEffect(() => {
    if (isAuthenticated && (signInApi?.data || meApi?.data)) {
      const data = (signInApi?.data || meApi?.data) as UserResponse;

      if (data && data.settings) {
        data.settings.currency =
          data.settings.currency && currencies.includes(data.settings.currency)
            ? data.settings.currency
            : 'euro';

        data.settings.language =
          data.settings.language && data.settings.language in languageOptions
            ? data.settings.language
            : 'en';

        data.settings.numberFormat =
          data.settings.numberFormat &&
          data.settings.numberFormat in numbersFormat
            ? data.settings.numberFormat
            : 'en-US';

        data.settings.uiMode =
          data.settings.uiMode && uiModes.includes(data.settings.uiMode)
            ? data.settings.uiMode
            : 'light';
      } else {
        data.settings = {
          uiMode: 'light',
          currency: 'euro',
          language: 'en',
          numberFormat: 'en-US',
        } as Settings;
      }

      setUser(data);
      // setting the current language and mode always based on the db
      const language = data.settings.language;
      const uiMode = data.settings.uiMode;

      localStorage.setItem('language', language);
      i18next.changeLanguage(language);
      setTheme(uiMode);

      if (manufacturers.data) {
        loadManufacturerFromStorage();
      }
    }
  }, [
    isAuthenticated,
    signInApi?.data,
    meApi?.data,
    manufacturers.data,
    loadManufacturerFromStorage,
  ]);

  const isAnalyticsLoading = Object.values(analyticsApis).some(
    (api) => api?.isLoading
  );

  const isFiltersLoading = Object.values(bnFilterApi).some(
    (api) => api?.isLoading
  );

  const merchantsContentLoading = Object.values(merchantContentApi).some(
    (api) => api?.isLoading
  );

  const pricingContentLoading = Object.values(pricingApi).some(
    (api) => api?.isLoading
  );

  const buynowLoading = Object.values(buynowApi).some((api) => api?.isLoading);
  const managementLoading = Object.values(managementApi).some(
    (api) => api?.isLoading
  );

  const visibilityLoading = Object.values(visibilityApi).some(
    (api) => api?.isLoading
  );

  const availabilityLoading = Object.values(availabilityApi).some(
    (api) => api?.isLoading
  );

  const isAllUsersTrafficLoading = Object.values(allUsersTrafficApi).some(
    (api) => api?.isLoading
  );

  const setupLoading = Object.values(setupApi).some((api) => api?.isLoading);

  return (
    <DataContext.Provider
      value={{
        isLoading:
          isAllUsersTrafficLoading ||
          manufacturersApi.isLoading ||
          merchantsApi.isLoading ||
          isAnalyticsLoading ||
          isFiltersLoading ||
          merchantsContentLoading ||
          pricingContentLoading ||
          buynowLoading ||
          visibilityLoading ||
          availabilityLoading ||
          setupLoading ||
          filtersLoading ||
          managementLoading,
        user,
        setSettings,
        manufacturersApi,
        merchantsApi,
        setManufacturer: setManufacturerInLocalStorage,
        ...analyticsApis,
        updateUserApi,
        userTrafficApi,
        aiOptimizerApi,
        merchantProductContentApi,
        ...bnFilterApi,
        ...managementApi,
        ...merchantContentApi,
        ...pricingApi,
        ...visibilityApi,
        ...setupApi,
        allUsersTrafficApi,
        buyNowTransactionsApi,
        setAllManufacturerIsSelected,
        allManufacturerIsSelected,
        selectedManufacturers,
        dateRange,
        setDateRange,
        ...buynowApi,
        daysChanged,
        ...availabilityApi,
        setFiltersLoading,
        filtersLoading,
        ...managementApi,
        confirmationDialogOpen,
        setConfirmationDialogOpen,
        copyState,
        setCopyState,
        openSnackbar,
        setOpenSnackbar,
        snackbarMessage,
        setSnackbarMessage,
        openFilters,
        setOpenFilters
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
