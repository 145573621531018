import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Upgrade from "@/pages/purchasing/Upgrade";
import {
  CssBaseline,
  useTheme,
  useMediaQuery,
  Paper,
  Container as MuiContainer,
} from "@mui/material";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "@/components/sidebar/Sidebar";
import useData from "@/hooks/auth/useData";
import navItems from "../consts/navItems";
import { debounce } from "@/hooks/useDebaunce";
import routes from "@/consts/routes";
import useAuth from "@/hooks/auth/useAuth";
import SnackbarAlert from "@/components/SnackbarAlert/SnackbarAlert";

const drawerWidth = 200;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div<{
  sidebarWidth: number;
  open: boolean;
  isMobile: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 50%;
  margin-left: ${(props) =>
    props.open ? `${props.sidebarWidth}px` : props.isMobile ? "0px" : "14px"};
  transition: margin-left 0.3s ease;
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Container = styled(MuiContainer)`
  height: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

interface DashboardType {
  children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardType> = ({ children }) => {
  const { user, updateUserApi } = useData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [sidebarOpen, setSidebarOpen] = useState(() => {
    return JSON.parse(localStorage.getItem("sidebarOpen") ?? "true");
  });
  const [sidebarWidth, setSidebarWidth] = useState(() => {
    return parseInt(localStorage.getItem("sidebarWidth") ?? "200", 10);
  });

  useEffect(() => {
    if (user?.settings?.drawerIsOpen !== undefined) {
      setSidebarOpen(user.settings.drawerIsOpen);
      setSidebarWidth(user.settings.drawerWidth ?? 200);
    }
  }, [user?.settings]);

  const debouncedUpdateUserApi = debounce((settings: any) => {
    updateUserApi?.execute({ settings });
  }, 300);
  const currentRoute = location.pathname;

  // Function to find the matching route with required privileges
  const findRoute = (routes: any[], path: string) => {
    for (const route of routes) {
      if (route.path === path) return route;
      if (route.children) {
        const foundChild = route.children.find(
          (child: any) => child.path === path
        );
        if (foundChild) return foundChild;
      }
    }
    return null;
  };

  const routeDef = findRoute(routes, currentRoute);

  const requiredModules: number[] = routeDef?.requiredModules || [];

  // Check if the user has all required access rights
  // user must have a privilege other than 'none' for all the manufacturers he has selected for the module he wants to access
  const { hasRequiredModules, hasNoPrivileges, lacksAllManufacturersPrivileges } = (() => {
    let hasRequiredModules = false;
    let hasNoPrivileges = false;
    let lacksAllManufacturersPrivileges = false;

    if (user?.role === 'admin' || !user || !user.manufacturer || !user.modules) {
      hasRequiredModules = true;
      return { hasRequiredModules, hasNoPrivileges, lacksAllManufacturersPrivileges };
    }

    // Multiple manufacturers case
    if (Array.isArray(user.manufacturer)) {
      const manufacturerPrivileges = user.manufacturer.map((manufacturer) => {
        return requiredModules.every((moduleId) =>
          user.modules.some(
            (module) =>
              module.id === moduleId &&
              module.manufacturer_id === manufacturer.ID &&
              module.privilege !== 'none'
          )
        );
      });

      hasNoPrivileges = manufacturerPrivileges.every((privilege) => !privilege);
      lacksAllManufacturersPrivileges = manufacturerPrivileges.some((privilege) => !privilege);
      hasRequiredModules = !hasNoPrivileges && !lacksAllManufacturersPrivileges;

      return { hasRequiredModules, hasNoPrivileges, lacksAllManufacturersPrivileges };
    }

    // Single manufacturer case
    hasRequiredModules = requiredModules.every((moduleId) =>
      user.modules.some(
        (module) =>
          module.id === moduleId &&
          module.manufacturer_id === user.manufacturer.ID &&
          module.privilege !== 'none'
      )
    );

    hasNoPrivileges = !hasRequiredModules;
    lacksAllManufacturersPrivileges = false; // Single manufacturer, so this case doesn't apply

    return { hasRequiredModules, hasNoPrivileges, lacksAllManufacturersPrivileges };
  })();


  const handleDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem("sidebarOpen", JSON.stringify(!sidebarOpen));
    debouncedUpdateUserApi({ ...user?.settings, drawerIsOpen: !sidebarOpen });
  };
  const handleWidthChange = (newWidth: number) => {
    setSidebarWidth(newWidth);
  };

  useEffect(() => {
    if (isMobile && sidebarOpen) {
      handleDrawerToggle();
    }
  }, [isMobile]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Sidebar
        open={sidebarOpen}
        onToggle={handleDrawerToggle}
        onWidthChange={handleWidthChange}
        drawerWidth={sidebarWidth}
        setSidebarOpen={setSidebarOpen}
        items={navItems}
        isMobile={isMobile}
      />
      <AppContent
        sidebarWidth={sidebarWidth}
        open={sidebarOpen}
        isMobile={isMobile}
      >
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent sx={{ p: { sm: theme.gap} }}>
          <Container maxWidth={false}>
            {children}
            <Outlet />
            { hasRequiredModules ? null : hasNoPrivileges ? <Upgrade /> : <Upgrade messageType={'notAllManufacturers'}/>}
          </Container>
        </MainContent>
        <SnackbarAlert/>
      </AppContent>
    </Root>
  );
};

export default Dashboard;
