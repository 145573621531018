import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputBase,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchIcon, Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
import useData from "@/hooks/auth/useData";
import { LabelsResponse } from "@/api/setup/types";
import { AddLabelsModalProps } from "./types";

export default function AddLabelsModal({
  anchorEl,
  handleClose,
  setupLabelsApi,
  isKeyword,
  selectedLabel,
  rowIds,
  setSelectedLabel,
  isLeft,
  isMassAssign
}: AddLabelsModalProps) {
  const { t } = useTranslation();
  const [position, setPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState("");
  const [loadingLabelId, setLoadingLabelId] = useState<number  | null>(null);
  const open = Boolean(anchorEl);
  const { setupLabelsUpdateApi, setupKeywordsApi, setupCategoriesApi, user, selectedManufacturers } = useData();

  const manufacturerIds = useMemo(
    () => selectedManufacturers.map((m) => m.ID),
    [selectedManufacturers]
  );

  useEffect(() => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const modalHeight = 375;
      const viewportHeight = window.innerHeight;

      const spaceBelow = viewportHeight - rect.bottom;
      const spaceAbove = rect.top;

      let topPosition;

      if (spaceBelow >= modalHeight) {
        topPosition = rect.bottom;
      } else if (spaceAbove >= modalHeight) {
        topPosition = rect.top - modalHeight;
      } else {
        topPosition = rect.top + (rect.height - modalHeight) / 2;
      }

      setPosition({
        top: topPosition,
        left: rect.left,
      });
    }
  }, [anchorEl]);
  const hasAccess = (label: LabelsResponse) => {
    return (
      (user?.account_id !== undefined &&
        user?.account_id !== null &&
        (label.access as { [key: number]: number })?.[user.account_id] === 1) ||
      user?.account_id === label?.owner.account_id ||
      user?.role == 'admin'
    );
  };
  
  const filteredLabels = (setupLabelsApi.data || [])
    ?.filter(
      (label) =>
        label.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        label.comment.toLowerCase().includes(inputValue.toLowerCase())
    )
    .sort((a, b) => {
      const accessA = hasAccess(a);
      const accessB = hasAccess(b);
      if (accessA !== accessB) return accessA ? -1 : 1;

      return a.name.localeCompare(b.name);
    });

  const updateLabels = async (label: LabelsResponse) => {
    if (!hasAccess(label)) return;
    const labelState = getLabelState(label)
  
    setLoadingLabelId(label.ID);
  
    let updatedKeywords = [] as number[];
    if (Array.isArray(label.keywords)) {
      if (labelState === "all") {
        updatedKeywords = label.keywords.filter((keyword) => !rowIds.includes(keyword.ID)).map((item) => item.ID)
      } else {
        const keywords = rowIds.map((rowId) => ({ ID: rowId }))
        updatedKeywords = [...label.keywords, ...keywords].map((item) => item.ID)
      } 
    }

    let updatedCategories = [] as number[];
    if (Array.isArray(label.categories)) {
      if (labelState === "all") {
        updatedCategories = label.categories.filter((cat) => !rowIds.includes(cat.ID)).map((item) => item.ID)
      } else {
        const categories = rowIds.map((rowId) => ({ ID: rowId }))
        updatedCategories = [...label.categories, ...categories].map((item) => item.ID)
      }
    }
  
    const payload = {
      id: label.ID,
      keywords: isKeyword ? updatedKeywords : label.keywords.map((item) => item.ID),
      categories: !isKeyword ? updatedCategories : label.categories.map((item) => item.ID),
    };
  
    try {
      await setupLabelsUpdateApi.execute(payload);
  
      await setupLabelsApi.execute(undefined, false);
      
      if (isMassAssign && manufacturerIds.length) {
        if (isKeyword) {
          setupKeywordsApi.execute({
            m: manufacturerIds,
          }, false, false);
        } else {
          setupCategoriesApi.execute({
            m: manufacturerIds,
          }, false, false);
        }
      }
  
      if (selectedLabel) {
        const isLabelSelected = selectedLabel.some(
          (selected) => selected.label_id === label.ID
        );
  
        setSelectedLabel(
          isLabelSelected
            ? selectedLabel.filter((selected) => selected.label_id !== label.ID)
            : [
                ...selectedLabel,
                {
                  label_id: label.ID,
                  label_name: label.name,
                  label_color: label.color,
                },
              ]
        );
      }
    } catch (error) {
      console.error("Error updating labels:", error);
    } finally {
      setLoadingLabelId(null);
    }
  };
    
  const offset = isLeft ? 255 : 0  

  const isSelected = (labelId: number) =>
    selectedLabel?.some((selected) => selected.label_id === labelId);

  const getLabelState = (label: LabelsResponse) => {
    const labeledRows = isKeyword 
      ? label.keywords.map((keyword) => keyword.ID) 
      : label.categories.map((category) => category.ID) 
    
    const labeledSet = new Set(labeledRows);

    let allMatch = true;
    let someMatch = false;

    for (const row of rowIds) {
      if (labeledSet.has(row)) {
        someMatch = true;
      } else {
        allMatch = false;      }
    }

    if (allMatch) return 'all';
    if (someMatch) return 'some';
    return 'none';   
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: position?.top || 0,
          left: (position?.left || 0) - offset,
          transform: "translateY(10px)",
          bgcolor: "background.paper",
          visibility: position ? "visible" : "hidden",
          boxShadow: 24,
          borderRadius: 2,
          padding: 2,
          minWidth: 320,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #ccc",
            padding: "5px",
            width: "100%",
          }}
        >
          <SearchIcon width={24} height={24} opacity={0.25} />
          <InputBase
            placeholder="Search..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{
              flex: 1,
              color: "#888",
              fontSize: "16px",
              "::placeholder": {
                color: "#aaa",
              },
              marginLeft: "5px",
            }}
          />
        </Box>

        <Box
          sx={{
            height: 280,
            overflow: "auto",
          }}
        >
          {filteredLabels.map((label, index) => {
            const access = hasAccess(label);
            const labelState = getLabelState(label);
            
            return (
              <Box
                key={index}
                sx={{
                  padding: "7px 7px 7px 50px",

                  cursor: setupLabelsUpdateApi.isLoading
                    ? "not-allowed"
                    : access
                    ? "pointer"
                    : "default",
                  maxWidth: 320,
                  opacity: access ? 1 : 0.5,
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
                display="flex"
                alignItems="left"
                flexDirection="column"
                onClick={() =>
                  access &&
                  !setupLabelsUpdateApi.isLoading &&
                  updateLabels(label)
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {isSelected(label.ID) && loadingLabelId !== label.ID ? (
                    <Box
                      sx={{
                        position: "absolute",
                        left: -35,
                        top: "5px",
                      }}
                    >
                      <Check width={16} height={16} />
                    </Box>
                  ) : 
                  labelState === "all" ?
                    <Box
                      sx={{
                        position: "absolute",
                        left: -35,
                        top: "5px",
                      }}
                    >
                      <Tooltip title={t("general.tooltip.removeAllLabels")}>
                        <Check width={16} height={16} color="black" />
                      </Tooltip>
                    </Box>
                   : labelState === "some" && <Box
                      sx={{
                        position: "absolute",
                        left: -35,
                        top: "5px",
                      }}
                    >
                      <Tooltip title={t("general.tooltip.labelAll")}>
                        <Check width={16} height={16} color="grey"/>
                      </Tooltip>
                    </Box> }
                
                  {loadingLabelId === label.ID && (
                    <Box
                      sx={{
                        position: "absolute",
                        left: -35,
                        top: "5px",
                      }}
                    >
                      <CircularProgress size={16} color="inherit" />
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: 14,
                      height: 14,
                      borderRadius: "50%",
                      backgroundColor: label.color,
                      mr: "8px",
                    }}
                  />
                  
                  <Typography
                    fontWeight={700}
                    fontSize={"14px"}
                    lineHeight={"24px"}
                    color={access ? "text.primary" : "text.disabled"}
                  >
                    {label.name}
                  </Typography>
                </Box>
                <Typography
                  fontSize={"12px"}
                  color={access ? "text.secondary" : "text.disabled"}
                  lineHeight={"20px"}
                  mt={0.5}
                >
                  {label.comment}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Divider />
        <Button onClick={() => navigate("/setup/labels")}>
          + {t("pages.labels.addLabel")}
        </Button>
      </Box>
    </Modal>
  );
}
