import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColDef,
  GridRowSelectionModel,
  GridActionsCellItem,
  GridToolbarProps,
  GridPaginationModel,
  GridColumnVisibilityModel,
  GridCsvExportOptions,
  GridRowId,
  GridCsvGetRowsToExportParams,
  GridRowClassNameParams,
  GridSortItem,
  GridRowParams,
  GridValidRowModel,
  useGridApiRef,
  GridRowModel,
  GridFilterOperator,
  GridFilterItem,
  getGridStringOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
  GridRowHeightParams,
  GridState,
  GridPinnedColumnFields,
  gridFilteredSortedRowIdsSelector,
  GridFilterModel,
  GridInputRowSelectionModel, GridCallbackDetails, MuiEvent, GridGroupNode,
} from "@mui/x-data-grid-pro";

import {
  Box,
  Stack,
  Button,
  Skeleton,
  TextField,
  Typography,
  useTheme,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import useData from "@/hooks/auth/useData";
import { enUS, esES, deDE } from "@mui/x-data-grid/locales";
import { t } from "i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { JSX } from "@emotion/react/jsx-runtime";
import ProgressBar from "./ProgressBar";
import { gray } from "@/hooks/useColumnsData/consts";
import BaseError from "../errors/baseError";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { gray as lighterGray } from "@/hooks/useColumnsData/consts";
import { useDebounce } from "@/utils/useDebounce";

interface Props<T extends GridValidRowModel> {
  rows: T[];
  columns: GridColDef[];
  onRowSelectionModelChange?: (selectionModel: GridRowSelectionModel) => void;
  selectedRows?: GridRowSelectionModel;
  loading: boolean;
  hasCheckboxes?: boolean;
  filterBarType?: number;
  initialPageSize?: number;
  skeletonRowCount?: number;
  hiddenFields?: Array<string>;
  hasControls?: boolean;
  title?: string;
  searchPlaceholder?: string;
  tableInfo?: string;
  opacityTableInfo?: string;
  treeData?: boolean;
  getTreeDataPath?: (row: T) => string[];
  groupingColDef?: GridColDef;
  sortModel?: GridSortItem[];
  hasExport?: boolean;
  hasProgress?: boolean;
  getRowClassName?:
    | ((params: GridRowClassNameParams<any>) => string)
    | undefined;
  getDetailPanelContent?: (params: GridRowParams<T>) => React.ReactNode;
  getDetailPanelHeight?: (params: GridRowParams<T>) => number;
  excludedRowsOnSearch?: Array<string>;
  exportAllColumns?: boolean;
  isError?: boolean;
  error?: string | null;
  hasCustomError?: boolean;
  processRowUpdate?: (newRow: T, oldRow: T) => T;
  onProcessRowUpdateError?: (error: Error) => void;
  onRowClick?: (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => void;
  rowHeights?: { [key: GridRowId]: number | null };
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  pageChangeLoading?: boolean | undefined;
  alignCellTop?: boolean;
  onCreateClick?: () => void;
  getRowId?: (row: T) => string;
  hasDelete?: boolean;
  id: string;
  initialGridFilterModal?: GridFilterModel;
  customButton?: React.JSX.Element;
  isRowSelectable?: ((params: GridRowParams<any>) => boolean);
  rowSelectionModel?: GridInputRowSelectionModel;
}

/**
 * CustomToolbar component for Mui DataGrid
 *
 * @param {GridToolbarProps} props - The properties for the CustomToolbar component.
 * @returns {JSX.Element} The rendered CustomToolbar component
 */
const CustomToolbar: React.FC<GridToolbarProps> = (
  props: GridToolbarProps
): JSX.Element => {
  return (
    <Box padding={2}>
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? '' : 'Select columns' },
            button: { disabled: props.loading },
          }}
        />
        <GridToolbarFilterButton
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? '' : 'Show filters' },
            button: { disabled: props.loading },
          }}
        />
        <Box flexGrow={1} />
        <GridToolbarExport
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? '' : 'Export data' },
            button: { variant: 'outlined', disabled: props.loading },
          }}
        />
      </GridToolbarContainer>
    </Box>
  );
};

/**
 * CustomToolbar component for Mui DataGrid
 *
 * @param props - The properties for the CustomToolbar component.
 * @returns {JSX.Element} The rendered CustomToolbar component
 */

const CustomToolbar2: React.FC<GridToolbarProps> = ({
  customFilterValue,
  onCustomFilterChange,
  hasControls,
  selectedRows,
  searchPlaceholder,
  tableInfo,
  opacityTableInfo,
  hasExport,
  treeData,
  totalRows,
  hasProgress,
  hasDelete,
  exportAllColumns,
  ...props
}): JSX.Element => {
  const { user } = useData();
  const theme = useTheme();
  const userLanguage =
    user?.settings?.language || localStorage.getItem('language') || 'en';

  const getCsvSeparator = (lang: string) => (lang === 'de' ? ';' : ',');

  const csvSeparator = getCsvSeparator(userLanguage);

  const csvOptions: GridCsvExportOptions = {
    delimiter: csvSeparator,
    utf8WithBom: true,
    includeHeaders: true,
    shouldAppendQuotes: true,
    allColumns: exportAllColumns,
    getRowsToExport: (params: GridCsvGetRowsToExportParams) => {
      const visibleRowIds = gridFilteredSortedRowIdsSelector(params.apiRef);
      return visibleRowIds as GridRowId[];
    },
  };

  const [filterValue, setFilterValue] = useState(customFilterValue);
  const {debouncedValue: debouncedFilterValue, isFiltering} = useDebounce(filterValue);

  useEffect(() => {
    onCustomFilterChange(debouncedFilterValue);
  }, [debouncedFilterValue]);

  return (
    <Box>
      {tableInfo && (
        <Stack
          padding="16px 0"
          direction="row"
          alignItems="center"
          spacing="2px"
        >
          <InfoOutlinedIcon
            sx={{
              color: '#FFC107',
              width: '20px',
              height: '20px',
            }}
          />
          <Typography variant="body2" lineHeight="20px">
            {tableInfo}
          </Typography>
        </Stack>
      )}
      {opacityTableInfo && (
        <Typography sx={{ color: theme.palette.mode === "dark" ? gray : theme.palette.grey[700], paddingTop: "16px" }}>
          {opacityTableInfo}
        </Typography>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingTop="16px"
      >
        <Stack spacing="8px" direction="row">
          <TextField
            label={t('tableColumns.search')}
            variant="outlined"
            size="small"
            placeholder={searchPlaceholder}
            sx={{
              width: '200px',
              "& .MuiInputLabel-root": {
                top: "3px",
                color: (theme.palette.mode === "dark") ? lighterGray : theme.palette.grey[700],
                transition: "none",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            disabled={props.loading}
            InputProps={{
              endAdornment: isFiltering ? (
                <InputAdornment position="end">
                  <CircularProgress size={16} />
                </InputAdornment>
              ) : null,
            }}
          />
          <GridToolbarContainer sx={{ gap: '8px' }}>
            <GridToolbarFilterButton
              slotProps={{
                /**
                 * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
                 */
                tooltip: { title: props.loading ? '' : 'Show filters' },
                button: { disabled: props.loading, sx: {color: theme.palette.mode === "dark" ? "#A5C4EE" : "#0C599C"}},
              }}
            />
            <GridToolbarColumnsButton
              slotProps={{
                /**
                 * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
                 */
                tooltip: { title: props.loading ? '' : 'Select columns' },
                button: { disabled: props.loading, sx: {color: theme.palette.mode === "dark" ? "#A5C4EE" : "#0C599C"} },
              }}
            />
            {hasExport && (
              <GridToolbarExport
                csvOptions={csvOptions}
                slotProps={{
                  /**
                   * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
                   */
                  tooltip: { title: props.loading ? '' : 'Export data' },
                  button: { disabled: props.loading, sx: {color: theme.palette.mode === "dark" ? "#A5C4EE" : "#0C599C"} },
                }}
              />
            )}
            <props.ResetButton/>
          </GridToolbarContainer>
        </Stack>
        {hasProgress && <ProgressBar value={0} max={totalRows} />}
        {props.customButton}
        {hasControls && (
          <Stack spacing="10px" direction="row">
            {/* TODO: Remove this check when the delete functionality is implemented  */}
            {hasDelete && (
              <Button variant="outlined" disabled={selectedRows?.length === 0}>
                {t("general.delete")}
              </Button>
            )}
            <Button variant="contained" onClick={props.onCreateClick}>
              {t('general.create')}
            </Button>
          </Stack>
        )}
      </Box>
    </Box>
  );
};


const CustomToolbar3: React.FC<GridToolbarProps> = (
  props: GridToolbarProps
): JSX.Element => {
  const theme = useTheme();

  return (
    <Box padding={2}>
      {props.title && (
        <Typography sx={{ opacity: 0.6, padding: '8px' }}>
          {props.title}
        </Typography>
      )}
      <GridToolbarContainer>
        <GridToolbarFilterButton
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? '' : 'Show filters' },
            button: {
              disabled: props.loading,
              size: 'large',
              sx: { color: theme.palette.primary.main },
            },
          }}
        />
        <GridToolbarExport
          slotProps={{
            /**
             * Setting tooltip setting empty is necessary since otherwise we get a warning in the console
             */
            tooltip: { title: props.loading ? '' : 'Export data' },
            button: {
              disabled: props.loading,
              size: 'large',
              sx: { color: theme.palette.primary.main },
            },
          }}
        />
        <Box flexGrow={1} />
        {props.onCreateClick && (
          <Button
            variant="contained"
            disabled={props.loading}
            sx={{ marginLeft: '16px', backgroundColor: '#2196F3' }}
            onClick={props.onCreateClick}
          >
            {t('general.create')}
          </Button>
        )}
      </GridToolbarContainer>
    </Box>
  );
};

const globalIsEmptyOperator: GridFilterOperator = {
  label: t('general.filter.isEmpty') || 'is empty',
  value: 'isEmpty',
  getApplyFilterFn: (_: GridFilterItem) => {
    return (value, row, column, apiRef) => {
      const { valueGetter } = column;
      const cellValue = valueGetter
        ? valueGetter(value as never, row, column, apiRef)
        : value;
      return (
        cellValue === null ||
        cellValue === undefined ||
        cellValue === '' ||
        cellValue === t('tableColumns.notAvailable')
      );
    };
  },
};

const globalIsNotEmptyOperator: GridFilterOperator = {
  label: t('general.filter.isNotEmpty') || 'is not empty',
  value: 'isNotEmpty',
  getApplyFilterFn: (_: GridFilterItem) => {
    return (value, row, column, apiRef) => {
      const { valueGetter } = column;
      const cellValue = valueGetter
        ? valueGetter(value as never, row, column, apiRef)
        : value;
      return !(
        cellValue === null ||
        cellValue === undefined ||
        cellValue === '' ||
        cellValue === t('tableColumns.notAvailable')
      );
    };
  },
};

const skeletonCell = {
  renderCell: () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Skeleton variant="rectangular" width="100%" height={20} />
    </Box>
  ),
  sortable: false,
  filterable: false,
  isLoading: true,
};

const emptyFilterModal = {items: []};

/**
 *  MuiGridTable component which is a separately importable custom DataGrid component
 *
 * @param props - The properties for the  MuiGridTable component.
 * @returns {JSX.Element} The rendered  MuiGridTable component
 */
export const MuiGridTable: React.FC<Props<any>> = ({
  rows,
  columns,
  id,
  onRowSelectionModelChange,
  onRowClick,
  selectedRows,
  title,
  loading,
  hasCheckboxes = false,
  filterBarType = 1,
  initialPageSize = 100,
  skeletonRowCount = 3,
  hiddenFields = [],
  hasControls = false,
  searchPlaceholder = '',
  tableInfo,
  opacityTableInfo,
  treeData,
  getTreeDataPath,
  groupingColDef,
  getRowClassName,
  sortModel,
  hasExport = true,
  hasProgress = false,
  getDetailPanelContent,
  getDetailPanelHeight,
  excludedRowsOnSearch,
  exportAllColumns = false,
  processRowUpdate,
  onProcessRowUpdateError,
  isError,
  error,
  rowHeights,
  setPageSize,
  setPage,
  pageChangeLoading,
  alignCellTop,
  onCreateClick,
  hasCustomError = false,
  getRowId,
  initialGridFilterModal = {items: []},
  customButton,
  isRowSelectable,
  rowSelectionModel,
}): JSX.Element => {
  const { user, updateUserApi } = useData();
  const [customFilterValue, setCustomFilterValue] = useState<string>("");
  const [activeColumn, setActiveColumn] = useState<string | null>(null);
  const userLanguage =
    user?.settings?.language || localStorage.getItem('language') || 'en';
  const theme = useTheme();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: initialPageSize,
  });
  const [rowsAreRendered, setRowsAreRendered] = useState<boolean>(false);

  const [columnsLoading, setColumnsLoading] = useState<boolean>(true);
  const [initialState, setInitialState] = useState<GridInitialStatePro>()
  const [currentState, setCurrentState] = useState<GridInitialStatePro>()
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>(initialGridFilterModal);
  const expandedRowsRef = useRef<GridRowId[]>([]);

  const completeColumns =
    treeData && groupingColDef ? [groupingColDef, ...columns] : [...columns];
    const calculatedColumnVisibilityModel: GridColumnVisibilityModel =
    useMemo(() => {
      return completeColumns.reduce<GridColumnVisibilityModel>(
        (visibilityModel, column) => {
          visibilityModel[column.field] = !hiddenFields.includes(column.field);
          return visibilityModel;
        },
        {}
      );
    }, [completeColumns, hiddenFields]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => (calculatedColumnVisibilityModel));

  const handleColumnVisibilityChange = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(newModel);
    },
    []
  );

  const apiRef = useGridApiRef();

  useEffect(() => {
    const initItems = initialGridFilterModal.items;

    setFilterModel((prev) => {
      initItems.forEach((el) => {
        prev.items.forEach((el2, index) => {
          // @ts-ignore
          if (el2.field === el.field && !el2.fromInput) {
            prev.items[index] = el;
          }
        });
      })
      return prev;
    })

  }, [initialGridFilterModal]);

  useEffect(() => {
    const handleRowExpansionChange = (node: GridGroupNode) => {
      expandedRowsRef.current = node.childrenExpanded ?
        [...expandedRowsRef.current, node.id] :
        expandedRowsRef.current.filter((id) => id !== node.id);
    };

    const unsubscribe = apiRef.current.subscribeEvent(
      'rowExpansionChange',
      handleRowExpansionChange
    );

    // Cleanup subscription on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const isGroupExpanded = useCallback(
    (node: GridGroupNode) => {
      return expandedRowsRef.current.includes(node.id);
    },
    [expandedRowsRef]
  );

  const rowMaps = useMemo(() => {
    const rowMap = new Map<string, GridRowModel>();
    const parentMap = new Map<string, string[]>();

    if (!treeData || loading) return { rowMap, parentMap };

    rows.forEach((row) => {
      rowMap.set(row.hierarchy.join('/'), row);

      const hierarchy = row.hierarchy as string[];
      if (hierarchy.length > 1) {
        const parentId = hierarchy.slice(0, -1).join('/');
        const currentId = hierarchy.join('/');

        if (!parentMap.has(parentId)) {
          parentMap.set(parentId, []);
        }
        parentMap.get(parentId)!.push(currentId);
      }
    });

    return { rowMap, parentMap };
  }, [rows]);

  const getOperators = (col: GridColDef) => {
    let operators;
    switch (col.type) {
      case 'number':
        operators = getGridNumericOperators();
        break;
      case 'boolean':
        operators = getGridBooleanOperators();
        break;
      case 'date':
        operators = getGridDateOperators();
        break;
      case 'dateTime':
        operators = getGridDateOperators(true);
        break;
      case 'singleSelect':
        operators = getGridSingleSelectOperators();
        break;
      case 'string':
      default:
        operators = getGridStringOperators();
    }
    return operators;
  };

  const isIPadProOrSmaller = useMemo(() => {
    const screenWidth = Math.min(window.screen.width, window.screen.height);
    const screenHeight = Math.max(window.screen.width, window.screen.height);

    // iPad Pro dimensions (12.9-inch model) in portrait mode
    const maxWidth = 1024; // Maximum width of iPad Pro
    const maxHeight = 1366; // Maximum height of iPad Pro

    // Check if the screen dimensions are within the iPad Pro range or smaller
    return screenWidth <= maxWidth && screenHeight <= maxHeight;
  }, [window.screen]);

  const modifiedColumns = useMemo(() => {
    if (currentState?.columns?.columnVisibilityModel) {
      setColumnVisibilityModel(currentState.columns.columnVisibilityModel);
    }
    const orderedFields = currentState?.columns?.orderedFields || [];
    const reorderedColumns = columns.sort(
      (a, b) => orderedFields.indexOf(a.field) - orderedFields.indexOf(b.field)
    );

    // Apply filtering operators and conditionally disable column menus
    return reorderedColumns.map((col) => {
      const baseOps = getOperators(col);
      const ids = (col.filterOperators || []).map((c) => c.value);
      const newOps = baseOps.filter(
        (op) =>
          op.value !== 'isNotEmpty' &&
          op.value !== 'isEmpty' &&
          !ids.includes(op.value)
      );
      if (!ids.includes('isEmpty')) {
        newOps.push(globalIsEmptyOperator);
      }
      if (!ids.includes('isNotEmpty')) {
        newOps.push(globalIsNotEmptyOperator);
      }

      // Add iPad Pro or smaller condition
      const additionalProps = isIPadProOrSmaller
        ? { disableColumnMenu: activeColumn !== col.field }
        : {};

      return {
        ...col,
        filterOperators: [...newOps, ...(col.filterOperators || [])],
        ...additionalProps,
      };
    });
  }, [columns, currentState, columns, activeColumn, isIPadProOrSmaller]);


  const getLocale = (lang: string) => {
    switch (lang) {
      case 'es':
        return esES;
      case 'de':
        return deDE;
      default:
        return enUS;
    }
  };

  const locale = getLocale(userLanguage);

  /**
   * Create placeholder columns for DataGrid while loading to show Skeletons.
   * The original columns are modified, so that the sizes stay the same.
   *
   * @returns {GridColDef[]} Grid Column defintion with placeholders
   */
  const columnsPlaceholder = useMemo(() => {
    const placeholder: GridColDef[] = columns.map((el: GridColDef) => {
      let newDef = {};
      if (el.field === 'actions') {
        newDef = {
          getActions: () => [
            <GridActionsCellItem
              icon={<Skeleton variant="rectangular" width={22} height={22} />}
              label="Edit"
              disabled
            />,
          ],
        };
      } else {
        newDef = skeletonCell;
      }
      return {
        ...el,
        ...newDef,
      };
    });
    return placeholder;
  }, [columns]);

  /**
   * Create placeholder rows for DataGrid while loading to show Skeletons.
   * List objects containing only the necessary id param.
   *
   * @returns {Array<{ id: string, hierarchy: Array<string> }>}
   * List objects with row index and hierarchy as needed to create placeholder Skeletons
   */
  const rowsPlaceholder = useMemo(() => {
    return Array.from({ length: skeletonRowCount }).map((_, rowIndex) => ({
      id: `skeleton-loader-${rowIndex}`,
      hierarchy: [`skeleton-loader-${rowIndex}`],
    }));
  }, [skeletonRowCount]);

  /**
   * Sets state to keep track of current page size
   *
   * @param {GridPaginationModel} paginationModel - MUI Pagninaton Model
   */
  const handlePageSizeChange = (paginationModel: GridPaginationModel) => {
    // important to distinguish between loading and the loading triggered by page changes
    if (pageChangeLoading) return;
    if (setPageSize) setPageSize(paginationModel.pageSize);
    if (setPage) setPage(paginationModel.page);
    setPaginationModel(paginationModel);
  };
  const ResetButton = () => (
    <Button
      sx={{fontSize: 12, padding: "4px 5px", color: theme.palette.mode === "dark" ? "#A5C4EE" : "#0C599C"}}
      disabled={!Object.keys(currentState?.columns || {}).length}
      onClick={() => {
        apiRef.current.restoreState(initialState ?? {})
        handleColumnChange({state: {pinnedColumns: {left: [], right: []}}})
      }}
      >
      {t("tableColumns.resetToDefault")}
    </Button>
  );

  const getToolbar = () => {
    if (filterBarType) {
      return CustomToolbar2;
    }
    return undefined;
  };

  const getAncestors = (nodeId: string) => {
    const map = rowMaps.rowMap;
    const ancestors: typeof rows = [];
    const currentRow = map.get(nodeId);

    if (!treeData || loading) return ancestors;

    if (currentRow) {
      let hierarchy = currentRow.hierarchy as string[];

      while (hierarchy.length > 1) {
        hierarchy = hierarchy.slice(0, -1);
        const ancestorId = hierarchy.join('/');
        const ancestorRow = map.get(ancestorId);

        if (ancestorRow) {
          ancestors.push(ancestorRow);
        }
      }
    }
    return ancestors;
  };

  const getDescendants = (nodeId: string) => {
    const mapParent = rowMaps.parentMap;
    const map = rowMaps.rowMap;
    const descendants: typeof rows = [];
    const stack = [nodeId];

    if (!treeData || loading) return descendants;

    while (stack.length > 0) {
      const currentId = stack.pop()!;
      const currentRow = map.get(currentId);
      if (currentRow) {
        descendants.push(currentRow);

        const childPaths = mapParent.get(currentId) || [];
        childPaths.forEach((childPath) => {
          stack.push(childPath);
        });
      }
    }

    return descendants;
  };

  const applyCustomFilter = (): typeof rows => {
    if (!customFilterValue) return rows;

    const lowercasedFilter = customFilterValue.toLowerCase();    

    if (treeData) {
      const matchingRows = new Set<typeof rows>();

      rows.forEach((row) => {
        const isMatch = completeColumns.some((col) => {
          const fieldName = col.field;
          const cVal = row[fieldName] as never;

          if (
            !columnVisibilityModel[fieldName] ||
            (excludedRowsOnSearch || []).includes(fieldName)
          )
            return false;

          const cellValue = col.valueGetter
            ? col.valueGetter(cVal, row, col, apiRef)
            : row[col.field];

          return String(cellValue).toLowerCase().includes(lowercasedFilter);
        });

        if (isMatch) {
          matchingRows.add(row);
          const descendants = getDescendants(row.hierarchy.join('/'));
          descendants.forEach((descendant) => matchingRows.add(descendant));
          const ancestors = getAncestors(row.hierarchy.join('/'));
          ancestors.forEach((ancestor) => matchingRows.add(ancestor));
        }
      });

      return Array.from(matchingRows);
    } else {
      return rows.filter((row) => {
        return completeColumns.some((col: any) => {
          const fieldName = col.field;

          const cVal = row[fieldName] as never;

          if (
            !columnVisibilityModel[fieldName] ||
            (excludedRowsOnSearch || []).includes(fieldName)
          )
            return false;

          const cellValue = col.valueGetter
            ? col.valueGetter(cVal, row, col, apiRef)
            : cVal;

          return String(cellValue).toLowerCase().includes(lowercasedFilter);
        });
      });
    }
  };

  const handleStateChange = (newState: GridState) => {
    // Access the current rows being displayed in the grid
    const ids = newState.rows.dataRowIds;
    const skeletonUsed = ids.find((id) =>
      id.toString().startsWith('skeleton-loader-')
    );
    const columnsState = newState.columns;
    const columnsAreLoading = columnsState.orderedFields.find(
      // @ts-ignore
      (field) => columnsState.lookup[field].isLoading === true
    );

    setColumnsLoading(columnsAreLoading !== undefined);
    setRowsAreRendered(!skeletonUsed);
  };

  const setTableState = () => {
    if (!user) {
      return
    }

    const exportInitialState = apiRef.current.exportState()
    if (!initialState) {
      setInitialState(exportInitialState)
    }

    const currentColumns = user.settings?.tableState?.[id]?.columns
    const pinnedColumns = user.settings?.tableState?.[id]?.pinnedColumns
    if (currentColumns) {
      setCurrentState({...exportInitialState, pinnedColumns, columns: currentColumns})
      apiRef.current.restoreState({...exportInitialState, columns: currentColumns})
    }
  }

  useLayoutEffect(() => {
    setTableState()
  }, [user]);

  const handleColumnChange = ({state, columnVisibilityModel, pinnedColumns}: {
    state?: GridInitialStatePro,
    columnVisibilityModel?: GridColumnVisibilityModel,
    pinnedColumns?: GridPinnedColumnFields}) => {
    if (!initialState) {
      // to disable state export on first render, when saved state was not applied yet
      return
    }
    let currentState = state || apiRef.current.exportState()

    if (columnVisibilityModel) {
      currentState = {...currentState, columns: {...currentState.columns, columnVisibilityModel}}
    }

    if (pinnedColumns) {
      currentState = {...currentState, pinnedColumns}
    }

    setCurrentState(currentState)

    if (user) {
      updateUserApi?.execute({
        settings: {
          ...user.settings,
          tableState: {
            ...user.settings?.tableState,
            [id]: currentState,
          }
        }
      });
    }
  }

  const totalRows = rows.length;

  return (
    <Box sx={{ border: 'none' }}>
      <DataGridPro
        //hide rows of datagrid until heights are calculated
        loading={!loading && !rowsAreRendered}
        apiRef={apiRef}
        isGroupExpandedByDefault={isGroupExpanded}
        groupingColDef={
          groupingColDef
            ? loading
              ? { ...groupingColDef, ...skeletonCell }
              : groupingColDef
            : undefined
        }
        treeData={treeData || false}
        getTreeDataPath={treeData ? getTreeDataPath : undefined}
        onColumnVisibilityModelChange={(newModel) => {
            handleColumnChange({columnVisibilityModel: newModel})
          }
        }
        columnVisibilityModel={columnVisibilityModel}
        onColumnOrderChange={() => handleColumnChange({})}
        onPinnedColumnsChange={(pinnedColumns) => handleColumnChange({pinnedColumns})}
        pinnedColumns={currentState?.pinnedColumns}
        onStateChange={handleStateChange}
        getRowHeight={(params: GridRowHeightParams) =>
          !loading && rowHeights ? rowHeights[params.id] || 52 : 52
        }
        autoHeight
        sx={{
          '& .MuiDataGrid-columnHeaderMenuIcon--active': {
            visibility: 'visible', // Show when active
          },
          '& .MuiDataGrid-columnHeaderMenuIcon': {
            visibility: isIPadProOrSmaller ? 'hidden' : 'visible', // Hide by default
          },
          '& .nested-row': {
            backgroundColor: theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : '#FDFBFB',
          },
          '--DataGrid-containerBackground': theme.palette.background.paper,
          '--DataGrid-overlayHeight': '679px',
          minHeight: 679,
          padding: '0 16px 16px 16px',
          '& .MuiDataGrid-row.gray-50-row': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.background.default
                : theme.palette.grey[50],
          },
          '& .MuiDataGrid-detailPanel': {
            backgroundColor: theme.palette.background.paper,
          },
          '& .MuiDataGrid-row.row-highlight': {
            animation: 'highlight 1s ease-out',
            '@keyframes highlight': {
              from: { backgroundColor: '#fffae6' },
              to: { backgroundColor: 'transparent' },
            },
          },

          '& .MuiDataGrid-row.gray-100-row': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? '#192230'
                : theme.palette.grey[100],
          },
          '& .MuiDataGrid-columnHeader.grouping-header': {
            paddingLeft: '50px',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '.Mui-disabled-row': {
            pointerEvents: 'none',
            opacity: 0.5,
          },
          border: 'none',
          ...(alignCellTop && {
            '& .MuiDataGrid-cell': {
              lineHeight: '51px',
            },
          }),
        }}

        columns={loading ? columnsPlaceholder : modifiedColumns}
        // await that columns are in loading mode for placeholder
        rows={
          loading
            ? columnsLoading
              ? rowsPlaceholder
              : []
            : applyCustomFilter()
        }
        pagination
        paginationModel={paginationModel}
        initialState={currentState || {
          pagination: {
            paginationModel: {
              pageSize: initialPageSize,
            },
          },
          columns: {
            columnVisibilityModel,
          },
          sorting: { sortModel: sortModel || [] },
        }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        onPaginationModelChange={handlePageSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        checkboxSelection={hasCheckboxes}
        filterModel={loading ? emptyFilterModal : filterModel}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        disableRowSelectionOnClick

        slots={{
          toolbar: getToolbar(),
          noRowsOverlay: isError
            ? () => (
                <BaseError text={error || ''} hasCustomError={hasCustomError} />
              )
            : // prevent no rows text if columns are loading
            columnsLoading
            ? () => null
            : undefined,
          //hide rows of datagrid until heights are calculated
          loadingOverlay: () => (
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                height: '100%',
                width: '100%',
              }}
            ></Box>
          ),
        }}
        slotProps={{
          toolbar: {
            loading: loading,
            customFilterValue: customFilterValue,
            onCustomFilterChange: setCustomFilterValue,
            title,
            hasControls: hasControls,
            selectedRows: selectedRows,
            searchPlaceholder: searchPlaceholder,
            tableInfo: tableInfo,
            opacityTableInfo: opacityTableInfo,
            hasExport: hasExport,
            treeData: treeData,
            totalRows: totalRows,
            hasProgress: hasProgress,
            allColumns: exportAllColumns,
            onCreateClick: onCreateClick,
            ResetButton: ResetButton,
            customButton: customButton,
          },
          pagination: {
            sx: {
              display: loading ? 'none' : 'flex',
            },
          },
          baseCheckbox: {
            disabled: loading,
          },
          /*loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },*/
        }}
        //loading={loading}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onRowClick={onRowClick ? onRowClick : undefined}
        localeText={locale.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={getRowClassName ? getRowClassName : undefined}
        disableChildrenSorting
        getDetailPanelContent={
          getDetailPanelContent ? getDetailPanelContent : undefined
        }
        getDetailPanelHeight={
          getDetailPanelHeight ? getDetailPanelHeight : undefined
        }
        disableChildrenFiltering={true}
        disableColumnResize={true}
        getRowId={getRowId}
        isRowSelectable={isRowSelectable}
        rowSelectionModel={rowSelectionModel}
        onColumnHeaderClick={(params) => {
          if (isIPadProOrSmaller) {
            setActiveColumn(params.field);
          }
        }}
      />
    </Box>
  );
};

export default MuiGridTable;
