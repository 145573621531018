import { SidebarItemsType } from '@/types/sidebar';

import {
  ShoppingCart,
  Search,
  DescriptionOutlined,
  CheckCircle,
} from '@mui/icons-material';
import MovingIcon from '@mui/icons-material/Moving';
import { useTranslation } from 'react-i18next';
import WrenchTool from '@/assets/svg/wrench-tool';

const pagesSection = [
  {
    href: '/visibility',
    title: 'navigation.visibility',
    icon: Search,
    module: 'visibility',
    children: [
      {
        href: '/visibility/share-of-search',
        title: 'navigation.shareOfSearch',
      },
    ],
  },
  {
    href: '/availability',
    title: 'navigation.availability',
    icon: CheckCircle,
    module: 'availability',
    children: [
      {
        href: '/availability/overview',
        title: 'navigation.availabilityOverview',
        topbarTitle: 'navigation.availabilityOverviewHeader',
      },
      {
        href: '/availability/product-listings',
        title: 'navigation.productListings',
      },
    ],
  },
  {
    href: '/content',
    icon: DescriptionOutlined,
    title: 'navigation.content',
    module: 'content',
    children: [
      {
        href: '/content/merchants-product-catalog',
        title: 'navigation.contentDashboard',
      },
      {
        href: '/content/ratings-and-reviews',
        title: 'navigation.ratingsAndReviews',
      },
      {
        href: '/content/packshots-and-images',
        title: 'navigation.packshotsAndVideos',
      },
      {
        href: '/content/ai-optimizer',
        title: 'navigation.aiOptimizer',
      },
    ],
  },
  {
    href: '/pricing',
    icon: MovingIcon,
    title: 'navigation.pricing',
    module: 'pricing',
    children: [
      {
        href: '/pricing/overview',
        title: 'navigation.pricingOverview',
        topbarTitle: 'pages.pricingOverview.title',
      },
    ],
  },
  {
    href: '/buy-now',
    icon: ShoppingCart,
    title: 'navigation.buyNow',
    module: 'buy-now',
    children: [
      {
        href: '/buy-now/dashboard',
        title: 'navigation.dashboard',
        topbarTitle: 'navigation.buyNowDashboard',
      },
      {
        href: '/buy-now/transactions',
        title: 'navigation.buyNowTransactions',
      },
      {
        href: '/buy-now/components',
        title: 'navigation.buyNowComponents',
        topbarTitle: 'navigation.buyNowComponentsHeader',
      },
      {
        href: '/buy-now/properties',
        title: 'navigation.buyNowProperties',
        topbarTitle: 'navigation.buyNowPropertiesHeader',
      },
    ],
  },
  {
    href: '/setup',
    title: 'navigation.setup',
    icon: WrenchTool,
    children: [
      {
        href: '/setup/labels',
        title: 'navigation.labels',
      },
      {
        href: '/setup/keywords',
        title: 'navigation.keywords',
      },
      {
        href: '/setup/categories',
        title: 'navigation.categories',
      },
      {
        href: '/setup/products',
        title: 'navigation.myProducts',
      },
      {
        href: '/setup/markets',
        title: 'navigation.markets',
      },
    ],
  },
] as SidebarItemsType[];

const hiddenPagesSection = [
  {
    href: "/management",
    children: [
      {
        href: "/management/contracts",
        title: "pages.contracts.title",
      },
      {
        href: "/management/usage",
        title: "pages.usage.title",
      },
      {
        href: "/management/users",
        title: "navigation.manageUsers",
      },
      {
        href: "/management/accounts",
        title: "navigation.manageAccounts",
      }
    ]
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: '',
    pages: pagesSection,
  },
];

/**
 * Find title based on current path
 *
 * @param {string} pathname - current pathname
 * @returns {string} Title of current path which is empty String if no match
 */
export function getTitleByPathname(pathname: string): string {
  const { t } = useTranslation();
  const combinedSections = [...pagesSection, ...hiddenPagesSection];
  const flattenedItems = combinedSections.flatMap((section) =>
    section.children ? [section, ...section.children] : section
  );

  if (pathname === '/') return t('navigation.welcome');

  const matchingItem = flattenedItems.find((item) => item.href === pathname);

  return (
    t(matchingItem?.topbarTitle as string) ||
    t(matchingItem?.title as string) ||
    ''
  );
}

export default navItems;

// Find parent module by pathname
export function getParentModule(pathname: string): string {
  const flattenedItems = pagesSection.flatMap((section) =>
    section.children ? [section, ...section.children] : section
  );

  const matchingItem = flattenedItems.find((item) => item.href === pathname);
  if (!matchingItem) return '';

  // If the item has a module property, it's a parent
  if (matchingItem.module) return matchingItem.module;

  // If not, find the parent section
  const parentSection = pagesSection.find((section) =>
    section.children?.some((child) => child.href === pathname)
  );

  return parentSection?.module || '';
}
