import { Label } from "@/api/setup/types";
import useData from "@/hooks/auth/useData";
import { Button } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useState } from "react";
import AddLabelsModal from "../AddLablesModal";
import { useTranslation } from "react-i18next";

const LabelButton = ({selectedRows, isKeyword}: {selectedRows: GridRowSelectionModel, isKeyword: boolean}) => {
  const {t} = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedLabel, setSelectedLabel] = useState<Label[] | null>(null);

  const { setupLabelsApi } = useData();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button disabled={!selectedRows.length} variant="contained" onClick={handleOpen} style={{textTransform: "uppercase"}}>
        {t("tableColumns.label")}
      </Button>
      
      <AddLabelsModal
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        rowIds={selectedRows as number[]}
        setupLabelsApi={setupLabelsApi}
        isKeyword={isKeyword}
        isLeft={true}
        isMassAssign={true}
      />
    </>
  )
};

export default LabelButton;
