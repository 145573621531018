import { deleteMutationFunction } from "./mutations/deleteMutationFunction";
import { createQueryFunction } from "./queries/createQueryFunction";
import { ApiMutation, useApiMutation } from "./useApiMutation";
import { ApiQuery, useApiQuery } from "./useApiQuery";

export interface Manufacturer {
  ID: number;
  NAME: string;
  NAME_COMPANY: string;
  GROUP: string;
  access: string;
}

export type ManufacturerParams = {
  every?: boolean,
  focus?: number,
}

export type ManufacturersResponse = Manufacturer[];

export interface DeleteManufacturerParams {
  manufacturerId: number;
}

export type DeleteManufacturersResponse = {};


export interface ManufacturersParams {
  every?: boolean;
}

/**
 * Type representing the API query for manufacturers.
 *
 * @template TResponse - The type of the response data.
 * @template TQueryParam - The type of the query parameter.
 * @template TMutationResponse - The type of the mutation response data.
 * @template TMutationParam - The type of the mutation parameter.
 */
export type ManufacturersApiType = {
  isLoading: boolean;
  manufacturers: ApiQuery<ManufacturersResponse, ManufacturerParams>;
  deleteManufacturer: ApiMutation<
    DeleteManufacturersResponse,
    DeleteManufacturerParams
  >;
  allManufacturers: ApiQuery<ManufacturersResponse, ManufacturersParams>;
};


const key = "manufacturers";

/**
 * Custom hook to handle API queries for manufacturers.
 *
 * @param params - Optional parameters for the query
 * @param params.every - If true, returns all manufacturers. Defaults to false
 * @returns {ManufacturersApiType} - An object containing the loading state, manufacturers data query,
 * and the delete manufacturer mutation.
 */
export const useManufacturers = (): ManufacturersApiType => {
  const manufacturers = useApiQuery<ManufacturersResponse, ManufacturerParams>({
    key,
    queryFn: createQueryFunction(key),
  });

  const deleteManufacturer = useApiMutation<
    DeleteManufacturersResponse,
    DeleteManufacturerParams
  >({
    mutationFn: deleteMutationFunction<
      DeleteManufacturersResponse,
      DeleteManufacturerParams
    >(`${key}/delete`),
  });

  const allManufacturers = useApiQuery<ManufacturersResponse, ManufacturersParams>({
    key: `${key}?every=true`,
    queryFn: createQueryFunction(`${key}?every=true`),
  });

  return {
    isLoading: manufacturers.isLoading || deleteManufacturer.isLoading || allManufacturers.isLoading,
    manufacturers,
    deleteManufacturer,
    allManufacturers,
  };
};

