import React, {useEffect, useMemo, useState} from "react";
import { Paper } from "@mui/material";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import CustomGridTreeDataGroupingCell from "@/components/CustomGridTreeDataGroupingCell";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";

export const UsersTraffic = () => {
  const {
    dateRange,
    allUsersTrafficApi,
  } = useData();

  const { getTranslatedHeaderName, na } = useCustomTableUtils();
  
  const columns = useColumns();

  useEffect(() => {
    const params = {
      from_date: dateRange.from_date,
      to_date: dateRange.to_date,
    };
    allUsersTrafficApi.execute(params);
  }, [dateRange?.from_date, dateRange.to_date]);
  
  const rows = useMemo(() => {
    const data = allUsersTrafficApi?.data || [];
    return data;
  }, [allUsersTrafficApi?.data]);

  const groupingSovColDef: GridColDef = {
    headerName: getTranslatedHeaderName("tableColumns.rowToggler",  "Table Row Toggler"),
    renderHeader: () => "",
    headerClassName: "grouping-header",
    field: "name",
    width: 65,
    align: 'center',
    renderCell: (params: GridRenderEditCellParams) => (
      <CustomGridTreeDataGroupingCell {...params} content={""} />
    ),
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === "user" ? row.name : "";
    },
  };

  const nameColumn: GridColDef = {
    headerName: getTranslatedHeaderName("tableColumns.name",  "Name"),
    field: "name",
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {
      if (params.row.type != "user") return "";
      const userFullName = params.row.name;
      return userFullName ? (
        <span style={{ fontWeight: 500 }}>{userFullName}</span>
      ) : (
        na
      );
    },
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === "user" ? row.name : "";
    },
  };

  const companyColumn: GridColDef = {
    field: "company",
    headerName: getTranslatedHeaderName("tableColumns.company",  "Company"),
    minWidth: 150,
    flex: 1,
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === "user" ? row.company : "";
    },
  };

  const myDataGridColumns = [
    nameColumn,
    companyColumn,
    columns.pageColumn,
    columns.systemColumn,
    columns.ipAddressColumn,
    columns.timeOfVisitColumn,
  ];

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          id="usersTraffic"
          filterBarType={2}
          rows={rows}
          searchPlaceholder={
            getTranslatedHeaderName("tableColumns.searchUsersPlaceholder", "Name, Company, ...")
          }
          columns={myDataGridColumns}
          loading={allUsersTrafficApi.showSkeletton}
          getTreeDataPath={(row) => row.hierarchy || []}
          treeData
          groupingColDef={groupingSovColDef}
          getRowClassName={(params) => {
            if (params.row.type === "traffic" || params.row.keyword) {
              return "gray-50-row";
            } 
            return "";
          }}
          hasCustomError={true}
          isError={allUsersTrafficApi.isError}
          error={allUsersTrafficApi.error}
        />
      </Paper>

     
    </ContentContainer>
  );
};
