import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {initialFormState, privileges, roles, salutations, titles} from './consts';
import useData from "@/hooks/auth/useData";
import * as generator from 'generate-password';
import {UserCreateRequest} from "@/api/management/types";
import PasswordDialog from '../../accounts/UsersTraffic/PasswordDialog';
import DynamicDialog from '@/components/DynamicDialog';
import { UserResponse } from '@/types/user';

interface FormData {
  salutation: number;
  title: number;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  company: number;
  maker: number[];
  role: number;
  privilege: number;
  comment: string;
}

interface ActionsDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (data: any) => void;
  dialogMode: "create" | "edit" | "overview";
  selectedRowData?: UserResponse;
}

const ActionsDialog = ({open, onClose, onSuccess, dialogMode, selectedRowData}: ActionsDialogProps) => {
  const {t} = useTranslation();
  const [formData, setFormData] = useState<FormData>(initialFormState);
  const {accountsApi, manufacturersApi, createUserApi, isLoading, editUserApi, allUsersApi} = useData();
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [isMultipilePrivileges, setIsMultipilePrivileges] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, boolean>>({});
  const isReadOnly = useMemo(() => dialogMode === "overview", [dialogMode]);

  const hasValidationErrors = Object.values(validationErrors).some((error) => error);
  const manufacturersData = useMemo(() => manufacturersApi?.manufacturers.data || [], [manufacturersApi?.manufacturers]);
  const findRoleValue = (role: string): number => {
    const matchedRole = roles.find(
      (r) => r.label.toLowerCase() === role.toLowerCase()
    );
    return matchedRole ? matchedRole.value : -1;
  };
  function allElementsSame(arr: Array<number>) {
    return new Set(arr).size === 1;
  }

  const findUserMakerPrivileges = (privileges: Array<number>): number => {
    if(privileges.length === 1 || allElementsSame(privileges)) {
      return privileges[0];
    } else if (privileges.length > 1) {
      setIsMultipilePrivileges(true);
    }
    return -1;
  }

  useEffect(() => {
    if ((dialogMode === "edit" || dialogMode === "overview") && selectedRowData) {
      setFormData({
        salutation: Number(selectedRowData?.salutation) || -1,
        title: Number(selectedRowData.title) || -1,
        firstName: selectedRowData.first_name || "",
        lastName: selectedRowData.last_name || "",
        password: "",
        email: selectedRowData.mail || "",
        company: Number(selectedRowData.company_details?.id) || -1,
        maker: selectedRowData.company_details?.maker.map((m) => Number(m.id)) || [],
        role: findRoleValue(selectedRowData?.role || "") || -1,
        privilege: findUserMakerPrivileges(selectedRowData.company_details?.maker.map((m) => Number(m.privilege_id)) || []), // Not allowed on Edit mode
        comment: selectedRowData?.comment || "",
      });
    } else if (dialogMode === "create") {
      handleResetState();
    }
  }, [dialogMode, selectedRowData, open]);

  
  useEffect(() => {
    accountsApi.execute(undefined, false);
  }, [])

  const createPassword = (): string => {
    return generator.generate({
      length: 24,
      numbers: true,
      symbols: true,
      uppercase: true,
      excludeSimilarCharacters: true,
      strict: true
    });
  }

  const isFormPopulated = () => {
    const requiredFields: (keyof FormData)[] = ['firstName', 'lastName', 'email', 'company', 'maker', 'role', 'privilege'];
    return requiredFields.every((field) => {
      const value = formData[field];
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== -1 && !!value;
    });
  };


  const validateEmail = (email: string, companyId: number) => {
    const selectedCompany = accountsApi.data?.find(
      (account) => account.account_id === companyId
    );
  
    const emailDomain = selectedCompany?.email_domain;
    const validDomains = ["maven360.io"];
    if (emailDomain) validDomains.push(emailDomain);
  
    const isValid =
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) &&
      validDomains.some((domain) => email.endsWith(`@${domain}`));
  
    if (!isValid) {
      validationErrors.email = true;
    } else {
      delete validationErrors.email;
    }
  
    setValidationErrors({...validationErrors});
  };

  const validateFirstName = (firstName: string) => {
    const isValid = firstName.length <= 50;

    if (!isValid) {
      validationErrors.firstName = true;
    } else {
      delete validationErrors.firstName;
    }

    setValidationErrors({...validationErrors});
  };

  const validateLastName = (lastName: string) => {
    const isValid = lastName.length <= 500;

    if (!isValid) {
      validationErrors.lastName = true;
    } else {
      delete validationErrors.lastName;
    }

    setValidationErrors({...validationErrors});
  };

  const handleInputChange = (field: keyof FormData, value: string | null) => {
    if (!value) value = '';

    setFormData((prev) => ({...prev, [field]: value}));

    if (field === 'email') {
      validateEmail(value, formData.company);
    } else if (field === 'firstName') {
      validateFirstName(value);
    } else if (field === 'lastName') {
      validateLastName(value);
    }
  };

  const handleSave = async () => {
    try {
      const generatedPassword = createPassword();
      const updatedFormData = {
        ...formData,
        password: generatedPassword,
      };
      setFormData(updatedFormData);

      const manufacturer_privileges = formData.maker.map((manufacturerId) => ({
        manufacturer_id: manufacturerId,
        privilege: formData.privilege,
      }));

      const requestData: UserCreateRequest = {
        username: formData.email,
        password: updatedFormData.password,
        first_name: formData.firstName,
        last_name: formData.lastName,
        role: formData.role,
        account_id: formData.company,
        manufacturer_privileges: manufacturer_privileges,
        gender: formData.salutation,
        comment: formData.comment,
      };

      if (formData.title > 0) {
        requestData.title = formData.title;
      }

      dialogMode === "create" ? await createUserApi.execute(requestData) : await editUserApi.execute({...requestData, user_id: selectedRowData?.user_id});

      dialogMode === "create" && setIsPasswordDialogOpen(true);
      onSuccess(formData);
      dialogMode !== "create" && onClose();
      await allUsersApi.execute(undefined, false);
    } catch (error: any) {
      throw error;
    }
  };

  const handlePasswordDialogClose = () => {
    setIsPasswordDialogOpen(false);
    onClose();
  };

  const handleResetState = () => {
    setFormData(initialFormState);
    setValidationErrors({});
  };

  const handleClose = () => {
    handleResetState();
    onClose();
  };

  const selectedCompany = accountsApi.data?.find((account) => account.account_id === formData.company);
  const manufacturers_access = selectedCompany?.manufacturers.map((access) => access.id) || [];


  useEffect(() => {
    const availableOptions = manufacturersData.filter((manufacturer) =>
      manufacturers_access.includes(manufacturer.ID)
    );

    if (availableOptions.length === 1 && !formData.maker.includes(availableOptions[0].ID)) {
      setFormData((prevData) => ({
        ...prevData,
        maker: [availableOptions[0].ID],
      }));
    }
  }, [manufacturersData, manufacturers_access, formData.maker]);

  const content = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} margin="dense">
          <FormControl fullWidth margin="dense">
            <Autocomplete
              disabled={isReadOnly}
              value={
                accountsApi.data?.find((account) => account.account_id === formData.company) || null
              }
              onChange={(event, newValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  company: newValue ? newValue.account_id : -1,
                  maker: [],
                }));
                validateEmail(formData.email, newValue ? newValue.account_id : -1)
              }}
              options={accountsApi.data || []}
              getOptionLabel={(option) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.account_id === value?.account_id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  disabled={isReadOnly}
                  size="medium"
                  InputLabelProps={{shrink: true}}
                  placeholder='Select'
                  label={t('pages.createUserModal.company')}
                  variant="standard"
                  margin="dense"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <Autocomplete
              disabled={isReadOnly}
              multiple
              disableCloseOnSelect
              value={
                manufacturersData.filter((manufacturer) => formData.maker.includes(manufacturer.ID))
              }
              onChange={(event, newValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  maker: newValue.map((item) => item.ID),
                }));
              }}
              options={manufacturersData.filter((manufacturer) =>
                manufacturers_access.includes(manufacturer.ID)
              )}
              noOptionsText={formData.company !== -1 ? t('pages.createUserModal.noMakerAvailable') : t('pages.createUserModal.noCompanySelected')}
              getOptionLabel={(option) => option.NAME}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  disabled={isReadOnly}
                  size="medium"
                  InputLabelProps={{shrink: true}}
                  placeholder='Select'
                  label={t('pages.createUserModal.maker')}
                  variant="standard"
                  margin="dense"
                />
              )}
              renderOption={(props, option, {selected}) => {
                const {key, ...optionProps} = props;
                return (
                  <li key={key} {...optionProps}>
                    <Checkbox
                      disabled={isReadOnly}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                      checkedIcon={<CheckBoxIcon fontSize="small"/>}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.NAME}
                  </li>
                );
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            disabled={isReadOnly}
            size="medium"
            options={roles}
            value={roles.find((role) => role.value === formData.role) || null}
            onChange={(_, newValue) => {
              setFormData((prevData) => ({
                ...prevData,
                role: newValue ? newValue.value : -1,
                privilege: newValue ? newValue.value === 1  ? 2 : 1 : prevData.privilege,
              }));
            }}
            renderInput={(params) => (
            <TextField
              {...params}
              required
              disabled={isReadOnly}
              size="medium"
              label={t('pages.createUserModal.role')}
              variant="standard"
              margin="dense"
              placeholder='Select'
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            disabled={isReadOnly || dialogMode === "edit" || roles.find((role) => role.value === formData.role)?.label.toLocaleLowerCase() === "admin"}
            options={privileges}
            getOptionLabel={(option) => t(`general.privileges.${option.label.toLowerCase()}`)}
            value={privileges.find((p) => p.value === formData.privilege) || null}
            onChange={(_, newValue) => {
              setFormData((prevData) => ({
                ...prevData,
                privilege: newValue ? newValue.value : -1,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                disabled={isReadOnly || dialogMode === "edit" || roles.find((role) => role.value === formData.role)?.label.toLocaleLowerCase() === "admin"}
                size="medium"
                label={t('pages.createUserModal.privilege')}
                variant="standard"
                placeholder={isMultipilePrivileges ? "Multiple" : "Select"}
                margin="dense"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Autocomplete
            disabled={isReadOnly}
            fullWidth
            options={salutations}
            getOptionLabel={(option) => t(`general.salutations.${option.label.toLowerCase()}`)}
            value={salutations.find((s) => s.value === formData.salutation) || null}
            onChange={(_, newValue) => {
              setFormData((prevData) => ({
                ...prevData,
                salutation: newValue?.value || 3,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                disabled={isReadOnly}
                size="medium"
                label={t('pages.createUserModal.salutation')}
                variant="standard"
                InputLabelProps={{shrink: true}}
                placeholder='Select'
                margin="dense"
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Autocomplete
            fullWidth
            disabled={isReadOnly}
            options={titles}
            getOptionLabel={(option) => t(`general.titles.${option.label.toLowerCase().replace(".", "")}`)}
            value={titles.find((t) => t.value === formData.title) || null}
            onChange={(_, newValue) => {
              setFormData((prevData) => ({
                ...prevData,
                title: newValue?.value || -1,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={isReadOnly}
                size="medium"
                label={t('pages.createUserModal.title')}
                variant="standard"
                InputLabelProps={{shrink: true}}
                placeholder='Select'
                margin="dense"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={isReadOnly}
            required
            InputLabelProps={{shrink: true}}
            placeholder={t('pages.createUserModal.firstName') || "First Name"}
            variant="standard"
            margin="dense"
            size="medium"
            label={t('pages.createUserModal.firstName')}
            fullWidth
            value={formData.firstName}
            onChange={(e) => handleInputChange('firstName', e.target.value)}
            error={validationErrors?.firstName || false}
            helperText={validationErrors?.firstName ? t('pages.createUserModal.validation.firstName') : ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            disabled={isReadOnly}
            InputLabelProps={{shrink: true}}
            placeholder={t('pages.createUserModal.lastName') || "Last Name"}
            variant="standard"
            margin="dense"
            size="medium"
            label={t('pages.createUserModal.lastName')}
            fullWidth
            value={formData.lastName}
            onChange={(e) => handleInputChange('lastName', e.target.value)}
            error={validationErrors?.lastName || false}
            helperText={validationErrors?.lastName ? t('pages.createUserModal.validation.lastName') : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={isReadOnly}
            required
            InputLabelProps={{shrink: true}}
            placeholder={t('pages.createUserModal.email') || "Email"}
            variant="standard"
            margin="dense"
            size="medium"
            label={t('pages.createUserModal.email')}
            fullWidth
            value={formData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            error={validationErrors?.email || false}
            helperText={
              validationErrors?.email
                ? `${t("pages.createUserModal.validation.email")}${
                  selectedCompany?.email_domain ? ` (${selectedCompany.email_domain}${selectedCompany.email_domain.includes("maven360") ? "" : ", maven360.io"})` : ""
                }`
                : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="medium"
            disabled={isReadOnly}
            label={t("pages.accountsManager.commentLabel")}
            fullWidth
            InputLabelProps={{shrink: true}}
            name={"comment"}
            multiline
            minRows={1}
            margin="normal"
            variant="standard"
            placeholder={t("pages.accountsManager.commentPlaceholder") || ""}
            value={formData.comment}
            onChange={(e) => handleInputChange('comment', e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <PasswordDialog
        isPasswordDialogOpen={isPasswordDialogOpen}
        handlePasswordDialogClose={handlePasswordDialogClose}
        formData={formData}
      />
      <DynamicDialog
        open={open}
        closeOnBackdropClick={isReadOnly}
        onClick={handleSave}
        onClose={handleClose}
        title={t(`pages.createUserModal.${dialogMode}Heading`)}
        content={content}
        isPrimaryBtnDisabled={!isFormPopulated() || hasValidationErrors || isLoading}
        {...(!isReadOnly
          ? {
              primaryButtonText: t("general.save") || "",
              secondaryButtonText: t("general.cancel") || ""
            }
          : {})}
      />
    </>
  );
};

export default ActionsDialog;

