import { useState, useEffect } from 'react';

export const useDebounce = <T>(value: T, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isFiltering, setIsFiltering] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false)
      return;
    }

    setIsFiltering(true);

    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsFiltering(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return { debouncedValue, isFiltering };
};
