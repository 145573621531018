import { FC, ReactNode, useState } from "react";
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import * as styles from "./styles";
import TimeRangePicker from "../TimeRangePicker/TimeRangePicker";
import { FilterList } from "@mui/icons-material";
import useData from "@/hooks/auth/useData";

interface ContentContainerProps {
    children: ReactNode;
    hasFilters?: boolean
}
/**
 * Container for pages. Use this to ensure right gapping
 *
 * @returns {JSX.Element} Container for pages content
 */
const ContentContainer: FC<ContentContainerProps> = ({ children, hasFilters }): JSX.Element => {
    const {openFilters, setOpenFilters} = useData()
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const toggleMore = () => {
        setOpenFilters(!openFilters);
    };

    return (
        <Box sx={styles.containerStyles} gap={theme.gap}>
            {isMobile && 
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <TimeRangePicker />
                    <Box sx={{...styles.moreButtonContainerStyles}}>
                        <Button onClick={toggleMore}>
                        <Box sx={styles.moreButtonStylesContent}>
                            <FilterList sx={{ height: 20, width: 20 }} /> 
                        </Box>
                        </Button>
                    </Box>
                </div>}
            {children}
        </Box>
    );
};

export default ContentContainer;