import {
  QueryFunctionContext,
  QueryKey,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { t } from "i18next";
import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { transformParameter } from "@/utils/transformParameter";

/**
 * Interface for query return type
 * @template TResponse - The type of the response from the query
 * @template TParams - The type of the parameters for the query (optional)
 */
export interface ApiQuery<TResponse, TParams = undefined> {
  isExecuted: boolean;
  execute: (variables?: TParams, cachingEnabled?: boolean,  preventExecutionOnLoad?: boolean) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  data: TResponse | undefined;
  showSkeletton: boolean;
}

/**
 * Interface for query parameters
 * @template TResponse - The type of the response from the query
 * @template TParams - The type of the parameters for the query (optional)
 */
interface Props<TResponse, TParams = undefined> {
  key: string;
  queryFn: (
    context: QueryFunctionContext<[QueryKey, TParams | undefined]>
  ) => Promise<TResponse>;
}

const nonAbortableRequests = ["me", "merchants", "manufacturers"];

/**
 * Custom hook to handle API queries using react-query
 * @template TResponse - The type of the response from the query
 * @template TParams - The type of the parameters for the query (optional)
 * @param {Props<TResponse, TParams>} props - The query parameters
 * @returns {ApiQuery<TResponse, TParams>} - The query state and execute function
 */
export const useApiQuery = <TResponse, TParams = undefined>({
  key,
  queryFn,
}: Props<TResponse, TParams>): ApiQuery<TResponse, TParams> => {
  const [errorMessage, setError] = useState<string | null>(null);
  const [data, setData] = useState<TResponse | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExecuted, setIsExecuted] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const location = useLocation();
  const currentPathRef = useRef(location.pathname);

  const [invalidQueries, setInvalidQueries] = useState<string[]>([]);
  const loadingRef = useRef<boolean>(false);
  const queryStringRef = useRef<string | null>(null);
  // for initial load
  const hasLoadedRef = useRef(false);

  const abortControllerRef = useRef<AbortController | null>(null);

  useLayoutEffect(() => {
    if (!hasLoadedRef.current) {
      hasLoadedRef.current = true;
      return; // Skip logic on first render
    }

    if (currentPathRef.current !== location.pathname) {
      currentPathRef.current = location.pathname;
      if(!loadingRef.current) {
        setIsExecuted(false);
      }
    }
   
    return () => {
      if (abortControllerRef.current && !nonAbortableRequests.includes(key)) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
        queryClient.invalidateQueries({ queryKey: [key, queryStringRef.current] });
        setInvalidQueries((prev) => {
          if (typeof queryStringRef.current === "string" && !prev.includes(queryStringRef.current)) {
            return [...prev, queryStringRef.current];
          }
          return prev;
        });
        
        setIsLoading(false);
        loadingRef.current = false;
      }
    };
  }, [location.pathname]);

  /**
   * Executes the query and handles the loading state and errors
   * @param {TParams} newParams - The query parameters
   * @param {boolean} cachingEnabled - Whether caching should be enabled
   * @param {boolean} preventExecutionOnLoad - If loads, do not execute
   */
  const execute = async (
    newParams?: TParams,
    cachingEnabled: boolean = true,
    preventExecutionOnLoad: boolean = false,
  ) => {
    if(preventExecutionOnLoad && loadingRef.current) return;

    setIsLoading(true);
    loadingRef.current = true;
    setIsExecuted(true);

    try {
      const params = new URLSearchParams();
      Object.entries({ ...newParams }).forEach(([k, value]) => {
        if (Array.isArray(value)) {
          value.forEach((val: unknown) => {
            if (val !== null && val !== undefined) {
              params.append(transformParameter(k), String(val));
            }
          });
        } else if (value !== null && value !== undefined) {
          params.append(transformParameter(k), String(value));
        }
      });
      const queryString = params.toString();

      if(queryString === queryStringRef.current && abortControllerRef.current) {
        return
      }
      queryStringRef.current = queryString;

      if (abortControllerRef.current && !nonAbortableRequests.includes(key)) {
        abortControllerRef.current.abort();
      }
  
      abortControllerRef.current = new AbortController();

      let queryData;
      if(invalidQueries.includes(queryString)) {
        await queryClient.refetchQueries({ queryKey: [key, queryStringRef.current] });
        queryData = queryClient.getQueryData<TResponse>([key, queryStringRef.current]);
      } else {
        queryData = await queryClient.fetchQuery<TResponse>({
          queryKey: [key, queryString],
          queryFn: (context) =>
            queryFn({
              ...context,
              signal: abortControllerRef.current?.signal,
            } as QueryFunctionContext<[QueryKey, TParams | undefined]>),
          retry: 0,
  
          ...(cachingEnabled ? {} : { staleTime: 0 }),
        });
      }
      setError(null);
      setData(queryData);
    } catch (error) {
      setError(`${t("errors.globalError")}: ${(error as AxiosError)?.message}`);
      setData(undefined);
    } finally {
      if(queryClient.isFetching({ queryKey: [key] }) === 0) {
        setIsLoading(false);
        loadingRef.current = false;
      }
      abortControllerRef.current = null;
      
    }
  };

  return {
    execute,
    isExecuted,
    isLoading,
    isError: !!errorMessage,
    error: errorMessage,
    data,
    showSkeletton: isLoading || !isExecuted,
  };
};
