import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import {t} from "i18next";
import React, {useEffect, useMemo, useState} from "react";
import MuiGridTable from "@/components/tables/DataGridTable";
import {
  AccountsTreeResponse,
  ModulesAccessTreeResponse,
  PrivilegeResponse
} from "@/api/management/types";
import {GridColDef} from "@mui/x-data-grid-pro";
import {useColumns} from "@/hooks/useColumnsData";
import useData from "@/hooks/auth/useData";
import {Box} from "@mui/system";
import DynamicDialog from "@/components/DynamicDialog";

interface AccountsAccessModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  row: AccountsTreeResponse | null;
  listApi: any;
}

interface ModulesAccessTreeResponseWithEdited extends ModulesAccessTreeResponse {
  edited: boolean;
}

const AccountsAccessModal = ({
  open,
  onClose,
  onSuccess,
  row,
  listApi
}: AccountsAccessModalProps) => {
  const allColumns = useColumns();
  const {modulesAccessTreeApi, editModulesAccessApi} = useData();
  const [maker, setMaker] = useState(0);
  const [accountId, setAccountId] = useState(0);

  useEffect(() => {
    if (open && row?.manufacturerId && row?.accountId) {
      setMaker(row.manufacturerId)
      setAccountId(row.accountId)
      modulesAccessTreeApi.execute({m: row.manufacturerId, account_id: row.accountId});
    }
  }, [open]);

  const initializeRows = (data: any[]): ModulesAccessTreeResponseWithEdited[] => {
    return (data || [])
      .sort((a, b) => a.hierarchy.length - b.hierarchy.length) // Sort rows by hierarchy length
      .map((item: any) => {
        // Check if we need to load info from parent row
        if (item.selected_privilege === null || item.selected_contracts.length === 0) {
          // Find parent row in data to copy a value from
          const parentRow = (data || []).find(
            (row) => row.hierarchy.join(',') === item.hierarchy.slice(0, -1).join(',')
          );

          if (parentRow) {
            return {
              ...item,
              selected_privilege: item.selected_privilege ?? parentRow.selected_privilege,
              selected_contracts: item.selected_contracts.length > 0
                ? item.selected_contracts
                : parentRow.selected_contracts,
              edited: false,
            };
          }
        }

        return { ...item, edited: false };
      });
  };

  const [rows, setRows] = useState<ModulesAccessTreeResponseWithEdited[]>(() =>
    initializeRows(modulesAccessTreeApi.data || [])
  );

  useEffect(() => {
    setRows(initializeRows(modulesAccessTreeApi.data || []));
  }, [modulesAccessTreeApi.data]);

  const combineAndUnique = (uniqueValues: Set<string>): any[] => {
    const combined = Array.from(uniqueValues).flatMap((value) =>
      JSON.parse(String(value))
    );
    return Array.from(new Set(combined));
  };

  const getNeighbors = (rows: any[], targetRow: any): any[] => {
    const hierarchyLength = targetRow.hierarchy.length;

    return rows.filter((row) =>
      row.hierarchy.length === hierarchyLength &&
      row.hierarchy.length > 1 && // Exclude parent nodes
      row.hierarchy.slice(0, -1).join(',') === targetRow.hierarchy.slice(0, -1).join(',')
    );
  };

  const getParentNode = (rows: any[], targetRow: any): any | null => {
    const hierarchyLength = targetRow.hierarchy.length;

    return rows.find(
      (row) =>
        row.hierarchy.join(',') ===
        targetRow.hierarchy.slice(0, hierarchyLength - 1).join(',')
    ) || null;
  };

  const handleDropdownChange = (values: any, key: string, params: any) => {
    const targetRow = rows.find((row) => row.id === params.id);
    if (!targetRow) return;
    const targetHierarchy = targetRow.hierarchy.join(',');
    const hierarchyLength = targetRow.hierarchy.length;
    const multiplePrivilege = targetRow.privileges.find((privilege) => privilege.PRIVILEGE_ID === 5)
    const neighbors = getNeighbors(rows, targetRow);
    const parentNode = getParentNode(rows, targetRow);

    const updatedRows = rows.map((row) => {
      if (row.id === params.id) {
        return { ...row, [key]: values, edited: true };
      }

      // Update children in the same hierarchy
      if (row.hierarchy.slice(0, hierarchyLength).join(',') === targetHierarchy) {
        return {
          ...row,
          [key]: values,
          edited: true
        };
      }

      return row;
    });

    const uniqueValues = new Set(
      neighbors
        .map((row) => {
          const value = row[key];
          return typeof value === 'object' && value !== null ? JSON.stringify(value) : value;
        })
    );

    // determine depended on the children, what to show in the parent
    // if more than one value, show multiple or combination of all contracts
    // if only one value, show this value in the parent
    if (uniqueValues.size > 1) {
      if (key === 'selected_privilege') {
        parentNode.selected_privilege = multiplePrivilege;
      } else if (key === 'selected_contracts') {
        // we store here the combination of all used contracts
        parentNode.selected_contracts = combineAndUnique(uniqueValues as Set<string>);
      }
      parentNode.edited = true;
    } else if (uniqueValues.size === 1){
      const singleValue = JSON.parse(String(Array.from(uniqueValues)[0]));
      if (key === 'selected_privilege') {
        parentNode.selected_privilege = singleValue;
      } else if (key === 'selected_contracts') {
        parentNode.selected_contracts = singleValue;
      }
      parentNode.edited = true;
    }
    setRows(updatedRows);
  };

  const handleSubmit = async () => {
    const editedRows = rows.filter((row) => row.edited)
    const requestRows = editedRows.map((row) => ({
      account_id: accountId,
      manufacturer_id: maker,
      module_id: row.module_id,
      privilege_id: row.selected_privilege?.PRIVILEGE_ID,
      contract_ids: row.selected_contracts,
    }));

    await Promise.all(
      requestRows.map((requestRow) => editModulesAccessApi.execute(requestRow)
      )
    );
      // Once all API calls are complete, update rows to set `edited` to false
    const updatedRows = rows.map((row) => ({ ...row, edited: false }));
    setRows(updatedRows);
    // The tree response is more complicated to simple add it as it needs a tree form.
    listApi.execute(undefined, false);
  }

  const groupingModulesColDef = {
    headerName: t('tableColumns.module') || 'Module',
    hideDescendantCount: true,
    headerClassName: "grouping-header",
    field: "name",
    flex: 1,
    valueGetter: allColumns.createValueGetter("module", false, 0, false),
  };

  const columns: Array<GridColDef> = [
    {
      ...allColumns.createMultiSelectDropdownColumn(handleDropdownChange, 'contracts', 'selected_contracts'),
      field: "contracts",
      headerName: t("tableColumns.contract") || "Contract",
      valueGetter: (_: any, row: Record<string, any>) => {
        return row.contracts
      },
      width: 180
    },
    {
      ...allColumns.createDropdownColumn(handleDropdownChange, 'privileges', 'selected_privilege', 'PRIVILEGE', 'PRIVILEGE_ID', (value) => t(`general.privileges.${value}`), [5], 4),
      field: "privilege",
      headerName: t("tableColumns.privilege") || "Privilege",
      valueGetter: (_: any, row: Record<string, any>) => {
        return t(`general.privileges.${row.selected_privilege}`);
      },
      width: 120,
    }
  ]

  const content =  (
    <Box>
        <Typography variant="subtitle1">
          <Typography component="span" fontWeight="bold">
            {t('pages.manageAccounts.editAccess.maker')}:
          </Typography>{' '}
          {row?.makerPermission}
        </Typography>
        <MuiGridTable
          id="accounts-access"
          getRowClassName={(params) => {
            if (params.row.contracts && params.row.contracts.length == 0) return 'Mui-disabled-row';
            return params.row.hierarchy && params.row.hierarchy.length > 1 ? 'gray-50-row': '';
          }}
          filterBarType={2}
          rows={rows}
          initialPageSize={10}
          columns={columns}
          loading={modulesAccessTreeApi.showSkeletton}
          hasExport={false}
          getTreeDataPath={(row) => row.hierarchy || []}
          treeData
          groupingColDef={groupingModulesColDef}
          searchPlaceholder={t('pages.manageAccounts.editAccess.searchPlaceholder') || 'Module...'}
        />
      </Box>
  )

  return <DynamicDialog
      open={open}
      onClose={onClose}
      onClick={handleSubmit}
      onSuccess={onSuccess}
      errorText={t("pages.manageAccounts.editAccess.error") as string}
      isLoading={editModulesAccessApi.isLoading}
      closeOnBackdropClick={true}
      title={t("pages.manageAccounts.editAccess.title")}
      content={content}
      primaryButtonText={t("general.edit") as string}
      showSuccessOutside={true}
    />
}

export default AccountsAccessModal;
