import { Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions,  Grid, Button, TextField, Alert, FormControl, Checkbox } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { localeDateFormat, localeMapping } from '../../components/TimeRangePicker/constants';
import { useTranslation } from 'react-i18next';
import useData from "@/hooks/auth/useData";
import { useState, useMemo, useEffect } from 'react';
import {
  AccountResponse,
  AccountsUserResponse,
  ContractsResponse,
  CreateContractRequest,
  ModulesResponse
} from "@/api/management/types";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { UserResponse } from "@/types/user";
import { 
  createBillingCycleOptions, 
  createNoticePeriodOptions, 
  createMinTermOptions 
} from './types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface CreateContractModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  listApi: any;
  accounts: AccountResponse[];
  modules: ModulesResponse[];
  manufacturers: any[];
  accountExecutives: UserResponse[];
  type: string;
  initialData: ContractsResponse | null;
}

interface ModuleEntry {
  moduleId: string;
  manufacturerId: string;
  quantity: string;
}

const createModuleEntries = (
  module_ids?: number[],
  maker_ids?: number[],
  quantities?: number[]
): ModuleEntry[] => {
  // Check if all arrays exist and have the same length
  if (
    !module_ids ||
    !maker_ids ||
    !quantities ||
    module_ids.length !== maker_ids.length ||
    module_ids.length !== quantities.length
  ) {
    return []; // Return empty array if validation fails
  }

  const combinedEntries: Record<string, { moduleIds: string[]; manufacturerId: string; quantity: string }> = {};

  module_ids.forEach((moduleId, index) => {
    const manufacturerId = maker_ids[index] === -1 ? '' : maker_ids[index].toString();  // empty maker
    const quantity = quantities[index].toString();
    const key = `${manufacturerId}-${quantity}`; // Unique key for grouping

    if (!combinedEntries[key]) {
      combinedEntries[key] = {
        moduleIds: [],
        manufacturerId,
        quantity,
      };
    }
    combinedEntries[key].moduleIds.push(moduleId.toString());
  });

  // Create ModuleEntry objects for each index
  return Object.values(combinedEntries).map(({ moduleIds, manufacturerId, quantity }) => ({
    moduleId: moduleIds.join(','), // Join moduleIds with a comma
    manufacturerId,
    quantity,
  }));
};

const CreateContractModal = ({ 
  open, 
  onClose, 
  onSuccess, 
  listApi,
  accounts,
  modules,
  manufacturers,
  accountExecutives,
  type,
  initialData
}: CreateContractModalProps) => {
  const { t } = useTranslation();
  const { user, createContractApi, editContractApi, accountsUserApi } = useData();
  const [contractId, setContractId] = useState<string | null>(null);
  const [company, setCompany] = useState<string | null>(null);
  const [minTerm, setMinTerm] = useState<string | null>(null);
  const [noticePeriod, setNoticePeriod] = useState<string | null>(null);
  const [billingCycle, setBillingCycle] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [comment, setComment] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [moduleEntries, setModuleEntries] = useState<ModuleEntry[]>([{ 
    moduleId: '', 
    manufacturerId: '', 
    quantity: '' 
  }]);
  const [closingDate, setClosingDate] = useState<Dayjs | null>(null);
  const [firstAccessDate, setFirstAccessDate] = useState<Dayjs | null>(null);
  const [automaticRenewal, setAutomaticRenewal] = useState(true);
  const [paymentDue, setPaymentDue] = useState<string | null>(null);
  const [selectedAccountExecutive, setSelectedAccountExecutive] = useState<UserResponse | null>(null);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [poNumber, setPoNumber] = useState<string | null>(null);
  const [procurementEmail, setProcurementEmail] = useState<string | null>(null);
  const [billingEmail, setBillingEmail] = useState<string | null>(null);
  const [primaryContact, setPrimaryContact] = useState<AccountsUserResponse | null>(null);
  const [primaryContacts, setPrimaryContacts] = useState<AccountsUserResponse[]>([]);

  const billingCycleOptions = useMemo(() => createBillingCycleOptions(t), [t]);
  const noticePeriodOptions = useMemo(() => createNoticePeriodOptions(t), [t]);
  const minTermOptions = useMemo(() => createMinTermOptions(t), [t]);

  const numberFormat = user?.settings?.numberFormat;
  const userLocale = numberFormat && numberFormat in localeMapping
    ? localeMapping[numberFormat as keyof typeof localeMapping]
    : 'en';
  const userDateFormat = numberFormat && numberFormat in localeDateFormat
    ? localeDateFormat[numberFormat as keyof typeof localeDateFormat]
    : 'YYYY/MM/DD';
  const isReadOnly = type === "overview";

  useEffect(() => {
    if ((type === 'edit' || type === 'overview') && initialData) {
      setContractId(initialData.contract_id || null);
      setCompany(String(initialData.account_id || null));
      setPoNumber(initialData?.po_number || null);
      setBillingEmail(initialData?.billing_email || null);
      setProcurementEmail(initialData?.procurement_email || null);
      setMinTerm(String(initialData.term || null));
      setNoticePeriod(String(initialData.notice || null));
      setBillingCycle(String(initialData.cycle || null));
      setStartDate(initialData.start ? dayjs.utc(initialData.start) : null);
      setClosingDate(initialData.closing_date ? dayjs.utc(initialData.closing_date) : null);
      setFirstAccessDate(initialData.access_date ? dayjs.utc(initialData.access_date) : null);
      setAutomaticRenewal(Boolean(initialData.automatic_renewal));
      setPaymentDue(String(initialData.payment_due || null));
      setComment(initialData.comment || null);
      setPrimaryContact(
        primaryContacts.find(ae => ae.user_id === initialData.primary_contact_id) || null
      );
      setSelectedAccountExecutive(
        accountExecutives.find(ae => ae.user_id === initialData.executive_id) || null
      );
      setModuleEntries(createModuleEntries(initialData.module_ids, initialData.maker_ids, initialData.quantities) || []);
    }
  }, [initialData, open]);

  useEffect(() => {
    if ((type === 'edit' || type === 'overview') && initialData) {
      setPrimaryContact(
        primaryContacts.find(ae => ae.user_id === initialData.primary_contact_id) || null
      );
    }
  }, [initialData, open, primaryContacts]);

  useEffect(() => {
    if (company) {
      setPrimaryContact(null);
      accountsUserApi.execute({ account_ids: [Number(company)] });
    }
  }, [company]);

  useEffect(() => {
    setPrimaryContacts(accountsUserApi?.data || []);
  }, [accountsUserApi.data]);

  const handleClose = () => {
    setContractId(null);
    setCompany(null);
    setMinTerm(null);
    setNoticePeriod(null);
    setBillingCycle(null);
    setStartDate(null);
    setComment(null);
    setError(null);
    setTouched({});
    setWasSubmitted(false);
    setModuleEntries([{ moduleId: '', manufacturerId: '', quantity: '' }]);
    setClosingDate(null);
    setFirstAccessDate(null);
    setAutomaticRenewal(true);
    setPaymentDue(null);
    setSelectedAccountExecutive(null);
    setPoNumber(null);
    setProcurementEmail(null);
    setBillingEmail(null);
    setPrimaryContact(null);
    onClose();
  };

  /**
   * Validates if a contract ID contains a valid date in its first 6 digits
   * 
   * The first 6 digits of the contract ID represent a date in the format YYMMDD where:
   * - YY is the last 2 digits of the year (20XX)
   * - MM is the month (01-12)
   * - DD is the day (01-31)
   * 
   * @param contractId - The contract ID string to validate
   * @returns boolean - True if the first 6 digits form a valid date, false otherwise
   */
  const isValidContractDate = (contractId: string | null): boolean => {
    return contractId ? (contractId.length >= 4 && contractId.length <= 8) : false;

    /* Keeping it. Might be used in the future again
    const dateStr = contractId.substring(0, 6);
    const year = parseInt(dateStr.substring(0, 2)) + 2000;
    const month = parseInt(dateStr.substring(2, 4)) - 1;
    const day = parseInt(dateStr.substring(4, 6));
    
    const date = new Date(year, month, day);
    
    return date instanceof Date && 
           !isNaN(date.getTime()) && 
           date.getFullYear() - 2000 === parseInt(dateStr.substring(0, 2)) &&
           date.getMonth() + 1 === parseInt(dateStr.substring(2, 4)) &&
           date.getDate() === parseInt(dateStr.substring(4, 6));
     */
  };

  const isFieldEmpty = (value: any): boolean => {
    return value === null || value === undefined || value === '';
  };

  const getFieldError = (fieldName: string, value: any): boolean => {
    // Exclude fields that can be null from error checking
    const nullableFields = ['poNumber', 'accountExecutive', 'primaryContact'];
    if (nullableFields.includes(fieldName)) {
      return false;
    }
    const emailFields = ['billingEmail', 'procurementEmail']
    if (emailFields.includes(fieldName)) {
      return ((touched[fieldName] || wasSubmitted) && (!isFieldEmpty(value) && !validateEmail(value)))
    }

    if (fieldName === 'contractId') {
      return (touched[fieldName] || wasSubmitted) && (isFieldEmpty(value) || !isValidContractDate(value));
    }
    return (touched[fieldName] || wasSubmitted) && isFieldEmpty(value);
  };

  const handleSubmit = async () => {
    try {
      setError(null);
      setWasSubmitted(true);

      // Validate contract ID format first
      if (isNaN(Number(contractId)) || !isValidContractDate(contractId)) {
        setError(t('pages.contracts.create.alerts.invalidContractId'));
        return;
      }

      // Mark required fields as touched on submit
      const allFields = {
        contractId: true,
        company: true,
        closingDate: true,
        firstAccessDate: true,
        startDate: true,
        minTerm: true,
        noticePeriod: true,
        billingCycle: true,
        paymentDue: true,
      };
      setTouched(allFields);

      // Check all required fields without considering touched state
      if (
        isFieldEmpty(contractId) ||
        isFieldEmpty(company) ||
        isFieldEmpty(closingDate) ||
        isFieldEmpty(firstAccessDate) ||
        isFieldEmpty(startDate) ||
        isFieldEmpty(minTerm) ||
        isFieldEmpty(noticePeriod) ||
        isFieldEmpty(billingCycle) ||
        isFieldEmpty(paymentDue) ||
        moduleEntries.length === 0 ||
        moduleEntries.some(entry => 
          isFieldEmpty(entry.moduleId) || 
          isFieldEmpty(entry.manufacturerId) || 
          isFieldEmpty(entry.quantity)
        )
      ) {
        setError(t('pages.contracts.create.alerts.requiredFields'));
        return;
      }

      // Adjust dates to unix utc
      const startDateUnix = startDate ? startDate.utc().valueOf() : null;
      const closingDateUnix = closingDate ? closingDate.utc().valueOf() : null;
      const firstAccessDateUnix = firstAccessDate ? firstAccessDate.utc().valueOf() : null;

      const request: CreateContractRequest = {
        contract_id: contractId || '',
        account_id: Number(company),
        primary_contact: primaryContact?.user_id ?? null,
        start: startDateUnix,
        end: null,
        term: Number(minTerm),
        notice: Number(noticePeriod),
        cycle: Number(billingCycle),
        status: 1,
        closing_date: closingDateUnix,
        access_date: firstAccessDateUnix,
        automatic_renewal: automaticRenewal ? 1 : 0,
        payment_due: Number(paymentDue),
        executive: selectedAccountExecutive?.user_id ?? null,
        comment: comment || null,
        po_number: poNumber || null,
        procurement_email: procurementEmail || null,
        billing_email: billingEmail || null,
        modules: moduleEntries
          .filter(entry => !(Number(entry.moduleId) === 0 && Number(entry.manufacturerId) === 0 && Number(entry.quantity) === 0))
          .flatMap((entry) => {
            const moduleIds = entry.moduleId.split(',').map(id => id.trim()).filter((id) => id)
            return moduleIds.map(moduleId => ({
              am_id: Number(moduleId),
              manufacturer: Number(entry.manufacturerId),
              quantity: Number(entry.quantity)
            }));
          })
      }
      // Add your contract creation / edit API call here
      if (type == 'create') {
        await createContractApi.execute(request);
      } else if (type === 'edit') {
        await editContractApi.execute(request);
      } else {
        console.log('Invalid type provided. Expected "create" or "edit", but got:', type);
      }

      // The tree response is more complicated to simple add it as it needs a tree form.
      listApi.execute(undefined, false);
      onSuccess();
      handleClose();
    } catch (error) {
      if (type === 'create') {
        setError(t('pages.contracts.create.alerts.createFailed'));
      } else if (type === 'edit') {
        setError(t('pages.contracts.create.alerts.editFailed'));
      }
    }
  };

  const handleAddModule = () => {
    setModuleEntries([...moduleEntries, { 
      moduleId: '', 
      manufacturerId: '', 
      quantity: '' 
    }]);
  };

  const handleRemoveModule = (index: number) => {
    setModuleEntries(moduleEntries.filter((_, i) => i !== index));
  };

  const handleModuleChange = (index: number, field: keyof ModuleEntry, value: string) => {
    const newEntries = [...moduleEntries];
    newEntries[index] = { ...newEntries[index], [field]: value };
    setModuleEntries(newEntries);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isFormValid = (): boolean => {
    return (
      !isFieldEmpty(contractId) &&
      !isFieldEmpty(company) &&
      !isFieldEmpty(closingDate) &&
      !isFieldEmpty(firstAccessDate) &&
      !isFieldEmpty(startDate) &&
      !isFieldEmpty(minTerm) &&
      !isFieldEmpty(noticePeriod) &&
      !isFieldEmpty(billingCycle) &&
      !isFieldEmpty(paymentDue) &&
      (isFieldEmpty(procurementEmail) || validateEmail(procurementEmail as string)) &&
      (isFieldEmpty(billingEmail) || validateEmail(billingEmail as string)) &&
      moduleEntries.length > 0 &&
      moduleEntries.every(entry => 
        !isFieldEmpty(entry.moduleId) && 
        !isFieldEmpty(entry.manufacturerId) && 
        !isFieldEmpty(entry.quantity)
      )
    );
  };

  return (
    <Dialog 
      open={open} 
      maxWidth="sm" 
      fullWidth 
      sx={{ '& .MuiDialog-paper': { maxWidth: '630px' } }}
      disableEscapeKeyDown={true}
      onClose={(event: object, reason: string) => {
        if (type === 'overview' || reason !== 'backdropClick') {
          handleClose();
        }
      }}
    >
    <DialogTitle>
      {
      (() => {
        switch (type) {
          case 'create':
            return t('pages.contracts.create.createTitle');
          case 'edit':
            return t('pages.contracts.create.editTitle');
          case 'overview':
            return t('pages.contracts.create.overviewTitle');
          default:
            return t('pages.contracts.create.defaultTitle'); // Optional fallback
        }
      })()
    }
    </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {/* First Row */}
          <Grid item xs={12} md={2.4}>
            <TextField
              disabled={isReadOnly}
              margin="dense"
              size="small"
              label={t('tableColumns.contractId')}
              fullWidth
              required
              variant="standard"
              type="text"
              placeholder="ID"
              InputLabelProps={{shrink: true}}
              error={getFieldError('contractId', contractId)}
              value={contractId}
              onChange={(e) => setContractId(e.target.value)}
              onBlur={() => setTouched(prev => ({ ...prev, contractId: true }))}
            />
          </Grid>
          <Grid item xs={12} md={4.8}>
            <Autocomplete
              size="small"
              disabled={isReadOnly}
              options={accounts}
              openOnFocus={true}
              getOptionLabel={(option) => option.name || option.abbreviation}
              value={accounts.find((acc) => String(acc.account_id) === company) || null}
              onChange={(_, newValue) => setCompany(newValue ? String(newValue.account_id) : '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={isReadOnly}
                  value={company || ''}
                  margin="dense"
                  label={t('tableColumns.account')}
                  required
                  error={getFieldError('company', company)}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  placeholder={t('pages.contracts.create.accountPlaceholder') || 'Company name'}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4.8}>
            <Autocomplete
              size="small"
              disabled={isReadOnly}
              options={primaryContacts}
              getOptionLabel={(option) =>
                option.first_name && option.last_name
                  ? `${option.first_name} ${option.last_name}`
                  : `${option.username}`
              }
              value={primaryContact}
              onChange={(_, newValue) => setPrimaryContact(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={isReadOnly}
                  margin="dense"
                  label={t('tableColumns.primaryContact')}
                  variant="standard"
                  placeholder={t('pages.contracts.create.primaryContactPlaceholder') || 'Primary Contact'}
                  error={getFieldError('primaryContact', primaryContact)}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          {/* Second Row */}
            <Grid item xs={12} md={2.4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={userLocale}>
                <DatePicker
                  label={t('tableColumns.closingDate')}
                  disabled={isReadOnly}
                  value={closingDate?.local() || null}
                  onChange={(newValue) => setClosingDate(newValue)}
                  format={userDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      margin: "dense",
                      fullWidth: true,
                      required: true,
                      variant: "standard",
                      InputLabelProps: {shrink: true},
                      error: getFieldError('closingDate', closingDate),
                      onBlur: () => setTouched(prev => ({ ...prev, closingDate: true }))
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={userLocale}>
                <DatePicker
                  label={t('tableColumns.firstAccessDate')}
                  disabled={isReadOnly}
                  value={firstAccessDate?.local() || null}
                  onChange={(newValue) => setFirstAccessDate(newValue)}
                  format={userDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      margin: "dense",
                      fullWidth: true,
                      required: true,
                      variant: "standard",
                      InputLabelProps: {shrink: true},
                      error: getFieldError('firstAccessDate', firstAccessDate),
                      onBlur: () => setTouched(prev => ({ ...prev, firstAccessDate: true }))
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={userLocale}>
                <DatePicker
                  label={t('tableColumns.startDate')}
                  disabled={isReadOnly}
                  value={startDate?.local() || null}
                  onChange={(newValue) => setStartDate(newValue)}
                  format={userDateFormat}
                  slotProps={{
                    textField: {
                      size: "small",
                      margin: "dense",
                      fullWidth: true,
                      required: true,
                      variant: "standard",
                      InputLabelProps: {shrink: true},
                      error: getFieldError('startDate', startDate),
                      onBlur: () => setTouched(prev => ({ ...prev, startDate: true }))
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4.8}>
              <Autocomplete
                size="small"
                disabled={isReadOnly}
                options={accountExecutives.map(ae => ({
                  value: ae.user_id?.toString(),
                  key: ae.user_id?.toString(),
                  label: ae.first_name + " " + ae.last_name
                }))}
                value={selectedAccountExecutive ? {
                  value: selectedAccountExecutive.user_id?.toString(),
                  key: selectedAccountExecutive.user_id?.toString(),
                  label: selectedAccountExecutive.first_name + " " + selectedAccountExecutive.last_name
                } : null}
                onChange={(_, newValue) => setSelectedAccountExecutive(
                  accountExecutives.find(ae => ae.user_id?.toString() === newValue?.value) || null
                )}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={isReadOnly}
                    margin="dense"
                    label={t('tableColumns.accountExecutive')}
                    variant="standard"
                    error={getFieldError('accountExecutive', selectedAccountExecutive)}
                    InputLabelProps={{shrink: true}}
                    placeholder={t('pages.contracts.create.whoClosed') || 'Who has closed the deal?'}
                  />
                )}
              />
            </Grid>

          {/* New Row for PO Number, Procurement Email, and Billing Email */}
          <Grid item xs={12} md={2.4}>
            <TextField
              variant="standard"
              disabled={isReadOnly}
              margin="dense"
              size="small"
              label={t('tableColumns.poNumber')}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              value={poNumber || ''}
              onChange={(e) => setPoNumber(e.target.value)}
              error={getFieldError('poNumber', poNumber)}
            />
          </Grid>
          <Grid item xs={12} md={4.8}>
            <TextField
              variant="standard"
              disabled={isReadOnly}
              margin="dense"
              size="small"
              label={t('tableColumns.procurementEmail')}
              type="email"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              placeholder={t('pages.contracts.create.procurementEmailPlaceholder') || 'Procurement Email'}
              value={procurementEmail}
              onChange={(e) => setProcurementEmail(e.target.value)}
              error={getFieldError('procurementEmail', procurementEmail)}
              onBlur={() => setTouched(prev => ({ ...prev, procurementEmail: true }))}
            />
          </Grid>
          <Grid item xs={12} md={4.8}>
            <TextField
              variant="standard"
              disabled={isReadOnly}
              margin="dense"
              size="small"
              label={t('tableColumns.billingEmail')}
              type="email"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              placeholder={t('pages.contracts.create.billingEmailPlaceholder') || 'Billing Email'}
              value={billingEmail}
              onChange={(e) => setBillingEmail(e.target.value)}
              error={getFieldError('billingEmail', billingEmail)}
              onBlur={() => setTouched(prev => ({ ...prev, billingEmail: true }))}
            />
          </Grid>

          {/* Third Row */}

            <Grid item xs={12} md={2.4}>
              <Autocomplete
                size="small"
                disabled={isReadOnly}
                options={[
                  { value: 'true', label: t('tableColumns.yes') },
                  { value: 'false', label: t('tableColumns.no') }
                ]}
                value={automaticRenewal ? 
                  { value: 'true', label: t('tableColumns.yes') } :
                  { value: 'false', label: t('tableColumns.no') }
                }
                onChange={(_, newValue) => setAutomaticRenewal(newValue?.value === 'true')}
                getOptionLabel={(option) => option.label}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={isReadOnly}
                    margin="dense"
                    required
                    label={t('tableColumns.automaticRenewal')}
                    variant="standard"
                    InputLabelProps={{shrink: true}}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <Autocomplete
                size="small"
                options={minTermOptions}
                disabled={isReadOnly}
                value={minTerm ? minTermOptions.find(opt => opt.value.toString() === minTerm) : null}
                onChange={(_, newValue) => setMinTerm(newValue ? newValue.value.toString() : '')}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={isReadOnly}
                    margin="dense"
                    label={t('tableColumns.term')}
                    variant="standard"
                    required
                    error={getFieldError('minTerm', minTerm)}
                    InputLabelProps={{shrink: true}}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <Autocomplete
                size="small"
                options={noticePeriodOptions}
                disabled={isReadOnly}
                value={noticePeriod ? noticePeriodOptions.find(opt => opt.value.toString() === noticePeriod) : null}
                onChange={(_, newValue) => setNoticePeriod(newValue ? newValue.value.toString() : '')}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={isReadOnly}
                    margin="dense"
                    error={getFieldError('noticePeriod', noticePeriod)}
                    label={t('tableColumns.noticePeriod')}
                    variant="standard"
                    required
                    InputLabelProps={{shrink: true}}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <Autocomplete
                size="small"
                disabled={isReadOnly}
                options={billingCycleOptions}
                value={billingCycle ? billingCycleOptions.find(opt => opt.value.toString() === billingCycle) : null}
                onChange={(_, newValue) => setBillingCycle(newValue ? newValue.value.toString() : '')}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={isReadOnly}
                    margin="dense"
                    error={getFieldError('billingCycle', billingCycle)}
                    label={t('tableColumns.billingCycle')}
                    variant="standard"
                    required
                    InputLabelProps={{shrink: true}}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <TextField
                disabled={isReadOnly}
                variant="standard"
                margin="dense"
                size="small"
                label={t('tableColumns.paymentDue')}
                type="number"
                fullWidth
                required
                InputLabelProps={{
                  shrink: true
                }}
                placeholder={t('pages.contracts.create.paymentDuePlaceholder') || 'In days'}
                value={paymentDue}
                onChange={(e) => setPaymentDue(e.target.value)}
                error={getFieldError('paymentDue', paymentDue)}
                onBlur={() => setTouched(prev => ({ ...prev, paymentDue: true }))}
              />
            </Grid>



          {/* Module Entries */}
          {moduleEntries.map((entry, index) => (
            <Grid container item spacing={2} key={index}>
              <Grid item xs={12} md={4.8}>
                <FormControl fullWidth margin="dense" size="small">
                  <Autocomplete
                    size="small"
                    disabled={isReadOnly}
                    options={manufacturers.map(manufacturer => ({
                      value: manufacturer.ID.toString(),
                      label: manufacturer.NAME
                    }))}
                    value={manufacturers.find(m => String(m.ID) === entry.manufacturerId) ? {
                      value: entry.manufacturerId.toString(),
                      label: manufacturers.find(m => String(m.ID) === entry.manufacturerId)?.NAME || ""
                    } : null}
                    onChange={(_, newValue) => handleModuleChange(
                      index,
                      'manufacturerId', 
                      newValue?.value || null
                    )}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isReadOnly}
                        label={t('filters.manufacturer')}
                        variant="standard"
                        required
                        placeholder={t('pages.contracts.create.makerPlaceholder') || 'Assign maker'}
                        error={getFieldError(`manufacturerId_${index}`, entry.manufacturerId)}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4.8}>
                <FormControl fullWidth margin="dense" size="small">
                  <Autocomplete
                    disabled={isReadOnly}
                    multiple
                    disableCloseOnSelect
                    size="small"
                    options={modules.map(module => ({
                      value: module.am_id.toString(),
                      label: module.module,
                    }))}
                    value={modules
                      .filter(m => entry.moduleId.split(',').includes(m.am_id.toString()))
                      .map(m => ({
                        value: m.am_id.toString(),
                        label: m.module,
                      }))}
                    onChange={(_, newValues) => {
                      const selectedValues = newValues.map(value => value.value);
                      handleModuleChange(index, 'moduleId', selectedValues.join(','));
                    }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => {
                      const isSelected = entry.moduleId.split(',').includes(option.value);
                      return (
                        <li
                          {...props}
                          style={{ margin: 0, padding: '0px 0px', cursor: 'pointer' }}
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent default selection behavior
                            const currentValues = entry.moduleId.split(',');
                            let updatedValues;
                            if (isSelected) {
                              updatedValues = currentValues.filter(value => value !== option.value);
                            } else {
                              updatedValues = [...currentValues, option.value];
                            }

                            handleModuleChange(index, 'moduleId', updatedValues.join(','));
                          }}
                        >
                          <Checkbox
                            disabled={isReadOnly}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={isSelected}
                          />
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isReadOnly}
                        label={t('tableColumns.mavenProduct')}
                        variant="standard"
                        required
                        placeholder={t('pages.contracts.create.productPlaceholder') || 'Specify product'}
                        error={getFieldError(`moduleId_${index}`, entry.moduleId)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    filterSelectedOptions
                    freeSolo
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1.4}>
                <TextField
                  fullWidth
                  disabled={isReadOnly}
                  size="small"
                  margin="dense"
                  label={t('tableColumns.quantity')}
                  type="number"
                  value={entry.quantity}
                  onChange={(e) => handleModuleChange(index, 'quantity', e.target.value)}
                  required
                  error={getFieldError(`quantity_${index}`, entry.quantity)}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              { !isReadOnly && <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {index === moduleEntries.length - 1 ? (
                  <IconButton onClick={handleAddModule} color="primary">
                    <AddIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleRemoveModule(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>}
            </Grid>
          ))}

          {/* Fourth Row */}
          <Grid item xs={12}>
            <TextField
              variant="standard"
              disabled={isReadOnly}
              size="small"
              margin="dense"
              label={t('tableColumns.comment')}
              fullWidth
              multiline
              rows={4}
              value={comment}
              InputLabelProps={{
                shrink: true
              }}
              placeholder={t('pages.contracts.create.commentPlaceholder') || 'You can add some comments here'}
              onChange={(e) => setComment(e.target.value)}
            />
          </Grid>


        </Grid>
      </DialogContent>
      {type !== 'overview' && (
        <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: 6, paddingRight: 6 }}>
          <Button
            onClick={handleClose}
            size="medium"
            tabIndex={1}
            sx={{ '&:focus': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
          >
            {t('general.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            size="medium"
            tabIndex={0}
            disabled={!isFormValid()}
            sx={{ '&:focus': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
          >
            {type === 'create' ? t('general.create') : t('general.edit')}
          </Button>
          {error && (
            <Alert variant="filled" severity="error" sx={{ width: '100%', paddingTop: 1, paddingBottom: 1, paddingLeft: 1 }}>
              {error}
            </Alert>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CreateContractModal;
