import { useApiQuery } from "../useApiQuery";
import { createQueryFunction } from "../queries/createQueryFunction";
import {
  ModulesResponse,
  ModulesApiType,
  ModulesAccessTreeResponse,
  ModulesAccessTreeApiType,
  ModulesAccessTreeRequest,
  CreateContractApiType,
  ContractsResponse,
  CreateContractRequest, EditModuleAccessApiType, ModuleAccessResponse, ModuleAccessRequest
} from "./types";
import {useApiMutation} from "@/api/useApiMutation";
import {ContentType, createMutationFunction} from "@/api/mutations/createMutationFunction";


export const useModules = (): ModulesApiType => {
    return useApiQuery<ModulesResponse[], {}>({
      key: 'modules',
      queryFn: createQueryFunction(`app/management/modules`),
    });
};

export const useModulesAccessTree = (): ModulesAccessTreeApiType => {
    return useApiQuery<ModulesAccessTreeResponse[], ModulesAccessTreeRequest>({
      key: 'modules-access-tree',
      queryFn: createQueryFunction(`app/management/modules-access-tree`),
    });
};

export const useEditModuleAccess = (): EditModuleAccessApiType => {
    return useApiMutation<ModuleAccessResponse, ModuleAccessRequest>({
      mutationFn: (variables) =>
        createMutationFunction<ModuleAccessResponse, ModuleAccessRequest>(
          `app/management/modules-access/${variables?.account_id}/manufacturers/${variables?.manufacturer_id}/modules/${variables?.module_id}`,
          ContentType.JSON,
          "PUT")(variables),
    });
};