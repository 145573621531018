import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Drawer as MuiDrawer } from "@mui/material";
import SidebarNav from "./SidebarNav";
import { ReactComponent as MavenLogoIcon } from "../../assets/svg/maven360-icon.svg";
import { ReactComponent as MavenLogoText } from "../../assets/svg/maven360-text.svg";
import { SidebarFooter } from "./SidebarFooter";
import { SidebarItemsType } from "@/types/sidebar";
import { Routes } from "@/consts/routes";
import SidebarToggleButton from "@/components/sidebar/SidebarToggleButton";
import useData from "@/hooks/auth/useData";
import { debounce } from "@/hooks/useDebaunce";

const DrawerContainer = styled.div<{ width: number; open: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.open ? `${props.width}px` : "14px")};
  transition: 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
  background-color: ${(props) => props.theme.palette.background.paper};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1200;

  &:hover .toggleButton {
    opacity: 1;
  }
`;

const HoverBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  z-index: 1201;
`;

const ResizeHandle = styled.div`
  width: 1px;
  background-color: ${(props) =>
    props.theme.palette.mode === "dark"
      ? "#515151"
      : "#d1d1d1"};
  cursor: ew-resize;
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  z-index: 1201;

  &:hover {
    background-color: ${(props) =>
      props.theme.palette.mode === "dark"
        ? "#0C599C"
        : "#0c66e4"};
    width: 2.5px;
  }
`;

const Brand = styled(NavLink)<{ open: boolean }>`
  display: flex;
  flex-grow: 0;
  background-color: ${(props) => props.theme.sidebar.header.background};
  justify-content: flex-start;
  padding: 20px 24px;
  height: 64px;
  gap: 12px;
  text-decoration: none;
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: opacity 200ms cubic-bezier(0.2, 0, 0, 1) 0s, visibility 0.3s;

  svg {
    g:first-of-type {
      fill: white;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

export type SidebarProps = {
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
  onToggle: () => void;
  onWidthChange: (newWidth: number) => void;
  isMobile?: boolean;
  setSidebarOpen: (open: boolean) => void;
  drawerWidth: number;
};

const Sidebar: React.FC<SidebarProps> = ({
  open = true,
  items,
  showFooter = true,
  onToggle,
  onWidthChange,
  isMobile,
  setSidebarOpen,
  drawerWidth,
}) => {
  const location = useLocation();
  const { user, updateUserApi } = useData();
  const [navPath, setNavPath] = useState<string>(location.pathname);
  const [isResizing, setIsResizing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseDown = () => {
    setIsResizing(true);
  };

  const debouncedUpdateUserApi = debounce((settings: any) => {
    updateUserApi?.execute({ settings });
    localStorage.setItem("sidebarWidth", settings.drawerWidth.toString());
  }, 300);

  const handleMouseMove = (e: MouseEvent) => {
    if (isResizing) {
      const newWidth = Math.max(14, Math.min(e.clientX, 400));
      if (newWidth < 180) {
        onToggle();
      } else {
        onWidthChange(newWidth);
        debouncedUpdateUserApi({ ...user?.settings, drawerWidth: newWidth });
      }
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  const handleMouseEnter = () => {
    if (!open) {
      setIsHovering(true);
      setSidebarOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (open && isHovering) {
      setIsHovering(false);
      setSidebarOpen(false);
    }
  };

  return (
    <>
      {isMobile ? (
        <MuiDrawer
          variant="temporary"
          open={open}
          onClose={onToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": { width: "200px" },
          }}
        >
          <Brand
            to={Routes.startPage}
            onClick={() => setNavPath(Routes.startPage)}
            open={true}
            aria-label="Go to homepage" /* Accessible label */
            title="Homepage" /* Tooltip for mouse users */
          >
            <MavenLogoIcon />
            <MavenLogoText />
            <span className="sr-only">Home</span> {/* For screen readers */}
          </Brand>
          <SidebarNav items={items} navPath={navPath} setNavPath={setNavPath} />
          {!!showFooter && <SidebarFooter />}
        </MuiDrawer>
      ) : (
        <>
          <HoverBar onMouseEnter={handleMouseEnter} />

          <DrawerContainer
            width={drawerWidth}
            open={open}
            onMouseLeave={handleMouseLeave}
          >
            <Brand
              to={Routes.startPage}
              onClick={() => setNavPath(Routes.startPage)}
              open={open}
              aria-label="Go to homepage" /* Accessible label */
              title="Homepage" /* Tooltip for mouse users */
            >
              <MavenLogoIcon />
              <MavenLogoText />
            </Brand>

            <SidebarNav
              items={items}
              navPath={navPath}
              setNavPath={setNavPath}
              open={open}
            />
            {!!showFooter && <SidebarFooter isVisible={open} />}
            <ResizeHandle onMouseDown={handleMouseDown} />
            <SidebarToggleButton
              drawerWidth={drawerWidth}
              open={open}
              onClick={onToggle}
            />
          </DrawerContainer>
        </>
      )}
    </>
  );
};

export default Sidebar;
