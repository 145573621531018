import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";

export interface ExtraThemeProps {
  gap?: string | number;
}

const createTheme = (name: string) => {
  let themeConfig = variants.find((variant) => variant.name === name);
  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      gap: "10px",
      breakpoints: breakpoints,
      // @ts-expect-error Incompatible types
      components: {
        ...components,
        MuiLink: {
          defaultProps: {
            underline: "hover",
          },
          styleOverrides: {
            root: ({ theme }) => ({
              color: theme.palette.mode === "dark" ? "#A5C4EE" : "#0C599C",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }),
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: ({ theme }) => ({
              // Default label color
              color: theme.palette.mode === "dark" ? 'rgb(201, 201, 201)' : theme.palette.grey[700],

              // Specific to the "standard" variant
              "&.Mui-focused": {
                color: theme.palette.primary.main,
              },

              "&.Mui-error": {
                color: theme.palette.error.main,
              },
              "&.Mui-disabled": {
                color: theme.palette.mode === "dark" ? 'rgb(201, 201, 201)' : theme.palette.grey[700],
              },
            }),
          },
        },
      },
      typography: typography,
      shadows: shadows,
      palette: {
        ...themeConfig.palette,
        error: {
          // @ts-expect-error Incompatible types
          main: themeConfig.palette.mode === "dark" ? "#F49890" : "#D32F2F",
          contrastText: "#fff",
        },
      },
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  );
};

export default createTheme;
