import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MuiGridTable as DataGridTable } from "@/components/tables/DataGridTable";
import {
  Alert,
  Box,
  Menu,
  MenuItem,
  Paper as MuiPaper,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { t } from "i18next";
import { ComponentsResponse, PropertiesResponse } from "@/api/buynow/types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditDrawer from "@/pages/buynow/components/editPanel";
import { TabsProvider } from "@/contexts/TabsContext";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";
import CreateComponent from "./CreateComponent";
import { hiddenColumns } from "./consts";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { DataContext } from "@/contexts/DataContext";

const Paper = styled(MuiPaper)(spacing);

export default function BuyNowComponents(): JSX.Element {
  const {
    selectedManufacturers,
    componentsApi,
    defContentJsonApi,
    defSocialJsonApi,
    allPropertiesApi,
    user,
    setConfirmationDialogOpen,
    updateComponentsApi,
  } = useData();
  const userNumberFormat = user?.settings?.numberFormat || "en-US";
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const allColumns = useColumns();
  const [rows, setRows] = useState<ComponentsResponse[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<ComponentsResponse | null>(
    null
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null>(null);
  const {
    copyState,
    setCopyState,
    openSnackbar, 
    setOpenSnackbar,
    snackbarMessage,
    setSnackbarMessage
  } = useContext(DataContext)

  const { getTypeImage, renderLinkCell, removeProtocol, na , getTranslatedHeaderName, formatValue, parseCustomDate} = useCustomTableUtils();

  useEffect(() => {
    if (componentsApi.data) {
      setRows(componentsApi.data);
    }
  }, [componentsApi.data]);

  useEffect(() => {
    const selectedManufacturerIDs = selectedManufacturers.map((m) => m.ID);
    if (componentsApi && selectedManufacturerIDs.length > 0) {
      setSelectedRows([]);
      defContentJsonApi.execute();
      defSocialJsonApi.execute();
      componentsApi.execute({ m: selectedManufacturerIDs });
      allPropertiesApi.execute({ m: selectedManufacturerIDs });
    }
  }, [selectedManufacturers]);

  const handleUpdateRow = (updatedRow: ComponentsResponse) => {
    if (!updatedRow?.type || updatedRow?.type !== "variant") {
      setSelectedRow(updatedRow);
      setHighlightedRowId(updatedRow?.id);
      setTimeout(() => setHighlightedRowId(null), 1000);
    }
  
    setRows((prevRows) => {
      // Remove old children if updatedRow is a parent (not a variant)
      let rowsWithoutChildren = prevRows;
      if (!updatedRow?.type || updatedRow?.type !== "variant") {        
        rowsWithoutChildren = prevRows.filter(
          (row) => !(row?.hierarchy?.[0] == updatedRow.id && row.hierarchy.length > 1)
        );
      }
  
      const existingRow = rowsWithoutChildren.find((row) => row.id === updatedRow.id);
  
      if (existingRow) {
        return rowsWithoutChildren.map((row) =>
          row.id === updatedRow.id ? updatedRow : row
        );
      } else {
        return [...rowsWithoutChildren, updatedRow];
      }
    });
  };
  
  

  const handleCopy = (domain: string) => {
    const divContent = `<a data-bid="${domain}">Jetzt kaufen</a>`;
    navigator.clipboard.writeText(divContent).then(() => {  
      setCopyState("success")
      setSnackbarMessage(t("tableColumns.copiedToClipboard") || "Copied to clipboard")
    }).catch(err => {
      console.error("Failed to copy:", err);
      setCopyState("error")
      setSnackbarMessage(t("errors.copiedToClipboard") || "Copy failed")
    });
    setOpenSnackbar(true);
  };

  const handleMenuClick = (
    row: ComponentsResponse
  ) => {
    setSelectedRow(row);
    setDrawerOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleVisitComponent = (row: ComponentsResponse) => {
    if (row?.demo_link) {
      window.open(row.demo_link, "_blank");
      return;
    }
    handleMenuClose();
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const removeComponent = () => {
    if (selectedRow) {
      return updateComponentsApi.execute(
        {
          id: selectedRow.id,
          activity_status: 2,
        }
      )
    } else {
      throw new Error('No row selected');
    }
  };

  const highlightRow = (id: string) => {
    setHighlightedRowId(id);
    setTimeout(() => {
      setHighlightedRowId(null);
    }, 5000);
  }

  const onComponentRemoved = (data: ComponentsResponse) => {
    highlightRow(data.id);
    setSnackbarMessage(t('pages.buyNow.componentRemoved') || "Component removed");
    setOpenSnackbar(true);
    setRows((prev) => {
      return prev.map((el) => {
        if (el.id === data.id) {
          el.activity_status = data.activity_status;
          el.updated = data.updated;
        }
        return el;
      })
    })
  };

  const groupingColDef = {
    ...allColumns.idColumn,
    headerName: "BID",
    align: "right",
    width: 80,
    valueGetter: (_: any, row: Record<string, any>) => row.type === "instance" ? Number(row.id) : "",
    hideDescendantCount: true,
  };

  const handleRemoveComponent = (
    row: ComponentsResponse
  ) => {
    setSelectedRow(row);
    setConfirmationDialogOpen(true);
  };

  const columns: GridColDef<ComponentsResponse>[] = [
    {
      ...allColumns.idColumn,
      headerName: "Copy Button",
      width: 2,
      sortable: false,
      align: "left",
      disableExport: true,
      filterable: false,
      hideable: true,
      renderHeader: () => '',
      renderCell: (params) => {
        const componentId = params.row.id;
        if (params.row.type === "variant") return "";
        return (
          <Box
           onClick={() => handleCopy(componentId)}
          >
            <ContentCopyIcon sx={{ height: 14, paddingLeft: 0, margin: '-2px -15px', cursor: "pointer",}} />
          </Box>
        );
      },
    },
    allColumns.actionsTreeColumnNative(
      (params: GridRowParams) => {
        const actions: React.ReactElement[] = [];
    
        actions.push(
          <GridActionsCellItem
            key="edit"
            disabled={selectedRow?.activity_status === 2}
            onClick={() => handleMenuClick(params.row)}
            label={user?.role === "admin" ? t("general.edit") : t("general.configuration")}
            showInMenu
          />
        );
    
        if (user?.role === "admin") {
          actions.push(
            <GridActionsCellItem
              key="remove"
              disabled={selectedRow?.activity_status === 2}
              onClick={() => handleRemoveComponent(params.row)}
              label={getTranslatedHeaderName("general.remove", "Remove")}
              showInMenu
            />
          );
        }
    
        actions.push(
          <GridActionsCellItem
            key="preview"
            onClick={(_event) => {
              handleVisitComponent(params.row);
            }}
            label={getTranslatedHeaderName("general.preview", "Preview")}
            showInMenu
          />
        );
    
        return actions;
      },
      (params: GridRowParams) => (params.row?.hierarchy?.length || 0) === 1
    ),
   { ...allColumns.manufacturerNameColumn,
    flex: 1,
    minWidth: 257,
    renderCell: (params) => {
      if (params.row.type === "variant") return "";
      const manufacturer = params.row.manufacturer_name;
      return manufacturer ? (
        <span style={{ fontWeight: 500 }}>{manufacturer}</span>
      ) : (
        na
      );
    },
   },
   { ...allColumns.brandNameColumn,
    minWidth: 257,
    flex: 1,
   },
    allColumns.productNameColumn,
    {
      ...allColumns.pznColumn,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      ...allColumns.bnTypeColumn,
      width: 54,
      valueGetter: (_: any, row: Record<string, any>) => row.type === "instance" ? (row.install.type === 1 ? "social" : "content") : "",
      renderCell: (params: GridRenderCellParams) =>
        params.row.type === "instance" ? getTypeImage(params.row.install.type) : "",
    },
    {
      ...allColumns.propertyColumn,
      minWidth: 257,
      flex: 1,
      renderCell: (params) =>
        params.row.type === "instance" ? renderLinkCell(
          params.row.install?.url,
          removeProtocol(params.row.install?.url),

        ) : "",
      valueGetter: (_: any, row: Record<string, any>) => row.type === "instance" ? (row.install?.url || na) : "",
    },
    allColumns.instanceActivityStatusColumn,
    {
      ...allColumns.demoComponentColumn,
      valueGetter: (_: any, row: Record<string, any>) =>{
        if (row.type === "variant") return "";
        return row.demo
          ? getTranslatedHeaderName("tableColumns.yes", "Yes")
          : getTranslatedHeaderName("tableColumns.no", "No")},
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.type === "variant") return "";

        return params.row.demo
          ? getTranslatedHeaderName("tableColumns.yes", "Yes")
          : getTranslatedHeaderName("tableColumns.no", "No")},
    },
    {
      ...allColumns.restrictionColumn,
      valueGetter: (_: any, row: Record<string, any>) => row.type === "instance" ? (row.install?.domain || na) : "",
    },
    {
      ...allColumns.createdOnlyDateColumn,
      valueFormatter: (_, row) => {
        if (row.type === "variant") return "";
        if (!row.created) return na;
        const parsedDate = parseCustomDate(row.created, ["T", "-", ":"]);
        return formatValue(
          parsedDate,
          userNumberFormat,
          undefined,
          undefined,
          true
        );
      },
    },
    {
      ...allColumns.updatedOnlyDateColumn,
      valueFormatter: (_, row) => {
        if (row.type === "variant") return "";
        if (!row.updated) return na;
        const parsedDate = parseCustomDate(row.updated, ["T", "-", ":"]);
        return formatValue(
          parsedDate,
          userNumberFormat,
          undefined,
          undefined,
          true
        );
      },
    },
  ];

  // Avoiding the whole table to be rerendered if the event listener inside the row is being triggered
  const memoizedRows = useMemo(() => rows, [rows]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const getTreeDataPath = useCallback((row: { hierarchy: Array<string> }) => row.hierarchy || [], []);
  const getRowId = useCallback((row: ComponentsResponse) => row.id, []);

  const initFilterModel = useMemo(() => {
    return {
      items: [
        {
          field: "activity_status",
          operator: "equals",
          value: t("general.active"),
          fromInput: "",
        },
      ],
    }
  }, [user?.settings?.language]);


  return (
    <ContentContainer>
      <Paper>
        <DataGridTable
          id="buynowComponents"
          rows={memoizedRows}
          columns={memoizedColumns}
          treeData
          groupingColDef={groupingColDef as GridColDef}
          getTreeDataPath={getTreeDataPath}
          getRowClassName={(params) => {
            if (params.row.type === "variant") {
              return "gray-50-row";
            }
            return "";
          }}
          loading={
            componentsApi.showSkeletton ||
            defContentJsonApi.showSkeletton ||
            defSocialJsonApi.showSkeletton ||
            allPropertiesApi.showSkeletton
          }
          sortModel={[
            {
              field: 'updated',
              sort: 'desc',
            },
          ]}
          getRowId={getRowId}
          hiddenFields={hiddenColumns}
          {...(user?.role === 'admin' && {
            filterBarType: 2,
            hasControls: true,
            getRowClassName: (params) => {
              const childStyles = params.row.type === "variant" ? "gray-50-row" : "";
              return params.row.id === highlightedRowId ? `row-highlight ${childStyles}` : childStyles;
            },
            onCreateClick: () => setDialogOpen(true),
          })}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
          tableInfo={t("tableInfo.buyNowComponents") || ""}
          isError={componentsApi?.isError}
          error={componentsApi?.error}
          initialGridFilterModal={initFilterModel}
        />
      </Paper>

      <TabsProvider rawRowData={selectedRow as ComponentsResponse}>
        <EditDrawer
          open={drawerOpen}
          onClose={handleDrawerClose}
          updateRow={handleUpdateRow}
        />
      </TabsProvider>

      <CreateComponent
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        onSuccess={(newComponent: ComponentsResponse) => {
          setRows((prevRows) => [...prevRows, newComponent]);
          setHighlightedRowId(newComponent.id);
          setSnackbarMessage("Component was created successfully");
          setOpenSnackbar(true);
          highlightRow(newComponent.id);
        }}
      />
      <ConfirmationDialog
        onClick={removeComponent}
        title={t("pages.buyNow.removeComponent")}
        isLoading={updateComponentsApi.isLoading}
        onSuccess={(data) => onComponentRemoved(data)}
      />
    </ContentContainer>
  );
}
