import {
  GridColDef,
  GridRenderCellParams,
  GridActionsCellItem,
  GridRenderEditCellParams,
  GridFilterInputValue,
  gridStringOrNumberComparator,
  GridCellParams, GridActionsColDef,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import { Label } from "@/api/setup/types";
import {Box, Checkbox, Color, FormControl, MenuItem, Select, Tooltip, useTheme} from "@mui/material";
import { formatUserNumber } from "@/utils/formatUserNumber";
import { CheckCircleOutlined, ErrorOutline } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import useData from "@/hooks/auth/useData";
import {
  PREVIEW_URL,
  green,
  red,
  yellow,
  darkRed as darkerRed,
  gray as lighterGray,
  orange as brighterOrange,
} from "./consts";

import useCustomTableUtils from "./utils";
import BrandCell from "@/components/BrandCell";
import CollaborationCell from "@/components/CollaborationCell";
import LabelsCell from "@/components/LabelsCell";
import { Link, useNavigate } from "react-router-dom";
import LabelColumns from "@/components/LabelColumns";
import ImageCell from "@/components/ImageCell";
import { differenceInDays, differenceInMonths, differenceInYears } from "date-fns";
import { useTranslation } from "react-i18next";


export const useColumns = () => {
  const { user } = useData();
  const userNumberFormat = user?.settings?.numberFormat || 'en-US';
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const numComparator = (_v1: number, _v2: number, param1: GridCellParams, param2: GridCellParams) => {
    console.log(param1) // has properties of api, field, id, rowNode, value

    const v1: number = param1.api.getRow(param1["id"])["totalArea"]
    const v2: number = param2.api.getRow(param2["id"])["totalArea"]
    return v1 - v2

  }

  const green = theme.palette.success.main;
  const red = theme.palette.error.main;
  const darkRed = theme.palette.mode === "dark" ? "#BEBCBC" : darkerRed;
  const gray = theme.palette.mode === "dark" ? lighterGray : theme.palette.grey[700];
  const orange = theme.palette.mode === "dark" ? brighterOrange : "#705200"
  const {
    formatValue,
    formatDate,
    getTranslatedHeaderName,
    parseCustomDate,
    renderDate,
    renderLinkCell,
    renderTranslatedCell,
    renderValueSpanColor,
    getTypeImage,
    trimUrl,
    getChip,
    renderFixedColor,
    renderPercentage,
    boolIntToString,
    replaceZeroByNone,
    na,
    statusTranslations,
    avTranslations,
    yesNoTranslations,
    renderTooltipText,
    getCurrency
  } = useCustomTableUtils();

  const createRawValueGetter = (fields: string | string[]) => {
    return (_: any, row: Record<string, any>) => {
      const fieldList = Array.isArray(fields) ? fields : [fields];

      // Find the first matching field value, checking for both uppercase and lowercase versions
      let value;
      for (const field of fieldList) {
        value = row[field] || row[field.toLowerCase()];
        if (value) return value;
      }
    };
  };

  const createValueGetter = (
    fields: string | string[], // Accept either a single field or an array of fields
    shouldBeFormatted: boolean = false,
    maximumFractionDigits: number = 0,
    returnEmptyNa: boolean = true
  ) => {
    return (_: any, row: Record<string, any>) => {
      const value = createRawValueGetter(fields)(_, row);

      if (!value || (typeof value === 'string' && value?.trim() === '')) {
        if (returnEmptyNa) {
          return na;
        } else {
          return '';
        }
      }

      if (shouldBeFormatted && !isNaN(Number(value))) {
        return formatValue(
          Number(value),
          userNumberFormat,
          maximumFractionDigits
        );
      }

      const parsedDate = new Date(value);
      if (shouldBeFormatted && !isNaN(parsedDate.getTime())) {
        return formatValue(parsedDate, userNumberFormat);
      }

      if (
        typeof value === 'string' &&
        /^\d{2}\.\d{2}\.\d{2} \d{2}:\d{2}$/.test(value)
      ) {
        const parsedDate = parseCustomDate(value);
        return formatValue(parsedDate, userNumberFormat);
      }

      return String(value);
    };
  };

  const systemColumn: GridColDef = {
    field: 'system',
    headerName: getTranslatedHeaderName('tableColumns.system', 'System'),
    minWidth: 150,
    flex: 1,
    valueGetter: (_: any, row: Record<string, any>) => {
      if (row['type'] === 'user') {
        if (Array.isArray(row['system'])) {
          const systemsCount = row['system'].length;
          if (systemsCount === 1) return row['system'][0];
          return `${getTranslatedHeaderName(
            'general.multiple',
            'multiple'
          )} (${systemsCount})`;
        }
      }
      return row['system'] || na;
    },
  };

  const pageColumn: GridColDef = {
    field: 'page_title',
    headerName: getTranslatedHeaderName('tableColumns.page', 'Page'),
    minWidth: 150,
    flex: 1,
    valueGetter: createValueGetter('page_title'),
    renderCell: (params: GridRenderCellParams<any>) => {
      const pageTitle = params.row['page_title'];
      if (params.row['type'] === 'user') return '';
      if (!pageTitle) return na;
      const pagePath = params.row["url"];
      const linkColor = theme.palette.mode === "dark" ? "#A5C4EE" : "#0C599C";

      if (pagePath) {
        try {
          const url = new URL(pagePath); // Parse the URL
          return (
            <Link to={url.pathname} style={{ textDecoration: "none", color: linkColor, fontWeight: 700 }}>
                {pageTitle}
            </Link>
          ); // Get the path part of the URL
        } catch {}
      }

      return na;
    },
  };

  const ipAddressColumn: GridColDef = {
    field: 'ip_address',
    align: 'center',
    headerAlign: 'center',
    headerName: getTranslatedHeaderName('tableColumns.ipAddress', 'IP-Address'),
    minWidth: 150,
    valueGetter: (_: any, row: Record<string, any>) => {
      if (row['type'] === 'user') {
        if (Array.isArray(row['ip_address'])) {
          const ipAddresssCount = row['ip_address'].length;
          if (ipAddresssCount === 1) return row['ip_address'][0];
          return `${getTranslatedHeaderName(
            'general.multiple',
            'multiple'
          )} (${ipAddresssCount})`;
        }
      }
      return row['ip_address'] || na;
    },
  };

  const timeOfVisitColumn: GridColDef = {
    field: 'timestamp',
    headerName: getTranslatedHeaderName(
      'tableColumns.timeOfVisit',
      'Time Of Visit'
    ),
    width: 141,
    renderCell: (params: GridRenderEditCellParams) => {
      const parsedDate = parseCustomDate(params.value, ['T', '-', ':']);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
    valueGetter: createValueGetter('timestamp'),
  };

  const shareColumn = {
    field: "SHARE",
    headerName: getTranslatedHeaderName("tableColumns.share", "Share"),
    width: 120,
    align: "right",
    hideDescendantCount: true,
    headerAlign: "center",
    valueGetter: (_: any, row: Record<string, any>) => {
      const share = (row["rix"] || 0) * 100;
      return (
          formatValue(share, userNumberFormat, 1, 1) + "%"
      );
    },
  };

  const restrictionColumn: GridColDef = {
    field: 'restriction',
    headerName: getTranslatedHeaderName(
      'tableColumns.restriction',
      'Restriction'
    ),
    width: 150,
    valueGetter: (_: any, row: Record<string, any>) =>
      row.install?.domain || na,
  };

  const top3Column: GridColDef = {
    field: 'TOP3',
    headerName: 'Top 3',
    width: 60,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {params.row?.top3 === true ? (
            <CheckCircleOutlined color="success" />
          ) : (
            <ErrorOutline color="error" />
          )}
        </Box>
      );
    },
    valueGetter: createValueGetter('top3'),
  };

  const sovKeywordColumn: GridColDef = {
    field: 'keyword',
    headerName: getTranslatedHeaderName('tableColumns.keyword', 'Keyword'),
    width: 130,
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === 'keyword' ? row.name || '' : row?.keyword;
    },
    renderCell: (params: GridRenderEditCellParams) => {
      return (
        <span>
          {params.row.type === 'keyword'
            ? params.row.name || ''
            : params?.row?.keyword}
        </span>
      );
    },
  };
  const sovProductColumn: GridColDef = {
    field: 'product',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    minWidth: 200,
    flex: 1,
    align: 'left',
    headerAlign: 'left',
    sortable: false,
    renderCell: (params: GridRenderEditCellParams) => {
      return params.row.type === 'product' ? params.row.name : '';
    },
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === 'product' ? row.name : '';
    },
  };

  const top10Column: GridColDef = {
    field: 'TOP10',
    headerName: 'Top 10',
    width: 60,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams<any>) => {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {params.row?.top10 === true ? (
            <CheckCircleOutlined color="success" />
          ) : (
            <ErrorOutline color="error" />
          )}
        </Box>
      );
    },
    valueGetter: createValueGetter('top10'),
  };

  const sovSkuColumn: GridColDef = {
    field: 'sku',
    headerName: getTranslatedHeaderName('tableColumns.PZN', 'PZN'),
    align: 'right',
    headerAlign: 'left',
    sortable: false,
    width: 80,
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === 'product' ? row.pzn : '';
    },
  };

  const pznTransColumn: GridColDef<any> = {
    field: 'pzn',
    headerName: getTranslatedHeaderName('tableColumns.PZN', 'PZN'),
    sortable: false,
    align: 'left',
    width: 160,
    valueGetter: createValueGetter('pzn')
  };

  const avgSerpColumn: GridColDef = {
    field: 'AVG_SERP',
    headerName: getTranslatedHeaderName(
      'tableColumns.averageSerp',
      'Avg. SERP'
    ),
    width: 100,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams<any>) => {
      return Math.round(Number(params.row['avg-serp']));
    },
    valueGetter: (_: any, row: Record<string, any>) => {
      return Math.round(Number(row['avg-serp'])) || '';
    },
  };

  const avgRatingsColumn: GridColDef = {
    field: 'AVG_RATINGS',
    headerName: getTranslatedHeaderName(
      'tableColumns.averageRatings',
      'Avg. Ratings'
    ),
    width: 100,
    align: 'center',
    headerAlign: 'center',
    valueGetter: (_: any, row: Record<string, any>) => {
      return Math.round(Number(row['ratings'])) || '';
    },
  };

  const reviewColumn: GridColDef<any> = {
    field: 'TEXT',
    headerName: getTranslatedHeaderName('tableColumns.review', 'Review'),
    width: 200,
    sortable: false,
    valueGetter: createValueGetter('TEXT', false),
  };

  const impactColumn: GridColDef<any> = {
    field: 'IMPACT',
    sortable: true,
    headerName: getTranslatedHeaderName('tableColumns.impact', 'Impact'),
    width: 75,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      const colorIndex = params.value > 0 ? 1 : params.value < 0 ? 0 : 2;
      return (
        <span
          style={{
            color: [red, green, theme.palette.text.primary][colorIndex],
          }}
        >
          {params.value}%
        </span>
      );
    },
    valueGetter: (_, params) => {
      const percentageValue = Math.round(
        parseFloat((params.IMPACT || 0).toFixed(2)) * 100
      );
      return percentageValue;
    },
    valueFormatter: (_, params) => {
      const formattedValue = formatValue(
        params.IMPACT || 0,
        userNumberFormat,
        2,
        2
      );
      if (
        formattedValue == '-0,00' ||
        formattedValue == '0,00' ||
        formattedValue == '-0.00' ||
        formattedValue == '0.00'
      )
        return '0';
      return formattedValue;
    },
  };

  const avgColumn: GridColDef<any> = {
    field: 'AVG',
    headerName: getTranslatedHeaderName('tableColumns.avg', 'AVG'),
    width: 40,
    align: 'center',
    headerAlign: 'center',
    valueGetter: createValueGetter('AVG', true, 1),
  };

  const pznColumn: GridColDef<any> = {
    field: 'PZN',
    headerName: getTranslatedHeaderName('tableColumns.PZN', 'PZN'),
    width: 100,
    sortable: false,
    valueGetter: createValueGetter('PZN'),
  };

  const leadsColumn: GridColDef<any> = {
    field: 'LEADS',
    headerName: getTranslatedHeaderName('tableColumns.leads', 'Leads'),
    width: 100,
    align: 'right',
    headerAlign: 'center',
    valueGetter: createValueGetter('LEADS', true, 0),
  };

  const dateColumn: GridColDef<any> = {
    field: 'DATE',
    headerName: getTranslatedHeaderName('tableColumns.date', 'Date'),
    width: 80,
    align: 'center',
    renderCell: (params: GridRenderEditCellParams) => {
      const parsedDate = parseCustomDate(params.value, ['T', '-', ':']);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        false
      );
      return result;
    },
    valueGetter: createValueGetter('DATE'),
  };

  const coloredDateColumn: GridColDef<any> = {
    ...dateColumn,
    renderCell: (params: GridRenderCellParams) =>
      renderDate(params.value, userNumberFormat, true),
  };

  const timeAddedColumn: GridColDef<any> = {
    field: 'created',
    headerName: getTranslatedHeaderName('tableColumns.timeAdded', 'Time Added'),
    width: 135,
    renderCell: (params: GridRenderEditCellParams) => {
      const parsedDate = parseCustomDate(params.row.created, ['T', '-', ':']);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
  };

  const groupingTransColDef = {
    field: "timestamp",
    headerName: getTranslatedHeaderName("tableColumns.transaction", "Transaction"),
    headerClassName: "grouping-header",
    sortingOrder: ['desc', 'asc'],
    sortComparator: (v1: string, v2: string, param1: GridCellParams, param2: GridCellParams) => {
      const date1 = new Date(param1.api.getRow(param1.id).timestamp)
      const date2 = new Date(param2.api.getRow(param2.id).timestamp)
      if (date1 < date2) return -1;
      if (date1 > date2) return 1;
      return 0;
    },
    renderHeader: (params: any) => (
      <Tooltip title={t("tableColumns.timeOfTransaction", "Time of transaction")}>
        {params.colDef.headerName}
      </Tooltip>
    ),
    minWidth: 135,
    hideDescendantCount: true,
    sortable: true,
    valueGetter: (_: any, row: Record<string, any>) => {
      if (row.type !== 'POS') return '';
      const parsedDate = parseCustomDate(row.timestamp, ['T', '-', ':']);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
  };

  const timeLeadTransColumn: GridColDef = {
    field: "timelead",
    headerName: getTranslatedHeaderName("tableColumns.timelead", "Time Lead"),
    minWidth: 135,
    renderCell: (params: GridRenderEditCellParams) => {
      const parsedDate = parseCustomDate(params.value, ['T', '-', ':']);
      const formattedDate = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return renderTooltipText(t("tableColumns.timeOfLead"), params.row.type === 'POS' ? formattedDate : " ", { width: '100%' });
    }
  };

  const ratingStarsColumn: GridColDef<any> = {
    field: 'RATING_STARS',
    headerName: getTranslatedHeaderName('tableColumns.rating', 'Rating'),
    width: 150,
    valueGetter: createValueGetter('RATING'),
    renderCell: (params: GridRenderCellParams) => {
      const rating = params.row.RATING || 0;
      const maxStars = 5;

      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {[...Array(maxStars)].map((_, index) => (
            <span key={index}>
              {index < Math.floor(rating) ? (
                <StarIcon
                  style={{ color: '#FFD700', fontSize: 18, marginTop: 20 }}
                />
              ) : index < rating ? (
                <StarHalfIcon
                  style={{ color: '#FFD700', fontSize: 18, marginTop: 20 }}
                />
              ) : (
                <StarBorderIcon
                  style={{ color: '#c5c5c5', fontSize: 18, marginTop: 20 }}
                />
              )}
            </span>
          ))}{' '}
          <span style={{ marginLeft: 10 }}>{rating}</span>
        </div>
      );
    },
  };

  const ratingNumberColumn: GridColDef<any> = {
    field: 'RATING',
    headerName: getTranslatedHeaderName(
      'tableColumns.ratingStarCount',
      'Rating'
    ),
    renderHeader: () => '',
    width: 40,
    align: 'left',
    sortable: true,
    headerAlign: 'center',
    sortComparator: (v1, v2) => v1 - v2,
  };

  const manufacturerNameColumn: GridColDef<any> = {
    field: 'manufacturer_name',
    headerName: getTranslatedHeaderName('tableColumns.maker', 'Maker'),
    width: 150,
    flex: 0,
    renderCell: (params) => {
      const manufacturer = params.row.manufacturer_name;
      return manufacturer ? (
        <span style={{ fontWeight: 500 }}>{manufacturer}</span>
      ) : (
        na
      );
    },
    sortable: true,
    valueGetter: createValueGetter('manufacturer_name'),
  };

  const manufacturerColumn: GridColDef<any> = {
    field: 'manufacturer',
    headerName: getTranslatedHeaderName('tableColumns.maker', 'maker'),
    width: 150,
    flex: 1,
    sortable: true,
    valueGetter: createValueGetter('manufacturer'),
  };

  const manufacturerNameColumn2: GridColDef<any> = {
    ...manufacturerNameColumn,
    field: 'MANUFACTURER_NAME',
    renderCell: (params) => {
      const manufacturer = params.row.MANUFACTURER_NAME;
      return manufacturer ? (
        <span style={{ fontWeight: 500 }}>{manufacturer}</span>
      ) : (
        na
      );
    },
    valueGetter: createValueGetter('MANUFACTURER_NAME'),
  };

  const propertyColumn: GridColDef<any> = {
    field: 'url',
    headerName: getTranslatedHeaderName('tableColumns.property', 'Property'),
    sortable: true,
    width: 150,
    renderCell: (params) =>
      renderLinkCell(params.row.url, trimUrl(params.row.url)),
    valueGetter: createValueGetter('url'),
  };

  const domainColumn: GridColDef<any> = {
    field: 'domain',
    headerName: getTranslatedHeaderName(
      'tableColumns.restriction',
      'Restriction'
    ),
    sortable: true,
    flex: 2,
    minWidth: 120,
    valueGetter: createValueGetter('domain'),
  };

  const demoColumn: GridColDef<any> = {
    field: 'DEMO',
    headerName: getTranslatedHeaderName('tableColumns.demo', 'Demo'),
    minWidth: 80,
    flex: 1,
    renderCell: (params: GridRenderCellParams) =>
      renderLinkCell(
        `${PREVIEW_URL}${PREVIEW_URL.endsWith('/') ? '' : '/'}${
          params.row.DEMO
        }`,
        'Demo'
      ),
  };

  const demoComponentColumn: GridColDef<any> = {
    field: 'demo',
    headerName: getTranslatedHeaderName('tableColumns.demo', 'Demo'),
    width: 80,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (_: any, row: Record<string, any>) =>
      row.demo
        ? getTranslatedHeaderName('tableColumns.yes', 'Yes')
        : getTranslatedHeaderName('tableColumns.no', 'No'),
    renderCell: (params: GridRenderCellParams) =>
      params.row.demo
        ? getTranslatedHeaderName('tableColumns.yes', 'Yes')
        : getTranslatedHeaderName('tableColumns.no', 'No'),
  };

  const expiresColumn: GridColDef<any> = {
    field: 'EXPIRES',
    headerName: getTranslatedHeaderName('tableColumns.expires', 'Expires'),
    sortable: true,
    minWidth: 80,
    flex: 1,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params);
      return formatValue(parsedDate, userNumberFormat);
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1);
      const date2 = parseCustomDate(v2);
      // Add type guard to ensure we have Date objects
      if (date1 instanceof Date && date2 instanceof Date) {
        return date1.getTime() - date2.getTime();
      }
      return 0;
    },
  };

  const configStateColumn: GridColDef<any> = {
    field: 'CONFIG_STATE',
    headerName: getTranslatedHeaderName('tableColumns.config', 'Config'),
    sortable: true,
    flex: 1,
    minWidth: 80,
    valueGetter: createValueGetter('CONFIG_STATE'),
  };

  const createdColumn: GridColDef<any> = {
    field: 'CREATED',
    headerName: getTranslatedHeaderName('tableColumns.created', 'Created'),
    sortable: true,
    minWidth: 135,
    flex: 1,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params);
      return formatValue(parsedDate, userNumberFormat);
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1);
      const date2 = parseCustomDate(v2);
      // Add type guard to ensure we have Date objects
      if (date1 instanceof Date && date2 instanceof Date) {
        return date1.getTime() - date2.getTime();
      }
      return 0;
    },
  };

  const createdOnlyDateColumn: GridColDef<any> = {
    field: 'created',
    headerName: getTranslatedHeaderName('tableColumns.created', 'Created'),
    sortable: true,
    minWidth: 135,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params, ['T', '-', ':']);
      return formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true
      );
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1, ['T', '-', ':']) as Date;
      const date2 = parseCustomDate(v2, ['T', '-', ':']) as Date;
      return date1.getTime() - date2.getTime();
    },
  };

  const createdBNComponentDateColumn: GridColDef<any> = {
    ...createdOnlyDateColumn,
    valueFormatter: (_, row) => {
      if (row.type === "variant") return "";
      if (!row.created) return na;
      const parsedDate = parseCustomDate(row.created, ["T", "-", ":"]);
      return formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true
      );
    },
  };

  const conversionRateColumn: GridColDef<any> = {
    field: 'CR',
    headerName: getTranslatedHeaderName('tableColumns.CR', 'CR'),
    width: 100,
    align: 'right',
    headerAlign: 'center',
    valueGetter: createValueGetter('CR', true, 2),
    renderCell: (params: GridRenderCellParams) => {
      return <span>{params.value}%</span>;
    },
  };

  const updatedColumn: GridColDef<any> = {
    field: 'UPDATED',
    headerName: getTranslatedHeaderName('tableColumns.edited', 'Edited'),
    sortable: true,
    flex: 1,
    minWidth: 135,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params);
      return formatValue(parsedDate, userNumberFormat);
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1);
      const date2 = parseCustomDate(v2);
      // Add type guard to ensure we have Date objects
      if (date1 instanceof Date && date2 instanceof Date) {
        return date1.getTime() - date2.getTime();
      }
      return 0;
    },
  };

  const updatedOnlyDateColumn: GridColDef<any> = {
    field: 'updated',
    headerName: getTranslatedHeaderName('tableColumns.edited', 'Edited'),
    sortable: true,
    minWidth: 135,
    valueFormatter: (params) => {
      if (!params) return na;
      const parsedDate = parseCustomDate(params, ['T', '-', ':']);
      return formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true
      );
    },
    sortComparator: (v1: string, v2: string) => {
      const date1 = parseCustomDate(v1, ['T', '-', ':']);
      const date2 = parseCustomDate(v2, ['T', '-', ':']);
      // Add type guard to ensure we have Date objects
      if (date1 instanceof Date && date2 instanceof Date) {
        return date1.getTime() - date2.getTime();
      }
      return 0;
    },
  };

  const updatedBNComponentDateColumn: GridColDef<any> = {
    ...updatedOnlyDateColumn,
    valueFormatter: (_, row) => {
      if (row.type === "variant") return "";
      if (!row.updated) return na;
      const parsedDate = parseCustomDate(row.updated, ["T", "-", ":"]);
      return formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true
      );
    }
  };

  const posColumn: GridColDef<any> = {
    field: 'POS',
    headerName: getTranslatedHeaderName('tableColumns.POS', 'POS'),
    sortable: true,
    width: 164,
    valueGetter: createValueGetter('POS'),
  };

  const posLinkColumn: GridColDef<any> = {
    field: 'POS',
    headerName: getTranslatedHeaderName('tableColumns.POS', 'POS'),
    sortable: true,
    width: 150,
    valueGetter: createValueGetter('POS'),
    renderCell: (params: GridRenderEditCellParams) =>
      renderLinkCell(params.row.URL, params.row.POS, t("pages.analytics.tooltip.pdp") || 'Visit Product Detail Page'),
  };

  const pdpColumn: GridColDef<any> = {
    field: 'PDP',
    headerName: 'PDP',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    width: 80,
    valueGetter: (_: any, row: Record<string, any>) => boolIntToString(row.PDP),
    renderCell: (params: GridRenderCellParams) =>
      renderTranslatedCell(
        boolIntToString(params.row.PDP, false),
        yesNoTranslations,
        [green, red]
      ),
  };

  const catColumn: GridColDef<any> = {
    field: 'CAT',
    headerName: 'CAT',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    width: 80,
    valueGetter: (_: any, row: Record<string, any>) =>
      replaceZeroByNone(row.CAT),
    renderCell: (params: GridRenderCellParams) =>
      renderValueSpanColor(
        params.row.CAT,
        [
          { color: green, upperLimit: 2 },
          { color: yellow, upperLimit: 0 },
        ],
        red,
        true
      ),
  };

  const kwdColumn: GridColDef<any> = {
    field: 'KWD',
    headerName: 'KWD',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    width: 80,
    valueGetter: (_: any, row: Record<string, any>) =>
      replaceZeroByNone(row.KWD),
    renderCell: (params: GridRenderCellParams) =>
      renderValueSpanColor(
        params.row.KWD,
        [
          { color: green, upperLimit: 3 },
          { color: yellow, upperLimit: 1 },
        ],
        red,
        true
      ),
  };

  const posTransColumn: GridColDef<any> = {
    field: 'POS',
    headerName: getTranslatedHeaderName('tableColumns.POS', 'POS'),
    sortable: true,
    minWidth: 170,
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === 'POS' ? row.name || na : '';
    },
    renderCell: (params: GridRenderEditCellParams) => {
      return (
        <span>{params.row.type === "POS" ? renderLinkCell(params.row.url, params.row.name, "", true)|| na : ""}</span>
      );
    },
  };

  const authorColumn: GridColDef<any> = {
    field: 'AUTHOR',
    headerName: getTranslatedHeaderName('tableColumns.author', 'AUTHOR'),
    sortable: true,
    width: 164,
    valueGetter: createValueGetter('AUTHOR'),
  };

  const titleColumn: GridColDef<any> = {
    field: 'TITLE',
    headerName: getTranslatedHeaderName('tableColumns.title', 'Title'),
    sortable: true,
    width: 164,
    valueGetter: createValueGetter('TITLE'),
  };

  const brandNameColumn: GridColDef<any> = {
    field: 'BRAND',
    headerName: getTranslatedHeaderName('tableColumns.brand', 'Brand'),
    sortable: true,
    width: 141,
    valueGetter: createValueGetter('BRAND'),
  };

  const normColumn: GridColDef<any> = {
    field: 'NORM',
    headerName: getTranslatedHeaderName('tableColumns.norm', 'Norm'),
    sortable: true,
    width: 80,
    headerAlign: 'center',
    align: 'center',
    valueGetter: createValueGetter('NORM'),
  };

  const statusColumn: GridColDef<any> = {
    field: 'STATUS',
    headerName: getTranslatedHeaderName('tableColumns.status', 'Status'),
    sortable: true,
    width: 102,
    valueGetter: createValueGetter('STATUS'),
    renderCell: (params: GridRenderCellParams) => {
      let status = params.row.STATUS;
      if (status) {
        status = status.toLowerCase();
        return renderTranslatedCell(status, statusTranslations, [green, red]);
      }
      return na;
    },
  };

  const availabilityColumn: GridColDef<any> = {
    field: 'AVAILABILITY',
    headerName: getTranslatedHeaderName(
      'tableColumns.availability',
      'Availability'
    ),
    sortable: true,
    minWidth: 160,
    valueGetter: (_: any, row: Record<string, any>) => {
      const av: keyof typeof avTranslations = row.AVAILABILITY || 5;
      return avTranslations[av];
    },
    renderCell: (params: GridRenderCellParams) =>
      renderTranslatedCell(params.row.AVAILABILITY || 5, avTranslations, [
        green,
        yellow,
        red,
        darkRed,
        gray,
      ], -1, true),
  };

  const availabilityBrandColumn: GridColDef<any> = {
    field: 'BRAND',
    headerName: getTranslatedHeaderName('tableColumns.brand', 'Brand'),
    sortable: true,
    minWidth: 200,
    flex: 1,
    valueGetter: createValueGetter('BRAND'),
  };

  const prodPageColumn: GridColDef<any> = {
    field: 'PROD_PAGE',
    headerName: getTranslatedHeaderName('tableColumns.prodPage', 'PROD. Page'),
    sortable: true,
    width: 120,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams) =>
      renderTranslatedCell(params.row.PROD_PAGE, yesNoTranslations, [
        green,
        red,
      ]),
  };

  const prodLinkColumn: GridColDef<any> = {
    field: 'STORE_LINK',
    headerName: getTranslatedHeaderName(
      'tableColumns.prodLink',
      'Product Link'
    ),
    sortable: true,
    valueGetter: createValueGetter('STORE_LINK'),
  };

  const priceColumn: GridColDef<any> = {
    field: 'PRICE',
    headerName: getTranslatedHeaderName('tableColumns.price', 'Price €'),
    sortable: true,
    width: 100,
    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) =>
      params ? formatValue(params, userNumberFormat, 2, 2) : na,
    sortComparator: (v1, v2) => {
      const isV1Fallback = typeof v1 === 'string' && v1 === na;
      const isV2Fallback = typeof v2 === 'string' && v2 === na;

      if (isV1Fallback && isV2Fallback) {
        return v1.localeCompare(v2);
      }

      if (isV1Fallback) return 1;
      if (isV2Fallback) return -1;

      return v1 - v2;
    },
  };

  const boldPriceColumn: GridColDef<any> = {
    ...priceColumn,
    renderCell: (params) => (
      <span style={{ fontWeight: 'bold' }}>
        {params.value !== undefined
          ? formatValue(params.value, userNumberFormat, 2, 2)
          : 'N/A'}
      </span>
    ),
  };

  const discColumn: GridColDef<any> = {
    field: 'DISC',
    headerName: getTranslatedHeaderName('tableColumns.disc', 'DISC %'),
    sortable: true,
    width: 76,
    headerAlign: 'center',
    align: 'right',
    valueGetter: createValueGetter('DISC'),
    valueFormatter: (params) =>
      params ? formatValue(params, userNumberFormat, 2, 2) : na,
    sortComparator: (v1, v2) => {
      const isV1Fallback = typeof v1 === 'string' && v1 === na;
      const isV2Fallback = typeof v2 === 'string' && v2 === na;

      if (isV1Fallback && isV2Fallback) {
        return v1.localeCompare(v2);
      }

      if (isV1Fallback) return 1;
      if (isV2Fallback) return -1;

      return v1 - v2;
    },
  };

  const ratingsColumn: GridColDef<any> = {
    field: 'RATINGS',
    headerName: getTranslatedHeaderName('tableColumns.ratings', 'Ratings'),
    sortable: true,
    align: 'center',
    headerAlign: 'center',
    width: 75,
    renderCell: (params: GridRenderCellParams) =>
      renderValueSpanColor(
        params.row.RATINGS,
        [
          { color: green, upperLimit: 50 },
          { color: orange, upperLimit: 20 },
        ],
        red
      ),
  };

  const ratingColumn: GridColDef<any> = {
    field: 'RATING',
    headerName: getTranslatedHeaderName('tableColumns.rating', 'Rating'),
    sortable: true,
    width: 73,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) =>
      renderValueSpanColor(
        params.row.RATING,
        [
          { color: green, upperLimit: 4.5 },
          { color: orange, upperLimit: 3.5 },
        ],
        red
      ),
  };

  const packshotsColumn: GridColDef<any> = {
    field: 'PACKSHOTS',
    headerName: getTranslatedHeaderName('tableColumns.packshots', 'Packshots'),
    sortable: true,
    width: 119,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams) =>
      renderValueSpanColor(
        params.row.PACKSHOTS,
        [
          { color: green, upperLimit: 3 },
          { color: orange, upperLimit: 1 },
        ],
        red
      ),
  };

  const videoColumn: GridColDef<any> = {
    field: 'VIDEO',
    headerName: getTranslatedHeaderName('tableColumns.video', 'Video'),
    sortable: true,
    width: 119,
    headerAlign: 'center',
    align: 'center',
    valueGetter: createValueGetter('VIDEO'),
  };

  const packshotsDescColumn: GridColDef<any> = {
    field: 'PACKSHOTS_DESC',
    headerName: getTranslatedHeaderName(
      'tableColumns.packshotsDesc',
      'Packshots Description'
    ),
    sortable: true,
    width: 119,
    valueGetter: createValueGetter('PACKSHOTS_DESC'),
  };

  const videoDescColumn: GridColDef<any> = {
    field: 'VIDEO_DESC',
    headerName: getTranslatedHeaderName(
      'tableColumns.videoDesc',
      'Video Description'
    ),
    sortable: true,
    width: 119,
    valueGetter: createValueGetter('VIDEO_DESC'),
  };

  const leafletColumn: GridColDef<any> = {
    field: 'LEAFLET',
    headerName: getTranslatedHeaderName('tableColumns.leaflet', 'Leaflet'),
    sortable: true,
    width: 94,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams) =>
      renderTranslatedCell(params.row.LEAFLET, yesNoTranslations, [green, red]),
  };

  const descriptionColumn: GridColDef<any> = {
    field: 'DESCRIPTION',
    headerName: getTranslatedHeaderName(
      'tableColumns.description',
      'Description'
    ),
    sortable: true,
    width: 260,
    valueGetter: createValueGetter('DESCRIPTION'),
  };

  const descriptionLengthColumn: GridColDef<any> = {
    field: 'DESC_LENGTH',
    headerName: getTranslatedHeaderName(
      'tableColumns.descriptionLength',
      'Description Length'
    ),
    sortable: true,
    width: 120,
    headerAlign: 'center',
    align: 'center',
    valueGetter: createValueGetter('DESC_LENGTH'),
  };

  const teaserColumn: GridColDef<any> = {
    field: 'TEASER',
    headerName: getTranslatedHeaderName('tableColumns.teaser', 'Teaser'),
    sortable: true,
    width: 260,
    valueGetter: createValueGetter('TEASER'),
  };

  const indicationColumn: GridColDef<any> = {
    field: 'INDICATION',
    headerName: getTranslatedHeaderName(
      'tableColumns.indication',
      'Indication'
    ),
    sortable: true,
    width: 260,
    valueGetter: createValueGetter('INDICATION'),
  };

  const canonicalColumn: GridColDef<any> = {
    field: 'CANONICAL',
    headerName: getTranslatedHeaderName('tableColumns.canonical', 'Canonical'),
    sortable: true,
    width: 237,
    valueGetter: createValueGetter('CANONICAL'),
  };

  const updatedDateColumn: GridColDef<any> = {
    field: 'UPDATED',
    headerName: getTranslatedHeaderName('tableColumns.edited', 'Edited'),
    sortable: true,
    width: 135,
    valueGetter: (_: any, row: Record<string, any>) => {
      if (!row?.UPDATED && !row?.updated) return na;
      const parsedDate = parseCustomDate((row.UPDATED || row.updated), ["T", "-", ":"]);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
  };

  const updatedPPDateColumn: GridColDef<any> = {
    field: 'UPDATED_PP',
    headerName:
      getTranslatedHeaderName('tableColumns.edited', 'Edited') + ' PDP',
    sortable: true,
    width: 135,
    valueGetter: (_: any, row: Record<string, any>) => {
      if (!row?.UPDATED_PP) return na;
      const parsedDate = parseCustomDate(row.UPDATED_PP, ['T', '-', ':']);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
  };

  const actionsColumn = (
    onClickHandler?: (params: any, event: React.MouseEvent<HTMLElement>) => void
  ): GridColDef<any> => ({
    field: 'actions',
    headerName:
      getTranslatedHeaderName('tableColumns.action', 'Action'),
    type: 'actions',
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<MoreVertIcon />}
        label="Actions"
        onClick={(event) => {
          if (onClickHandler) {
            onClickHandler(params, event); // Call the passed handler function
          } else {
            if (params.row.ACCESS_ID) {
              console.log('DB ID: ' + params.row.ACCESS_ID);
            } else {
              console.log('No access ID available');
            }
          }
        }}
      />,
    ],
  });

  const actionsTreeColumnNative = (
    getActions: (params: GridRowParams) => React.ReactElement[],
    displayConditions: (params: GridRowParams) => boolean = () => true
  ): GridActionsColDef<any> => ({
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params: GridRowParams) => {
      return displayConditions(params) ? getActions(params) : [];
    },
  });
  

  const actionsTreeColumn = (
    onClickHandler?: (params: any, event: React.MouseEvent<HTMLElement>) => void,
    displayConditions = (params: any) => params.row?.type === "instance",
  ): GridActionsColDef<any> => ({
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        label="Actions"
        showInMenu
        sx={{
          display: displayConditions(params) ? "" : "none"
        }}
        onClick={(event) => {
          event.stopPropagation()
          if (onClickHandler) {
            onClickHandler(params, event);
          }
        }}
      />,
    ],
  });

  const sellerColumn: GridColDef<any> = {
    field: 'SELLER',
    headerName: getTranslatedHeaderName('tableColumns.seller', 'Seller'),
    sortable: true,
    width: 200,
    valueGetter: createValueGetter('SELLER'),
  };

  const skuColumn: GridColDef<any> = {
    field: 'SKU',
    headerName: getTranslatedHeaderName('tableColumns.PZN', 'PZN'),
    sortable: true,
    width: 92,
    valueGetter: createValueGetter(['SKU', 'PZN']),
  };

  const productNameColumn: GridColDef<any> = {
    field: 'product_name',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    sortable: true,
    valueGetter: createValueGetter('product_name'),
    minWidth: 257,
    flex: 1,
  };

  const productTransColumn: GridColDef<any> = {
    field: 'name',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    sortable: true,
    flex: 2,
    getSortComparator: (sortDirection) => {
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 == null || value1 === na) {
          return 1;
        }
        if (value2 == null || value2 === na) {
          return -1;
        }
        return (
          modifier *
          gridStringOrNumberComparator(value1, value2, cellParams1, cellParams2)
        );
      };
    },
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === 'product' ? row.name || na : row.combinedNames || na
    }
  };

  const productColumn: GridColDef<any> = {
    field: 'PRODUCT',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    sortable: true,
    valueGetter: createValueGetter('PRODUCT'),
  };

  const productLinkColumn: GridColDef<any> = {
    ...productColumn,
    width: 353,
    renderCell: (params: GridRenderCellParams) => {
      const pos = params.row.pos || params.row.POS || params.row.merchant_name;
      const tooltip = pos
        ? getTranslatedHeaderName(
            'pages.analytics.tooltip.pdpTooltip',
            'Visit PDP at'
          ) + ` ${pos}`
        : '';
      return renderLinkCell(
        params.row.URL ?? params.row.url,
        params.row.PRODUCT ?? params.row.product,
        tooltip
      );
    },
    sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
    valueGetter: productColumn.valueGetter,
  };

  const propertyTransColumn: GridColDef<any> = {
    field: 'property',
    headerName: getTranslatedHeaderName('tableColumns.property', 'Property'),
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
        return <span>{params.row.type === "product" ? "" : params.value}</span>;
    },
  };

  const priceValueColumn: GridColDef<any> = {
    field: "value",
    headerName: getTranslatedHeaderName("tableColumns.value", "value"),
    align: "right",
    headerAlign: "right",
    sortable: true,
    getSortComparator: (sortDirection) => {
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 == null || value1 === na) {
          return 1;
        }
        if (value2 == null || value2 === na) {
          return -1;
        }
        return (
          modifier *
          gridStringOrNumberComparator(value1, value2, cellParams1, cellParams2)
        );
      };
    },
    width: 80,
    renderCell: (params: GridRenderCellParams) => {
        return <span>{formatValue(params.value, userNumberFormat, 2, 2) + ' ' + getCurrency(params.row.currency)}</span>;
    },

  };

  const sizeColumn: GridColDef<any> = {
    field: 'SIZE',
    headerName: getTranslatedHeaderName('tableColumns.size', 'Size'),
    sortable: true,
    width: 75,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => <span>{params.value.replace(/\s/g, '')}</span>,
    valueGetter: createValueGetter('SIZE'),
  };

  const labelColumn: GridColDef<any> = {
    field: 'name',
    headerName: getTranslatedHeaderName('tableColumns.label', 'Label'),
    minWidth: 150,
    flex: 1,
    editable: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return params.row.name ? <LabelColumns params={params} /> : na;
    },
    getSortComparator: (sortDirection) => {
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 == null || value1 === na) {
          return 1;
        }
        if (value2 == null || value2 === na) {
          return -1;
        }
        return (
          modifier *
          gridStringOrNumberComparator(value1, value2, cellParams1, cellParams2)
        );
      };
    },
  };

  const setupLabelColumn: GridColDef<any> = {
    field: 'labels',
    headerName: getTranslatedHeaderName('tableColumns.label', 'Label'),
    width: 400,
    flex: 1,
    sortable: true,
    valueGetter: (params: Label[]) => {
      return params?.map((label: Label) => label.label_name).join(', ') || na;
    },
    getSortComparator: (sortDirection) => {
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 == null || value1 === na) {
          return 1;
        }
        if (value2 == null || value2 === na) {
          return -1;
        }
        return (
          modifier *
          gridStringOrNumberComparator(value1, value2, cellParams1, cellParams2)
        );
      };
    },
    renderCell: (params: GridRenderCellParams) => (
      <LabelsCell labels={params.row} params={params} />
    ),
    filterOperators: [
      {
        label: getTranslatedHeaderName('general.filter.contains', 'contains'),
        value: 'contains',
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }

          const filterValue = filterItem.value.toLowerCase();
          return (_, row) => {
            return row.labels.some((label: Label) =>
              label.label_name.toLowerCase().includes(filterValue)
            );
          };
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: 'text' },
      },
      {
        label: getTranslatedHeaderName(
          'general.filter.doesNotContain',
          'does not contain'
        ),
        value: 'doesNotContain',
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.every(
              (label: Label) =>
                !label.label_name.toLowerCase().includes(filterValue)
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: 'text' },
      },
      {
        label: getTranslatedHeaderName('general.filter.equals', 'equals'),
        value: 'equals',
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.some(
              (label: Label) => label.label_name.toLowerCase() === filterValue
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: 'text' },
      },
      {
        label: getTranslatedHeaderName(
          'general.filter.doesNotEqual',
          'does not equal'
        ),
        value: 'doesNotEqual',
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.every(
              (label: Label) => label.label_name.toLowerCase() !== filterValue
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: 'text' },
      },
      {
        label: getTranslatedHeaderName(
          'general.filter.startsWith',
          'starts with'
        ),
        value: 'startsWith',
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.some((label: Label) =>
              label.label_name.toLowerCase().startsWith(filterValue)
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: 'text' },
      },
      {
        label: getTranslatedHeaderName('general.filter.endsWith', 'ends with'),
        value: 'endsWith',
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          const filterValue = filterItem.value.toLowerCase();
          return (_, row) =>
            row.labels.some((label: Label) =>
              label.label_name.toLowerCase().endsWith(filterValue)
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: 'text' },
      },
      {
        label: getTranslatedHeaderName('general.filter.isEmpty', 'Is empty'),
        value: 'isEmpty',
        getApplyFilterFn: () => {
          return (_, row) => row.labels.length === 0;
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: 'text', disabled: true },
      },
      {
        label: getTranslatedHeaderName(
          'general.filter.isNotEmpty',
          'Is not empty'
        ),
        value: 'isNotEmpty',
        getApplyFilterFn: () => {
          return (_, row) => row.labels.length > 0;
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: 'text', disabled: true },
      },
      {
        label: getTranslatedHeaderName('general.filter.isAnyOf', 'Is any of'),
        value: 'isAnyOf',
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value || !Array.isArray(filterItem.value)) {
            return null;
          }
          const filterValues = filterItem.value.map((val) => val.toLowerCase());
          return (_, row) =>
            row.labels.some((label: Label) =>
              filterValues.includes(label.label_name.toLowerCase())
            );
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: { type: 'text' },
      },
    ],
  };
  const commentColumn: GridColDef<any> = {
    field: 'comment',
    editable: true,
    headerName: getTranslatedHeaderName('tableColumns.comment', 'Comment'),
    minWidth: 300,
    flex: 1,
    sortable: true,
    valueGetter: createValueGetter('comment'),
  };

  const itemsColumn: GridColDef<any> = {
    field: "items",
    headerName: getTranslatedHeaderName("tableColumns.items", "Units"),
    renderHeader: (params: any) => (
      <Tooltip title={t("tableColumns.itemsTooltip", "Units")}>
        {params.colDef.headerName}
      </Tooltip>
    ),
    minWidth: 90,
    sortable: true,
    getSortComparator: (sortDirection) => {
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 == null || value1 === na) {
          return 1;
        }
        if (value2 == null || value2 === na) {
          return -1;
        }
        return (
          modifier *
          gridStringOrNumberComparator(value1, value2, cellParams1, cellParams2)
        );
      };
    },
    align: "center",
    headerAlign: "center",
    valueGetter: createValueGetter('items')
  };

  const typeColumn: GridColDef<any> = {
    field: 'type',
    headerName: getTranslatedHeaderName('tableColumns.type', 'Type'),
    width: 90,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <span
          style={{ fontWeight: 500, cursor: 'pointer' }}
          onClick={() => navigate('/setup/keywords')}
        >
          {formatUserNumber(params.value)}
        </span>
      );
    },
  };

  const keywordColumn: GridColDef<any> = {
    field: 'keyword',
    headerName: getTranslatedHeaderName('tableColumns.keyword', 'Keyword'),
    minWidth: 400,
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return <span style={{ fontWeight: 700 }}>{params.value}</span>;
    },
  };

  const categoryColumn: GridColDef<any> = {
    field: 'title',
    headerName: getTranslatedHeaderName('tableColumns.category', 'Category'),
    minWidth: 400,
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return <span style={{ fontWeight: 700 }}>{params.value}</span>;
    },
  };

  const categoryLinkColumn: GridColDef<any> = {
    ...categoryColumn,
    renderCell: (params: GridRenderCellParams) =>
      renderLinkCell(params.row.url, params.row.title),
    valueGetter: createValueGetter('title'),
  };

  const weightColumn: GridColDef<any> = {
    field: 'weight',
    headerName: getTranslatedHeaderName('tableColumns.weight', 'Weight'),
    width: 80,

    sortable: true,
    valueGetter: createValueGetter('weight'),
  };

  const kwdTypeColumn: GridColDef<any> = {
    field: 'type',
    headerName: getTranslatedHeaderName('tableColumns.type', 'Type'),
    sortable: true,
    width: 100,
    valueGetter: createValueGetter('type'),
  };

  const idColumn: GridColDef<any> = {
    field: 'id',
    headerName: getTranslatedHeaderName('tableColumns.ID', 'ID'),
    headerAlign: 'center',
    align: 'right',
    width: 80,
    sortable: true,
    valueGetter: createValueGetter('id'),
  };

  const collaborationColumn: GridColDef<any> = {
    field: 'collaboration',
    headerName: getTranslatedHeaderName(
      'tableColumns.collaboration',
      'Collaboration'
    ),
    width: 160,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => (
      <CollaborationCell {...params} />
    ),
  };

  const makerCollaborationColumn: GridColDef<any> = {
    field: 'collaboration',
    headerName: getTranslatedHeaderName(
      'tableColumns.collaboration',
      'Collaboration'
    ),
    width: 160,
    sortable: true,
    renderCell: (params: GridRenderCellParams) =>
      params.row.type === 'market' ? <CollaborationCell {...params} /> : '',
  };

  const ownerLabelsColumn: GridColDef<any> = {
    field: "owner",
    headerName: getTranslatedHeaderName("tableColumns.owner", "Owner"),
    minWidth: 150,
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <span>
          {params.row.owner?.first_name} {params.row.owner?.last_name}
        </span>
      );
    },
  };

  const bnTypeColumn: GridColDef<any> = {
    field: 'type',
    headerName: getTranslatedHeaderName('tableColumns.type', 'Type'),
    sortable: true,
    width: 50,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) => getTypeImage(params.row.type),
    valueGetter: createValueGetter('type'),
  };

  const ownerColumn: GridColDef<any> = {
    field: 'owner',
    headerName: getTranslatedHeaderName('tableColumns.owner', 'Owner'),
    sortable: true,
    width: 150,
  };

  const connectedColumn: GridColDef<any> = {
    field: 'status',
    headerName: getTranslatedHeaderName('tableColumns.status', 'Status'),
    sortable: true,
    width: 150,
    renderCell: (params: GridRenderCellParams) =>
      getChip(
        params.row.status === 1
          ? getTranslatedHeaderName('tableColumns.connected', 'Conntected')
          : getTranslatedHeaderName('tableColumns.noVisits', 'no visits'),
        params.row.status === 1 ? green : orange
      ),
    valueGetter: (_: any, row: Record<string, any>) =>
      row.status === 1
        ? getTranslatedHeaderName('tableColumns.connected', 'Connected')
        : getTranslatedHeaderName('tableColumns.noVisits', 'no visits'),
  };

  const instanceActivityStatusColumn: GridColDef<any> = {
    field: "activity_status",
    headerName: getTranslatedHeaderName("tableColumns.status", "Status"),
    sortable: true,
    width: 150,
    renderCell: (params: GridRenderCellParams) =>
      params.row.type === "instance" ?
        getChip(
          params.row.activity_status === 1
            ? getTranslatedHeaderName("general.active", "Active")
            : getTranslatedHeaderName("general.removed", "Removed"),
          params.row.activity_status === 1 ? green : red
        ) :
        "",
    valueGetter: (_: any, row: Record<string, any>) =>
      row.type === "instance" ?
        row.activity_status === 1
          ? getTranslatedHeaderName("general.active", "Active")
          : getTranslatedHeaderName("general.removed", "Removed") :
          "",
  }

  const connectedStatusColumn: GridColDef<any> = {
    ...connectedColumn,
    width: 120,
    renderCell: (params: GridRenderCellParams) =>
      getChip(
        params.row.status === 1
          ? getTranslatedHeaderName('tableColumns.connected', 'Conntected')
          : getTranslatedHeaderName('tableColumns.disabled', 'disabled'),
        params.row.status === 1 ? green : orange
      ),
    valueGetter: (_: any, row: Record<string, any>) =>
      row.status === 1
        ? getTranslatedHeaderName('tableColumns.connected', 'Connected')
        : getTranslatedHeaderName('tableColumns.disabled', 'disabled'),
  };

  const availabilitySizeColumn: GridColDef<any> = {
    field: 'QUANTITY',
    headerName: getTranslatedHeaderName('tableColumns.size', 'Size'),
    sortable: true,
    width: 75,
    align: 'center',
    headerAlign: 'center',
    valueGetter: createValueGetter('QUANTITY'),
  };

  const availabilityProductNameColumn: GridColDef<any> = {
    field: 'PRODUCT',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    sortable: true,
    valueGetter: createValueGetter('PRODUCT'),
    renderCell: (params: GridRenderCellParams) => {
      if(!params.row.PRODUCT) return na;
      const usesManuTitle = params.row.USE_MANUFACTURER_TITLE;
      const style = { color: usesManuTitle ? gray : theme.palette.text.primary };
      const tooltip = usesManuTitle ? t("general.tooltip.manufacturerProduct") : t("general.tooltip.merchantProduct");
      return renderTooltipText(tooltip, params.row.PRODUCT, style);
    },
    minWidth: 200,
    flex: 1,
  };

  const productPznColumn: GridColDef<any> = {
    field: 'product.pzn',
    headerName: getTranslatedHeaderName('tableColumns.PZN', 'PZN'),
    width: 100,
    sortable: true,
    valueGetter: (_, row) => {
      return row?.product?.pzn;
    },
  };

  const productAllBrandColumn: GridColDef<any> = {
    field: 'BRAND',
    headerName: getTranslatedHeaderName('tableColumns.brand', 'Brand'),
    width: 200,
    sortable: true,
    valueGetter: (_, row) => {
      return row?.BRAND?.BRAND || na;
    },
    renderCell: (params: GridRenderCellParams) => (
      <BrandCell
        brand={params.row.BRAND}
        isSubbrand={params.row.BRAND?.ID !== params.row.BRAND?.ID_parent}
      />
    ),
  };

  const productBrandColumn: GridColDef<any> = {
    field: 'brand',
    headerName: getTranslatedHeaderName('tableColumns.brand', 'Brand'),
    minWidth: 150,
    flex: 1,
    sortable: true,
    valueGetter: (_, row) => {
      return row?.brand?.BRAND;
    },
    renderCell: (params: GridRenderCellParams) => (
      <BrandCell
        brand={params.row.brand}
        manufacturer={params.row.product.manufacturer_id}
      />
    ),
  };

  const productSubBrandColumn: GridColDef<any> = {
    field: 'subbrand',
    headerName: getTranslatedHeaderName(
      'tableColumns.subbrand',
      'Brand.subbrand'
    ),
    minWidth: 150,
    flex: 1,
    sortable: true,
    valueGetter: (_, row) => {
      return row?.subbrand?.BRAND;
    },
    renderCell: (params: GridRenderCellParams) => (
      <BrandCell
        brand={params.row.subbrand}
        manufacturer={params.row.product.manufacturer_id}
      />
    ),
  };

  const setupProductColumn: GridColDef<any> = {
    field: 'setup_product',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    minWidth: 150,
    sortable: true,
    flex: 1,
    valueGetter: (_, row) => {
      return row?.product?.name;
    },
    renderCell: (params: GridRenderCellParams) => {
      return <span style={{ fontWeight: 600 }}>{params.row.product.name}</span>;
    },
  };

  const boldProductColumn: GridColDef<any> = {
    field: 'PRODUCT',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    minWidth: 328,
    sortable: true,
    flex: 1,
    valueGetter: (_, row) => {
      return row?.PRODUCT || na;
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <span style={{ fontWeight: 600 }}>{params.row.PRODUCT || na}</span>
      );
    },
  };

  const productFocusColumn: GridColDef<any> = {
    field: 'focus',
    headerName: getTranslatedHeaderName('tableColumns.focus', 'Focus'),
    width: 60,
    align: 'center',
    headerAlign: 'center',
    sortable: true,
    valueGetter: (_, row) => {
      return row?.product?.focus ? 'yes' : 'no';
    },
  };

  const productAvpColumn: GridColDef<any> = {
    field: 'avp',
    headerName: getTranslatedHeaderName('tableColumns.avp', 'AVP'),
    width: 75,
    align: 'right',
    headerAlign: 'center',
    sortable: true,
    valueGetter: (_, row) => {
      return row?.product?.avp || na;
    },
  };

  const productStatusColumn: GridColDef<any> = {
    field: 'status',
    headerName: getTranslatedHeaderName('tableColumns.status', 'Status'),
    width: 80,
    sortable: true,
    valueGetter: (_, row) => {
      return row?.product?.status || na;
    },
    renderCell: (params: GridRenderCellParams) => {
      const status = params.row.product.status_long;

      return (
        <span style={{ color: (status === "active" ? green : red) as unknown as string}}>
          {params.row.product.status_long || na}
        </span>
      );
    },
  };

  const productSizeColumn: GridColDef<any> = {
    field: 'size',
    headerName: getTranslatedHeaderName('tableColumns.size', 'Size'),
    width: 75,
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (_, row) => {
      return row?.product?.quantity && row?.product?.unit
        ? row?.product?.quantity + row?.product?.unit
        : na;
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <span>
          {params.row.product.quantity + params.row.product.unit || na}
        </span>
      );
    },
  };

  const availabilityOldStatusColumn: GridColDef<any> = {
    field: 'OLD_STATUS',
    headerName: getTranslatedHeaderName(
      'tableColumns.oldStatus',
      'Availability'
    ),
    sortable: true,
    minWidth: 150,
    valueGetter: (_: any, row: Record<string, any>) => {
      const av: keyof typeof avTranslations = row.OLD_STATUS || 5;
      return avTranslations[av];
    },
    renderCell: (params: GridRenderCellParams) =>
      renderTranslatedCell(params.row.OLD_STATUS || 5, avTranslations, [
        green,
        yellow,
        red,
        darkRed,
        gray,
    ]),
  };
  const changedDateColumn: GridColDef<any> = {
    field: 'AVAILABILITY_CHANGED',
    headerName: getTranslatedHeaderName('tableColumns.changed', 'Changed'),
    sortable: true,
    width: 135,
    valueGetter: (_: any, row: Record<string, any>) => {
      if (!row?.AVAILABILITY_CHANGED) return na;
      const parsedDate = parseCustomDate(row.AVAILABILITY_CHANGED, [
        'T',
        '-',
        ':',
      ]);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
  };

  const lastChangedDateColumn: GridColDef<any> = {
    field: 'LAST_CHECKED',
    headerName: getTranslatedHeaderName(
      'tableColumns.lastChecked',
      'Last Checked'
    ),
    sortable: true,
    width: 135,
    valueGetter: (_: any, row: Record<string, any>) => {
      if (!row?.LAST_CHECKED) return na;
      const parsedDate = parseCustomDate(row.LAST_CHECKED, ['T', '-', ':']);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
  };

  const rightAlignedColumn: GridColDef<any> = {
    field: '',
    headerName: '',
    headerAlign: 'center',
    align: 'right',
    sortable: true,
  };

  const leftAlignedColumn: GridColDef<any> = {
    field: "",
    headerName: "",
    headerAlign: "center",
    align: "left",
    sortable: true,
  };

  const centeredColumn: GridColDef<any> = {
    field: '',
    headerName: '',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
  };

  const datetimeColumn: GridColDef<any> = {
    field: "",
    headerName: "",
    headerAlign: "center",
    align: "center",
    width: 135,
    renderCell: (params: GridRenderEditCellParams) => {
      const parsedDate = parseCustomDate(params.value, ['T', '-', ':']);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
    sortable: true,
  };

  const createChipColumn = (getColor: (params: any) => string): GridColDef<any> => ({
    field: "",
    headerName: "",
    headerAlign: "center",
    align: "center",
    width: 120,
    renderCell: (params: GridRenderCellParams) =>
      getChip(params.formattedValue, getColor(params)),
  });

  const createDropdownColumn = (
    onChange: (value: any, key: string, params: any) => void,
    optionsKey: string = 'options',
    valueKey: string = 'value',
    labelKey: string = 'label',
    idKey: string = 'id',
    translateFn: (text: string) => string = (text) => text,
    hiddenIds: any[] = [],
    defaultFallback: any = ''
  ): GridColDef<any> => ({
    field: '',
    headerName: '',
    headerAlign: 'center',
    align: 'center',
    width: 250,
    renderCell: (params: GridRenderCellParams) => {
      const options = params.row[optionsKey] || []; // Ensure options is always an array
      let selectedValue = params.row[valueKey]?.[idKey] || defaultFallback; // Use the idKey for the value

      return (
        <FormControl variant="standard" margin="normal" fullWidth>
          <Select
            value={selectedValue}
            onChange={(event) => {
              const updatedId = event.target.value;
              const updatedValue = options.find(
                (option: any) =>
                  (typeof option === 'string' ? option : option[idKey]) === updatedId
              );
              params.row[valueKey] = updatedValue || {};
              onChange(updatedValue, valueKey, params);
            }}
            fullWidth
            displayEmpty
          >
            {options.map((option: any) => {
              const optionValue = typeof option === 'string' ? option : option[idKey];
              const rawLabel = typeof option === 'string' ? option : option[labelKey];
              const optionLabel = translateFn(rawLabel);

              return (
                <MenuItem
                  key={optionValue}
                  value={optionValue}
                  style={{
                    display: hiddenIds.includes(optionValue) ? 'none' : 'block'
                  }}
                >
                  {optionLabel}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    },
  });

  const createMultiSelectDropdownColumn = (
    onChange: (values: any[], key: string, params: any) => void,
    optionsKey: string = 'options', // Key for retrieving dropdown options
    valueKey: string = 'value', // Key for retrieving and updating the selected values
    labelKey: string = '', // Key for retrieving display labels from objects
    idKey: string = '', // Key for uniquely identifying options (e.g., privilege_id, id)
    translateFn: (text: string) => string = (text) => text, // Default translation function
    hiddenIds: string[] = [] // New parameter to specify hidden IDs
  ): GridColDef<any> => ({
    field: '',
    headerName: '',
    headerAlign: 'center',
    align: 'center',
    width: 250, // Adjust column width as needed
    renderCell: (params: GridRenderCellParams) => {
      const options = params.row[optionsKey] || []; // Ensure options is always an array
      const selectedValues = (params.row[valueKey] || []).map((val: any) =>
        typeof val === 'string' ? val : val[idKey]
      ); // Use idKey for selected values

      return (
        <FormControl variant="standard" margin="normal" fullWidth>
          <Select
            multiple
            value={selectedValues}
            onChange={(event) => {
              const updatedIds = event.target.value as any[];
              const updatedValues = updatedIds.map((id) =>
                options.find((option: any) =>
                  typeof option === 'string' ? option === id : option[idKey] === id
                )
              );
              params.row[valueKey] = updatedValues.filter(Boolean);
              onChange(updatedValues, valueKey, params);
            }}
            fullWidth
            renderValue={(selected) =>
              (selected as any[])
                .map((id) => {
                  const option = options.find((opt: any) =>
                    typeof opt === 'string' ? opt === id : opt[idKey] === id
                  );
                  const rawLabel = typeof option === 'string' ? option : option[labelKey];
                  return translateFn(rawLabel);
                })
                .join(' + ')
            }
          >
            {options.map((option: any) => {
              const optionValue = typeof option === 'string' ? option : option[idKey];
              const rawLabel = typeof option === 'string' ? option : option[labelKey];
              const optionLabel = translateFn(rawLabel);

              const isHidden = hiddenIds.includes(optionValue);

              return (
                <MenuItem
                  key={optionValue}
                  value={optionValue}
                  style={{
                    display: isHidden ? 'none' : 'block', // Completely remove space for hidden items
                  }}
                >
                  <Checkbox
                    checked={selectedValues.includes(optionValue)}
                    style={{ display: isHidden ? 'none' : 'inline-flex' }} // Hide checkbox for hidden items
                  />
                  {optionLabel}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    },
  });

  const percentageColumn: GridColDef<any> = {
    field: '',
    headerName: '',
    sortable: true,
    width: 80,
    align: 'right',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) =>
      renderPercentage(params.value, userNumberFormat),
  };

  const coloredPercentageColumn: GridColDef<any> = {
    ...percentageColumn,
    renderCell: (params: GridRenderCellParams) =>
      renderPercentage(params.value, userNumberFormat, true),
  };

  const groupingPOSColDef = {
    headerName: getTranslatedHeaderName("tableColumns.POS", "POS"),
    headerClassName: "grouping-header",
    field: "pos",
    sortable: false,
    width: 170,
    hideDescendantCount: true,
    valueGetter: (_: any, row: Record<string, any>) => {
      return row.type === 'parent' ? row.pos : '';
    },
  };

  const mediaLocationColumn: GridColDef = {
    headerName: getTranslatedHeaderName('tableColumns.location', 'Location'),
    field: 'location',
    width: 80,
    sortable: false,
    valueGetter: (_: any, row: Record<string, any>) => {
      if (row.location === 'gallery') {
        return getTranslatedHeaderName('filters.gallery', 'Gallery');
      } else if (row.location === 'description') {
        return getTranslatedHeaderName('filters.description', 'Description');
      }
      return na;
    },
  };

  const mediaColumn: GridColDef = {
    field: 'media',
    headerName: getTranslatedHeaderName('tableColumns.media', 'Media'),
    sortable: false,
    width: 215,
    renderCell: (params: GridRenderEditCellParams) => (
      <ImageCell id={params.row.id} imageUrl={params.row.media} />
    ),
  };

  const groupedBrandColumn: GridColDef = {
    field: 'brand',
    headerName: getTranslatedHeaderName('tableColumns.brand', 'Brand'),
    width: 200,
    sortable: false,
    valueGetter: (_, row) => {
      return row.type === 'parent' ? row.brand?.BRAND || na : '';
    },
    renderCell: (params: GridRenderCellParams) =>
      params.row.type === 'parent' ? (
        <BrandCell
          brand={params.row.brand}
          isSubbrand={params.row.brand?.ID !== params.row.brand?.ID_parent}
          height="51px"
        />
      ) : (
        ''
      ),
  };

  const groupedProductTitleColumn: GridColDef = {
    field: 'title',
    headerName: getTranslatedHeaderName('tableColumns.product', 'Product'),
    minWidth: 282,
    flex: 1,
    sortable: false,
    valueGetter: (_, row) => {
      return row.type === 'parent' ? row.title || na : '';
    },
    renderCell: (params: GridRenderEditCellParams) =>
      params.row.type === 'parent'
        ? renderLinkCell(
            params.row.url,
            params.row.title,
            getTranslatedHeaderName('pages.analytics.tooltip.pdp', '')
          )
        : '',
  };

  const groupedSKUColumn: GridColDef = {
    field: 'sku',
    headerName: getTranslatedHeaderName('tableColumns.PZN', 'PZN'),
    width: 80,
    sortable: false,
    valueGetter: (_, row) => {
      return row.type === 'parent' ? row.sku || na : '';
    },
  };

  const timestampColumn: GridColDef = {
    field: "timestamp",
    headerName: getTranslatedHeaderName("tableColumns.timestamp", "Time Stamp"),
    width: 115,
    sortable: false,
    valueGetter: (_: any, row: Record<string, any>) => {
      const parsedDate = parseCustomDate(row.timestamp, ['T', '-', ':']);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
  };

  const companyColumn: GridColDef = {
    field: 'company',
    headerName: getTranslatedHeaderName('tableColumns.company', 'Company'), 
    minWidth: 150,
    flex: 1,
  };

  const usersMakerColumn: GridColDef = {
    field: 'maker',
    headerName: getTranslatedHeaderName('tableColumns.maker', 'Maker'),
    minWidth: 150,
    flex: 1,
  };

  const privilegeColumns: GridColDef = {
    field: 'privilege',
    headerName: getTranslatedHeaderName('tableColumns.privilege', 'Privilege'),
    minWidth: 120,
    flex: 1,
  };

  const roleColumns: GridColDef = {
    field: 'role',
    headerName: getTranslatedHeaderName('tableColumns.role', 'Role'),
    width: 120,
  };

  const lastLoginColumn: GridColDef = {
    field: 'last_login',
    headerName: getTranslatedHeaderName('tableColumns.lastLogin', 'Last login'),
    width: 180,
    renderCell: (params: GridRenderEditCellParams) => {
      if (!params.row.last_login) return na;
      const parsedDate = parseCustomDate(params.row.last_login, ['T', '-', ':']);
      const result = formatValue(
        parsedDate,
        userNumberFormat,
        undefined,
        undefined,
        true,
        true
      );
      return result;
    },
  };

  const lastActiveColumn: GridColDef = {
    field: 'daysAgo',
    headerName: getTranslatedHeaderName('tableColumns.lastActive', 'Last Active'),
    width: 180,
  };

  const mailColumn: GridColDef = {
    field: 'mail',
    headerName: getTranslatedHeaderName('tableColumns.mail', 'Mail'),
    width: 200,
  }


  return {
    createRawValueGetter,
    createValueGetter,
    timeAddedColumn,
    connectedStatusColumn,
    idColumn,
    posColumn,
    posTransColumn,
    groupingTransColDef,
    timeLeadTransColumn,
    sellerColumn,
    leadsColumn,
    skuColumn,
    brandNameColumn,
    productLinkColumn,
    sizeColumn,
    normColumn,
    statusColumn,
    availabilityColumn,
    prodPageColumn,
    prodLinkColumn,
    productColumn,
    priceColumn,
    boldPriceColumn,
    discColumn,
    ratingsColumn,
    ratingStarsColumn,
    ratingNumberColumn,
    packshotsColumn,
    videoColumn,
    packshotsDescColumn,
    videoDescColumn,
    leafletColumn,
    descriptionColumn,
    descriptionLengthColumn,
    teaserColumn,
    indicationColumn,
    canonicalColumn,
    updatedDateColumn,
    updatedPPDateColumn,
    conversionRateColumn,
    actionsColumn,
    ratingColumn,
    createdColumn,
    updatedColumn,
    configStateColumn,
    expiresColumn,
    demoColumn,
    domainColumn,
    propertyColumn,
    manufacturerNameColumn,
    dateColumn,
    coloredDateColumn,
    pznColumn,
    pznTransColumn,
    propertyTransColumn,
    avgColumn,
    impactColumn,
    reviewColumn,
    labelColumn,
    commentColumn,
    itemsColumn,
    typeColumn,
    collaborationColumn,
    ownerColumn,
    ownerLabelsColumn,
    bnTypeColumn,
    connectedColumn,
    createdOnlyDateColumn,
    createdBNComponentDateColumn,
    updatedBNComponentDateColumn,
    updatedOnlyDateColumn,
    shareColumn,
    top3Column,
    top10Column,
    avgSerpColumn,
    avgRatingsColumn,
    sovSkuColumn,
    sovKeywordColumn,
    sovProductColumn,
    productTransColumn,
    keywordColumn,
    kwdTypeColumn,
    setupLabelColumn,
    weightColumn,
    categoryColumn,
    categoryLinkColumn,
    productNameColumn,
    productPznColumn,
    productBrandColumn,
    productSubBrandColumn,
    setupProductColumn,
    productAvpColumn,
    productStatusColumn,
    productFocusColumn,
    productSizeColumn,
    authorColumn,
    titleColumn,
    availabilitySizeColumn,
    availabilityBrandColumn,
    availabilityOldStatusColumn,
    availabilityProductNameColumn,
    changedDateColumn,
    lastChangedDateColumn,
    priceValueColumn,
    makerCollaborationColumn,
    posLinkColumn,
    productAllBrandColumn,
    boldProductColumn,
    pdpColumn,
    kwdColumn,
    catColumn,
    manufacturerNameColumn2,
    coloredPercentageColumn,
    percentageColumn,
    groupingPOSColDef,
    mediaLocationColumn,
    mediaColumn,
    groupedBrandColumn,
    groupedProductTitleColumn,
    groupedSKUColumn,
    timestampColumn,
    rightAlignedColumn,
    leftAlignedColumn,
    centeredColumn,
    datetimeColumn,
    createChipColumn,
    createDropdownColumn,
    createMultiSelectDropdownColumn,
    demoComponentColumn,
    restrictionColumn,
    companyColumn,
    usersMakerColumn,
    privilegeColumns,
    roleColumns,
    lastLoginColumn,
    lastActiveColumn,
    mailColumn,
    systemColumn,
    pageColumn,
    ipAddressColumn,
    timeOfVisitColumn,
    manufacturerColumn,
    actionsTreeColumn,
    actionsTreeColumnNative,
    instanceActivityStatusColumn,
  };
};
