export const salutations = [
  { label: "Ms", value: 1 },
  { label: "Mr", value: 2 },
  { label: "None", value: 3 },
];
  
export const titles = [
  { label: "Dr.", value: 1 },
  { label: "Prof.", value: 2 },
  { label: "None", value: -1 },
];
  
export const roles = [
  { label: "Admin", value: 1 },
  { label: "User", value: 2 }
];
  
export const privileges = [
  { label: "Read", value: 1 },
  { label: "Write", value: 2 }
];

export const initialFormState = {
  salutation: -1,
  title: -2,
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  company: -1,
  maker: [],
  role: -1,
  privilege: -1,
  comment: '',
};