import useData from "@/hooks/auth/useData";
import DynamicDialog from "../DynamicDialog";
import { t } from "i18next";
import { Typography } from "@mui/material";

interface ConfirmationDialogProps {
    onClick: () => void;
    title: string;
    isLoading: boolean;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    onSuccess?: (data: any) => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    onClick,
    title,
    isLoading,
    primaryButtonText = t("general.remove") || "Remove",
    secondaryButtonText = t("general.cancel") || "Cancel",
    onSuccess,
}) => {
    const { confirmationDialogOpen, setConfirmationDialogOpen } = useData();
    return (
        <DynamicDialog
            open={confirmationDialogOpen}
            onClose={() => setConfirmationDialogOpen(false)}
            onClick={onClick}
            closeOnBackdropClick={true}
            title={title}
            content={<Typography variant="subtitle1">{t("general.removeHint")}</Typography>}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            showSuccessOutside={true}
            primaryButtonColor="error"
            isLoading={isLoading}
            onSuccess={onSuccess}
        />
    );
};

export default ConfirmationDialog;