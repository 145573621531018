import React, {useContext, useEffect, useMemo, useState} from "react";
import {Button, Paper, useTheme} from "@mui/material";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridSortDirection,
} from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import useCustomTableUtils from "@/hooks/useColumnsData/utils";
import {AccountsTreeResponse} from "@/api/management/types";
import { orange } from "@/hooks/useColumnsData/consts";
import { Link } from "react-router-dom";
import ActionsDialog from "./ActionsDialog";
import AccountsAccessModal from "@/pages/account/AccountsManager/accountsAccessModal";
import {t} from "i18next";
import {DataContext} from "@/contexts/DataContext";


export const AccountsManager = () => {
  const { accountsTreeApi, manufacturersApi } = useData();
  const allColumns = useColumns();
  const { na, renderLinkCell, getTranslatedHeaderName } = useCustomTableUtils();
  const [clickedAccessRow, setClickedAccessRow] = useState<AccountsTreeResponse | null>(
    null
  );
  const [isAccountAccessModalOpen, setIsAccountAccessModalOpen] = useState(false);
  const [openActionsDialog, setOpenActionsDialog] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<AccountsTreeResponse | null>(null); // Track selected row
  const [dialogMode, setDialogMode] = useState<"create" | "edit" | "overview">("create"); // Manage dialog mode
  const theme = useTheme();
  const warning = theme.palette.mode === "dark" ? orange : "#705200"
  const linkColor = theme.palette.mode === "dark" ? "#A5C4EE" : "#0C599C";
  const {
    setOpenSnackbar,
    setSnackbarMessage
  } = useContext(DataContext)

  useEffect(() => {
    accountsTreeApi?.execute()
  }, [manufacturersApi?.manufacturers?.data])

  const rows = useMemo(() => {
    return accountsTreeApi?.data || [];
  }, [accountsTreeApi?.data]);

  const handleEditClick = (params: { row: any; }) => {
    const row = params.row;
    if (row.hierarchy.length === 1) {
      setSelectedRowData(row);
      setDialogMode("edit");
      setOpenActionsDialog(true)
    }
  };

  const groupingMarketColDef = {
    headerName: getTranslatedHeaderName('tableColumns.companyName', 'Company Name'),
    hideDescendantCount: true,
    headerClassName: "grouping-header",
    field: "name",
    minWidth: 180,
    flex: 1,
    valueGetter: (_: any, row: Record<string, any>) =>
      row.type == "account" ? (row.name || na) : "",
  };

  const columns: Array<GridColDef> = [
    {
      ...allColumns.centeredColumn,
      headerName: "ID",
      field: "accountId",
      width: 60,
      valueGetter: (_: any, row: Record<string, any>) => row.type == "account" ? (row.accountId || na) : "",
    },
    {
      headerName: getTranslatedHeaderName('tableColumns.companyShortName', 'Company Short Name'),
      field: "shortName",
      width: 170,
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "account" ? (row.shortName || na) : "",
    },
    {
      headerName: getTranslatedHeaderName('tableColumns.makerPermission', 'Maker Permission'),
      field: "makerPermission",
      minWidth: 170,
      flex: 1,
      valueGetter: (_: any, row: Record<string, any>) => row.makerPermission || na,
    },
    {
      headerName: getTranslatedHeaderName('tableColumns.makerId', 'Maker ID'),
      field: "manufacturerId",
      width: 100,
      align: "center",
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "maker" ? (row.manufacturerId || na) : "",
    },
    {
      headerName: getTranslatedHeaderName('tableColumns.privilege', 'Privilege'),
      field: "privileges",
      width: 100,
      valueGetter: (_: any, row: Record<string, any>) => {
          if (!row?.privileges || row.privileges === 0) {
              return t("general.privileges.none");
          }
          if (Array.isArray(row.privileges)) {
              if (row.privileges.length === 1) {
                  return t(`general.privileges.${row.privileges[0]}`);
              }
              if (row.privileges.length > 1) {
                  return t("general.privileges.multiple");
              }
          }
          return t("general.privileges.none");
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (!params.row.manufacturerId) {
          return <span>{params.value}</span>;
        }

        return (
          <Button onClick={() => handleAccountAccess(params.row)}
            sx={{ justifyContent: 'flex-start', textAlign: 'left', color: linkColor, fontWeight: theme.typography.fontWeightBold }}>
            {params.value}
          </Button>
        );
      }
    },
    {
      ...allColumns.centeredColumn,
      headerName: getTranslatedHeaderName('tableColumns.users', 'Users'),
      field: "users",
      width: 60,
      renderCell: (params: GridRenderCellParams<any>) => {
        const { row } = params;
        const userPageUrl = `/management/users`;

        return row.users ? (
          <Link
          style={{ color: linkColor, fontWeight: theme.typography.fontWeightBold}}
            to={userPageUrl}
          >
            {row.users}
          </Link>
        ) : (
          na
        );
      },
    },
    {
      headerName: getTranslatedHeaderName('tableColumns.emailDomain', 'Email Domain'),
      field: "emailDomain",
      width: 170,
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "account" ? (row.emailDomain || na) : "",
    },
    {
      headerName: "CSM URL",
      field: "csmUrl",
      width: 200,
      valueGetter: (_: any, row: Record<string, any>) =>
        row.type == "account" ? (row.csmUrl || na) : "",
      renderCell: (params: GridRenderCellParams<any>) => params.row.type == "account" && renderLinkCell(params.value, params.value)
    },
    {
      ...allColumns.createChipColumn((params) => params.row.status === 'active' ? theme.palette.success.main : warning),
      field: "status",
      headerName: getTranslatedHeaderName("tableColumns.status", "Status"),
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return getTranslatedHeaderName(`general.${(params as string)}`, "unknown")
      },
      valueGetter: allColumns.createValueGetter("status", false, 0, false),
    },
    allColumns.updatedDateColumn,
    {...allColumns.timeAddedColumn,
      headerName: getTranslatedHeaderName('tableColumns.created', 'Created'),
    },
    allColumns.actionsTreeColumnNative(
      (params: GridRowParams) => [
        <GridActionsCellItem
          key="edit"
          onClick={(_event) => {
            handleEditClick({ row: params.row });
          }}
          label={getTranslatedHeaderName("general.edit", "Edit")}
          showInMenu
        />,
      ],
      (params: GridRowParams) => (params.row?.hierarchy?.length || 0) === 1
    ),
  ];

  const sortModel = useMemo(
    () => [
      {
        field: "created",
        sort: "desc" as GridSortDirection,
      },
    ],
    []
  );

  const handleAccountAccess = (row: AccountsTreeResponse) => {
    const matchingRow = rows.find(
      (candidate: AccountsTreeResponse) =>
        candidate.accountId && candidate.hierarchy.join(',') === row.hierarchy.slice(0, -1).join(',')
    );
    if (matchingRow) {
      row.accountId = matchingRow.accountId
      setClickedAccessRow(row);
      setIsAccountAccessModalOpen(true);
    }
  };

  const handleRowClick = (params: { row: any }) => {
    const row = params.row;
    if (row.hierarchy.length === 1) {
      setSelectedRowData(row);
      setDialogMode("overview");
      setOpenActionsDialog(true);
    }
  };

  const handleCreateClick = () => {
    setSelectedRowData(null);
    setDialogMode("create");
    setOpenActionsDialog(true);
  };

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          id="accounts-tree"
          hasExport={false}
          filterBarType={2}
          rows={rows}
          columns={columns}
          onCreateClick={handleCreateClick}
          hasControls
          loading={accountsTreeApi.showSkeletton}
          getTreeDataPath={(row) => row.hierarchy || []}
          sortModel={sortModel}
          onRowClick={handleRowClick}
          treeData
          hiddenFields={["accountId", "csmUrl", "UPDATED", "manufacturerId"]}
          groupingColDef={groupingMarketColDef}
          getRowClassName={(params) => {
            if (params.row.type === "maker" || params.row.keyword) {
              return "gray-50-row";
            }
            return "";
          }}
          searchPlaceholder={getTranslatedHeaderName('tableColumns.searchUsersPlaceholder', 'Company Name, Maker, ...')}
        />
        <ActionsDialog
          dialogOpen={openActionsDialog}
          setDialogOpen={setOpenActionsDialog}
          selectedRowData={selectedRowData as AccountsTreeResponse}
          dialogMode={dialogMode}
        />
      </Paper>
      <AccountsAccessModal
        open={isAccountAccessModalOpen}
        onClose={() => setIsAccountAccessModalOpen(false)}
        onSuccess={() => {
          setSnackbarMessage(t('pages.manageAccounts.editAccess.snackBarMessage'));
          setOpenSnackbar(true);
        }}
        row={clickedAccessRow}
        listApi={accountsTreeApi}/>
    </ContentContainer>
  );
};
