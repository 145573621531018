import React, {useEffect, useMemo, useState} from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams
} from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import {Menu, MenuItem, Paper as MuiPaper} from "@mui/material";
import {spacing} from "@mui/system";
import styled from "@emotion/styled";
import {MuiGridTable} from "@/components/tables/DataGridTable";
import {useColumns} from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import {useTranslation} from "react-i18next";
import {ContractsResponse} from "@/api/management/types";
import {convertMonth} from "@/utils/timeConverter";
import {hiddenColumns} from "@/pages/contracts/consts";
import CreateContractModal from "./CreateContractModal";
import {convertDays} from "@/utils/timeConverter";

const Paper = styled(MuiPaper)(spacing);

const ContractsOverview = (): JSX.Element => {
  const {t} = useTranslation();
  const terminatedColor = '#CE2D2C';
  const activeColor = '#008000';
  const { contractsApi, accountsApi, modulesApi, manufacturersApi, isLoading, adminsApi } = useData();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isOverviewModalOpen, setIsOverviewModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ContractsResponse | null>(
    null
  );

  useEffect(() => {
    if (!contractsApi.isExecuted) {
      contractsApi.execute(undefined, false);
    }
    if (!accountsApi.isExecuted) {
      accountsApi.execute(undefined, false);
    }
    if (!modulesApi.isExecuted) {
      modulesApi.execute(undefined, false);
    }
    if (!manufacturersApi?.allManufacturers.data || manufacturersApi?.allManufacturers.data.length === 0) {
      manufacturersApi?.allManufacturers.execute(undefined, false);
    }
    if (!adminsApi.data || adminsApi.data.length === 0) {
      adminsApi.execute(undefined, false);
    }

  }, [contractsApi, accountsApi, modulesApi, manufacturersApi, adminsApi]);

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const rows = useMemo(() => {
    if (!contractsApi?.data) return [];
    const data = contractsApi.data

    return contractsApi.data.map(row => {
      // Process only top-level rows
      if (row.hierarchy.length === 1) {
        // Find related rows (maximum depth of 2)
        const relatedRows = data.filter(r =>
          r.hierarchy.slice(0, row.hierarchy.length).join() === row.hierarchy.join() &&
          r.hierarchy.length == 2
        );

        const modules = relatedRows.map(r => r.module).filter(module => module !== undefined && module !== null && module !== '');
        const module_ids = relatedRows.map(r => r.module_id).filter(module_id => module_id !== undefined && module_id !== null);
        const quantities = relatedRows.map(r => r.quantity).filter((quantity): quantity is number => quantity !== undefined && quantity !== null);
        const makers = relatedRows.map(r => r.maker).filter(maker => maker !== undefined && maker !== null);
        const maker_ids = relatedRows.map(r => (r.maker_id != null ? r.maker_id : -1)) // maker can be none if not defined

        const uniqueModuleIds = Array.from(new Set(module_ids));
        const uniqueModules = Array.from(new Set(modules));
        const uniqueMakers = Array.from(new Set(makers));
        const uniqueQuantities = Array.from(new Set(quantities));

        let quantityDisplay = '';
        if (uniqueQuantities.length > 0) {
          const minQuantity = Math.min(...uniqueQuantities);
          const maxQuantity = Math.max(...uniqueQuantities);
          quantityDisplay = uniqueQuantities.length === 1 ? `${minQuantity}` : `${minQuantity} - ${maxQuantity}`;
        }

        // Enhance the top-level row with the merged fields
        return {
          ...row,
          module_id: uniqueModuleIds.sort().join(' + '),
          module: uniqueModules.sort().join(' + '),
          maker: uniqueMakers.sort().join(' + '),
          quantity: quantityDisplay,
          modules,
          module_ids,
          quantities,
          makers,
          maker_ids,
        };
      }

      // Return other rows unchanged
      return row;
    });
  }, [contractsApi?.data]);

  const allColumns = useColumns();

  const columns: GridColDef<ContractsResponse>[] = [
    {
      ...allColumns.leftAlignedColumn,
      field: "account",
      headerName: t("tableColumns.account") || "Account",
      headerAlign: 'left',
      width: 100,
      valueGetter: allColumns.createValueGetter("account", false, 0, false)
    },
    {
      ...allColumns.leftAlignedColumn,
      field: "maker",
      headerName: t("tableColumns.maker") || "Maker",
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
      valueGetter: allColumns.createValueGetter("maker", false, 0, false)
    },
    {
      ...allColumns.idColumn,
      field: "module_id",
      headerName: t("tableColumns.moduleId") || "Module ID",
      width: 100,
      valueGetter: allColumns.createValueGetter("module_id", false, 0, false),
    },
    {
      ...allColumns.productColumn,
      field: "module",
      headerName: t("tableColumns.module") || "Module",
      flex: 1,
      minWidth: 200,
      valueGetter: allColumns.createValueGetter("module", false, 0, false),
      renderCell: (params: GridRenderCellParams) => {
        const text = params.value || "";

        const hierarchy = params.row.hierarchy || [];
        const indent = hierarchy.length > 2 ? (hierarchy.length - 2) * 12 : 0;

        return (
            <div
                style={{
                  paddingLeft: `${indent}px`,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
                title={text}
            >
              {text}
            </div>
        );
      },
    },
    {
      ...allColumns.centeredColumn,
      field: "quantity",
      headerName: t("tableColumns.quantity") || "Quantity",
      width: 100,
      valueGetter: allColumns.createValueGetter("quantity", false, 0, false)
    },
    {
      ...allColumns.centeredColumn,
      field: "term",
      headerName: t("tableColumns.term") || "Term",
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return convertMonth(params, t)
      },
      valueGetter: allColumns.createValueGetter("term", false, 0, false)
    },
    {
      ...allColumns.centeredColumn,
      field: "notice",
      headerName: t("tableColumns.notice") || "Notice",
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return convertMonth(params, t)
      },
      valueGetter: allColumns.createValueGetter("notice", false, 0, false)
    },
    {
      ...allColumns.centeredColumn,
      field: "cycle",
      headerName: t("tableColumns.cycle") || "Cycle",
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return convertMonth(params, t)
      },
      valueGetter: allColumns.createValueGetter("cycle", false, 0, false)
    },
    {
      ...allColumns.createChipColumn((params) => params.row.status === 'active' ? activeColor : terminatedColor),
      field: "status",
      headerName: t("tableColumns.contractStatus") || "Status",
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return t(`general.${(params as string)}`)
      },
      valueGetter: allColumns.createValueGetter("status", false, 0, false),
    },
    {
      ...allColumns.dateColumn,
      field: "start",
      width: 80,
      headerName: t("tableColumns.start") || "Start",
      valueGetter: allColumns.createValueGetter("start", false, 0, false)
    },
    /* Payment Column not implemented right now
    {
      ...allColumns.coloredDateColumn,
      field: "payment",
      width: 80,
      headerName: t("tableColumns.payment") || "Payment",
      valueGetter: allColumns.createValueGetter("payment", false, 0, false)
    },
    */
    {
      ...allColumns.dateColumn,
      field: "end",
      width: 80,
      headerName: t("tableColumns.end") || "end",
      valueGetter: allColumns.createValueGetter("end", false, 0, false)
    },
    {
      ...allColumns.dateColumn,
      field: "closing_date",
      width: 80,
      headerName: t("tableColumns.closingDate") || "Closing Date",
      valueGetter: allColumns.createValueGetter("closing_date", false, 0, false)
    },
    {
      ...allColumns.dateColumn,
      field: "access_date",
      width: 80,
      headerName: t("tableColumns.firstAccessDate") || "Access Date",
      valueGetter: allColumns.createValueGetter("access_date", false, 0, false)
    },
    {
      ...allColumns.createChipColumn((params) => params.row.automatic_renewal === 1 ? activeColor : terminatedColor),
      field: "automatic_renewal",
      headerName: t("tableColumns.automaticRenewal") || "Automatic Renewal",
      headerAlign: 'left',
      width: 100,
      valueFormatter: (params) => {
        return params === '1' ? t(`tableColumns.yes`) : t(`tableColumns.no`)
      },
      valueGetter: allColumns.createValueGetter("automatic_renewal", false, 0, false)
    },
    {
      ...allColumns.centeredColumn,
      field: "payment_due",
      headerName: t("tableColumns.paymentDue") || "Payment Due",
      headerAlign: 'left',
      width: 100,
      valueFormatter: (params) => {
        if (!params) return '';
        return convertDays(params, t)
      },
      valueGetter: allColumns.createValueGetter("payment_due", false, 0, false)
    },
    {
      ...allColumns.leftAlignedColumn,
      field: "po_number",
      headerName: t("tableColumns.poNumber") || "PO Number",
      headerAlign: 'left',
      width: 100,
      valueGetter: allColumns.createValueGetter("po_number", false, 0, false)
    },
    {
      ...allColumns.leftAlignedColumn,
      field: "procurement_email",
      headerName: t("tableColumns.procurementEmail") || "Procurement Email",
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
      maxWidth: 250,
      valueGetter: allColumns.createValueGetter("procurement_email", false, 0, false)
    },
    {
      ...allColumns.leftAlignedColumn,
      field: "billing_email",
      headerName: t("tableColumns.billingEmail") || "Billing Email",
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
      maxWidth: 250,
      valueGetter: allColumns.createValueGetter("billing_email", false, 0, false)
    },
    {
      ...allColumns.leftAlignedColumn,
      field: "executive",
      headerName: t("tableColumns.accountExecutive") || "Account Executive",
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
      maxWidth: 250,
      valueGetter: allColumns.createValueGetter("executive", false, 0, false)
    },
    {
      ...allColumns.leftAlignedColumn,
      field: "primary_contact",
      headerName: t("tableColumns.primaryContact") || "Primary Contact",
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
      maxWidth: 250,
      valueGetter: allColumns.createValueGetter("primary_contact", false, 0, false)
    },
    {
      ...allColumns.leftAlignedColumn,
      field: "comment",
      headerName: t("tableColumns.comment") || "comment",
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
      valueGetter: allColumns.createValueGetter("comment", false, 0, false)
    },
    {
      ...allColumns.datetimeColumn,
      field: "updated",
      headerName: t("tableColumns.edited") || "Edited",
      valueGetter: allColumns.createValueGetter("updated", false, 0, false)
    },
        {
      ...allColumns.datetimeColumn,
      field: "created",
      headerName: t("tableColumns.created") || "Created",
      valueGetter: allColumns.createValueGetter("created", false, 0, false)
    },
    allColumns.actionsTreeColumnNative(
      (params: GridRowParams) => [
        <GridActionsCellItem
          key="edit"
          onClick={(event) => {
            event.stopPropagation();
            handlePrefilledModal({ row: params.row }, "edit");
          }}
          label="Edit"
          showInMenu
        />,
      ],
      (params: GridRowParams) => (params.row?.hierarchy?.length || 0) === 1
    ),    
  ];

  const groupingColDef = {
    ...allColumns.idColumn,
    headerName: t("tableColumns.contractId") || "Contract ID",
    width: 120,
    valueGetter: allColumns.createValueGetter("contract_id", false, 0, false),
    hideDescendantCount: true,
  };

  const handlePrefilledModal = (params: { row: any; }, type: string = "overview") => {
    const row = params.row;
    if (row.hierarchy.length === 1) {
      setSelectedRow(row);
      type === "overview" ? setIsOverviewModalOpen(true) : setIsEditModalOpen(true);
    }
  };

  const handleCreateClick = () => {
    if (!accountsApi.data || !modulesApi.data) {
      return;
    }
    setIsCreateModalOpen(true);
  };

  return (
    <ContentContainer>
      <Paper>
        <MuiGridTable
          id="contracts"
          rows={rows}
          hasExport={false}
          columns={columns}
          loading={contractsApi.showSkeletton}
          hiddenFields={hiddenColumns}
          getTreeDataPath={(row) => row.hierarchy || []}
          groupingColDef={groupingColDef}
          treeData={true}
          onRowClick={(params) => handlePrefilledModal(params, "overview")}
          filterBarType={2}
          initialPageSize={100}
          hasControls={true}
          searchPlaceholder={
            t("tableColumns.contractSearchPlaceholder") || "Account, Contract, ..."
          }
          isError={contractsApi?.isError}
          error={contractsApi?.error}
          onCreateClick={handleCreateClick}
        />
      </Paper>
      <CreateContractModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSuccess={() => {
          contractsApi.execute();
        }}
        listApi={contractsApi}
        accounts={accountsApi.data || []}
        modules={modulesApi.data || []}
        manufacturers={manufacturersApi?.allManufacturers.data || []}
        accountExecutives={adminsApi?.data || []}
        type={'create'}
        initialData={null}
      />
      <CreateContractModal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSuccess={() => {
          contractsApi.execute();
        }}
        listApi={contractsApi}
        accounts={accountsApi.data || []}
        modules={modulesApi.data || []}
        manufacturers={manufacturersApi?.allManufacturers.data || []}
        accountExecutives={adminsApi?.data || []}
        type={'edit'}
        initialData={selectedRow}
      />
      <CreateContractModal
        open={isOverviewModalOpen}
        onClose={() => setIsOverviewModalOpen(false)}
        onSuccess={() => {}}
        listApi={contractsApi}
        accounts={accountsApi.data || []}
        modules={modulesApi.data || []}
        manufacturers={manufacturersApi?.allManufacturers.data || []}
        accountExecutives={adminsApi?.data || []}
        type={'overview'}
        initialData={selectedRow}
      />
    </ContentContainer>
  );
};

export default ContractsOverview;
