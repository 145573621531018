import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";

const Footer = styled.div<{ isVisible: boolean }>`
  background-color: ${(props) => props.theme.sidebar.background} !important;
  padding: 17px 16px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  transition: opacity 200ms cubic-bezier(0.2, 0, 0, 1) 0s, visibility 0.3s;
`;

const FooterText = styled.div`
  color: ${(props) => props.theme.sidebar.color};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  p {
    margin: 0;
  }
  a {
    color: ${(props) => props.theme.sidebar.color};
  }
`;

interface SidebarFooterProps {
  isVisible?: boolean;
}

export const SidebarFooter: React.FC<SidebarFooterProps> = ({ isVisible = true }) => {
  const { t } = useTranslation();
  const appVersion = import.meta.env.VITE_APP_VERSION;
  const appVersionDate = new Date(
    Number(import.meta.env.VITE_APP_VERSION_DATE) * 1000
  );

  const formattedDate = appVersionDate
    .toISOString()
    .slice(0, 16)
    .replace("T", " ");

  return (
    <Footer isVisible={isVisible}>
      <FooterText>
        <p>© Maven360 GmbH</p>
        <p>{t("general.footer.madeIn")}</p>
        <p>
          {appVersion} | {formattedDate}
        </p>
        <br />
        <p>
          <a
            href="https://maven360.io/datenschutzerklaerung/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("general.footer.dataPrivacy")}
          </a>{" "}
          |{" "}
          <a
            href="https://maven360.io/impressum/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("general.footer.imprint")}
          </a>
        </p>
      </FooterText>
    </Footer>
  );
};
