import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import useData from "@/hooks/auth/useData";
import { PackshotsAndVideosResponse } from "@/api/content/types";
import { Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { dependentAPIs, selectorsConfig } from "./consts";
import FiltersPanel from "@/components/FiltersPanel";
import { MuiGridTable } from "@/components/tables/DataGridTable";
import { useColumns } from "@/hooks/useColumnsData";
import ContentContainer from "@/components/contentContainer";
import { useTranslation } from "react-i18next";
import { GridRowId } from "@mui/x-data-grid";

const Paper = styled(MuiPaper)(spacing);

const PackshotsAndVideos = (): JSX.Element => {
  const { t } = useTranslation();
  const { packshotsAndVideosTableApi } = useData();
  const allColumns = useColumns();
  const [rowHeights, setRowHeights] = useState<{ [key: GridRowId]: number | null }>({});
  const [pageSize, setPageSize] = useState<number>(100);
  const [rowNum, setRowNum] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const lastPageRef = useRef<number>(0);
  const lastPageSizeRef = useRef<number>(100);

  const handleImageLoad = useCallback((id: GridRowId, height: number | null) => {
    setRowHeights((prev) => ({ ...prev, [id]: height }));
  }, []);

  useEffect(() => {
    setRowNum(0);
    setRowHeights({});
  }, [packshotsAndVideosTableApi?.data]);

  const rows: PackshotsAndVideosResponse[] = useMemo(() => {
    return packshotsAndVideosTableApi?.data || [];
  }, [packshotsAndVideosTableApi?.data]);

  const columns: GridColDef<PackshotsAndVideosResponse>[] = [
    allColumns.mediaLocationColumn,
    allColumns.mediaColumn,
    allColumns.groupedBrandColumn,
    allColumns.groupedProductTitleColumn,
    allColumns.groupedSKUColumn,
    allColumns.timestampColumn,
  ];
  
  useEffect(() => {
    // now need to load again
    const lpr = lastPageRef.current;
    const lpsr = lastPageSizeRef.current;
    lastPageRef.current = page;
    lastPageSizeRef.current = pageSize;
    if(page < lpr || pageSize < lpsr) return;
    
    if(rows.length > 0) {
        const maxRows = pageSize * (page + 1);
        let i = Math.max(maxRows - pageSize, 0);
        let j = page === 0 ? 0 : rowNum;
        // offset of 1 to get all children
        while (i < maxRows + 1) {
            if(j >= rows.length) {
                break;
            }
            const row = rows[j];
            if(row.type === "parent") {
                i += 1;
            }
            j += 1;
            const image = new Image();
            image.src = row.media;
            image.onerror = () => {
                handleImageLoad(row.id, null);
            }
            image.onload = () => {
                const aspectRatio = image.height / image.width;
                const calculatedHeight = 200 * aspectRatio;
                handleImageLoad(row.id, calculatedHeight);
            };
        }
        setRowNum(j);
    }
  }, [rows, page, pageSize]);

  const heightsLoading = useMemo(() => {
    const heightsLength = Object.keys(rowHeights).length;
    if(packshotsAndVideosTableApi?.data && rows.length === 0) return false;
    if(heightsLength === 0 || heightsLength < rowNum - 1) return true;
    
    const limit = Math.min(rows.length, rowNum)
    for(let i = 0; i < limit; i++) {
        const row = rows[i];
        if(!(row.id in rowHeights)) {
            return true;
        }
    }
    return false;

  }, [rows, rowHeights, rowNum]);
  

  return (
    <ContentContainer>
      <FiltersPanel
        selectorsConfig={selectorsConfig}
        dependentAPIs={dependentAPIs}
        hasDaysFilter={false}
        hasMoreButton={false}
      />
      <Paper>
        <MuiGridTable
          id="packshotsAndVideos"
          rows={rows}
          columns={columns}
          loading={packshotsAndVideosTableApi.showSkeletton || heightsLoading}
          setPageSize={setPageSize}
          setPage={setPage}
          alignCellTop={true}
          pageChangeLoading={heightsLoading}
          getTreeDataPath={(row) => row.hierarchy || []}
          getRowClassName={(params) => {
            if (params.row.type === "children") {
              return "gray-50-row";
            }
            return "";
          }}
          filterBarType={0}
          treeData
          rowHeights={rowHeights}
          groupingColDef={allColumns.groupingPOSColDef}
          searchPlaceholder={
            t("tableColumns.searchPlaceholder") || "SKU, Products, ..."
          }
          isError={packshotsAndVideosTableApi?.isError}
          error={packshotsAndVideosTableApi?.error}
        />
      </Paper>
    </ContentContainer>
  );
};

export default PackshotsAndVideos;
