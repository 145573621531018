import React, { useEffect, useRef, useState } from "react";
import { Box, Button, TextField, Typography, Popover, useTheme, useMediaQuery } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useData from "@/hooks/auth/useData";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/es";
import {
  defaultTimeRanges,
  localeDateFormat,
  localeMapping,
} from "./constants";
import { formatUserNumber } from "@/utils/formatUserNumber";

import { useTranslation } from "react-i18next";
import { useClickOutside } from "@/hooks/useClickOutside";
import { TimeRangeItem } from "./types";
import { useNavigate } from "react-router-dom";
import { gray as lighterGray } from "@/hooks/useColumnsData/consts";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

type TimeRange = {
  dateRangeOpen?: boolean;
};

export default function TimeRangePicker({ dateRangeOpen = true }: TimeRange) {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState<string>(t("profile.navBar.dateSelector.last30Days") || "Last 30 days");
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [timeRanges, setTimeRanges] =
    useState<TimeRangeItem[]>(defaultTimeRanges);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const days = new URLSearchParams(window.location.search).get("days");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { user, dateRange, setDateRange, daysChanged } = useData();

  const numberFormat = user?.settings?.numberFormat;
  const userLocale =
    numberFormat && numberFormat in localeMapping
      ? localeMapping[numberFormat as keyof typeof localeMapping]
      : "en";

  const userDateFormat =
    numberFormat && numberFormat in localeDateFormat
      ? localeDateFormat[numberFormat as keyof typeof localeDateFormat]
      : "YYYY/MM/DD";

  dayjs.locale(userLocale);

  const sixMonthsAgo = dayjs().subtract(6, "months");

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleRangeSelect = (range: TimeRangeItem) => {
    const today = dayjs();
    let fromDate: dayjs.Dayjs | null = null;
    let toDate: dayjs.Dayjs | null = today;
    let days = null;

    if (typeof range.value === "number") {
      fromDate = today.subtract(range.value, "day");
      toDate = today;
      days = range.value;
    } else if (range.label.includes("Custom")) {
      const [startDateStr, endDateStr] = range.value.split(" - ");

      fromDate = dayjs(startDateStr);
      toDate = dayjs(endDateStr);
    }

    if (fromDate && toDate) {
      setDateRange({
        from_date: fromDate.unix(),
        to_date: toDate.unix(),
        days,
      });
    }

    const params = new URLSearchParams(window.location.search);

    if (days) {
      params.set("days", days.toString());
    } else {
      params.delete("days");
    }

    navigate(`${window.location.pathname}?${params.toString()}`, {
      replace: true,
    });

    setSelectedRange(range.label);
    setDropdownOpen(false);
  };

  const handleApplyCustomRange = () => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).toISOString();
      const formattedEndDate = dayjs(endDate).toISOString();

      const displayStartDate = dayjs(startDate).format(userDateFormat);
      const displayEndDate = dayjs(endDate).format(userDateFormat);
      const customRangeLabel = `Custom: ${displayStartDate} - ${displayEndDate}`;
      const customRangeValue = `${formattedStartDate} - ${formattedEndDate}`;

      const customRange = { label: customRangeLabel, value: customRangeValue };

      if (!timeRanges.some((range) => range.label === customRange.value)) {
        const enabledRanges = timeRanges.filter((range) => !range.disabled);
        const disabledRanges = timeRanges.filter((range) => range.disabled);
        setTimeRanges([...enabledRanges, customRange, ...disabledRanges]);
      }
    }

    setStartDate(null);
    setEndDate(null);
  };

  const handleUpgradeClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);

    setTimeout(() => {
      setPopoverOpen(false);
    }, 3000);
  };

  const formatCustomRangeLabel = (customRangeLabel: string) => {
    if (!customRangeLabel.startsWith("Custom:")) {
      return customRangeLabel;
    }

    const parts = customRangeLabel.split(" ");

    return parts
      .map((part) => {
        const parsedDate = dayjs(
          part,
          ["DD.MM.YYYY", "YYYY-MM-DD", "DD/MM/YYYY"],
          true
        );

        if (parsedDate.isValid()) {
          return formatUserNumber(parsedDate.toDate());
        }

        return part;
      })
      .join(" ");
  };

  const shouldDisableDate = (date: any) => {
    return date.isBefore(sixMonthsAgo);
  };

  useEffect(() => {
    if (days) {
      const matchingRange = timeRanges.find(
        (range) => range.value === parseInt(days, 10)
      );
      if (matchingRange) {
        handleRangeSelect(matchingRange);
      }
    }
  }, [days]);


  const { ref } = useClickOutside(() => {
    const dropdown = document.querySelector('[role="dialog"]');

    if (dropdown) {
      return;
    }

    setDropdownOpen(false);
  });
  const gray = theme.palette.mode === "dark" ? lighterGray : theme.palette.grey[700];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={userLocale}>
      <Box ref={ref}>
        {isMobile 
          ? <div style={{minWidth: 140, display: "flex",alignItems: "center" , gap: 10}} onClick={toggleDropdown}>
              <CalendarTodayIcon sx={{width: 18, height: 18}} />
              <Typography 
                    color={theme.palette.text.primary}
                    sx={{ fontSize: 16 }}
                  >

                {dayjs(dateRange.from_date * 1000).format("DD. MMMM")} - {dayjs(dateRange.to_date * 1000).format("DD. MMMM")}
              </Typography>
              
            </div> 
          : <TextField
              label={t("profile.navBar.dateSelector.label")}
              value={t(selectedRange)}
              onClick={toggleDropdown}
              InputProps={{
                endAdornment: <ArrowDropDownIcon sx={{ cursor: "pointer" }} />,
              }}
              fullWidth
              variant="outlined"
              sx={{
                width: "140px",
                minWidth: "140px",
                maxWidth: "140px",
                "& .MuiOutlinedInput-root": {
                  height: "40px",
                },
                "& .MuiInputLabel-root": {
                  color: gray, // Change the label color
                },
              }}
            />}

        {dropdownOpen && (
          <Box
            sx={{
              position: "absolute",
              mt: 1,
              right: isMobile ? "auto" : 155,
              backgroundColor: theme.palette.background.paper,
              zIndex: 1,
              boxShadow: theme.shadows[4],
            }}
          >
            <Box display="flex">
              {dateRangeOpen && (
                <Box
                  display="flex"
                  gap={2}
                  alignItems="flex-start"
                  flexDirection="column"
                  sx={{ width: "220px" }}
                  borderRight="1px solid #ccc"
                  padding="40px 10px"
                >
                  <Typography 
                    color={theme.palette.text.primary}
                    sx={{ fontSize: 16 }}
                  >
                    {t("profile.navBar.dateSelector.timeRange")}
                  </Typography>
                  <DesktopDatePicker
                    label={t("profile.navBar.dateSelector.selectStartDate")}
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    shouldDisableDate={shouldDisableDate}
                    sx={{ width: "150px", marginTop: "10px" }}
                  />
                  <DatePicker
                    label={t("profile.navBar.dateSelector.selectEndDate")}
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    shouldDisableDate={shouldDisableDate}
                    sx={{ width: "150px", marginTop: "10px" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: "10px",
                      backgroundColor: theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    }}
                    onClick={handleApplyCustomRange}
                  >
                    {t("profile.navBar.dateSelector.applyRange")}
                  </Button>
                </Box>
              )}
              <Box sx={{ width: "230px" }}>
                {timeRanges.map((range, index) => (
                  <Box
                    key={range.value}
                    sx={{
                      display: "flex",
                      justifyContent: isMobile ? "start" : "space-between",
                      alignItems: "center",
                      padding: "8px",
                      color: range.disabled 
                        ? theme.palette.text.disabled 
                        : theme.palette.text.primary,
                      "&:hover": {
                        backgroundColor: range.disabled 
                          ? "inherit" 
                          : theme.palette.action.hover,
                        cursor: range.disabled ? "not-allowed" : "pointer",
                      },
                    }}
                    onClick={() => {
                      if (!range.disabled) {
                        handleRangeSelect(range);
                        daysChanged.current = true;
                      }
                    }}
                  >
                    <Typography>
                      {formatCustomRangeLabel(t(range.label))}
                    </Typography>
                    {range.upgrade && (
                      <Button
                        variant="text"
                        size="small"
                        onClick={handleUpgradeClick}
                        sx={{ ml: 2 }}
                      >
                        {t("profile.navBar.dateSelector.upgradeNow")}
                      </Button>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}

        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={() => setPopoverOpen(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Box sx={{ padding: 2, backgroundColor: theme.palette.background.paper }}>
            <Typography>
              {t("profile.navBar.dateSelector.upgradeMessage")}
            </Typography>
          </Box>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
}
